/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip
} from "reactstrap";

import reqwest from 'reqwest';

// core components
import BlankHeader from "components/Headers/BlankHeader.jsx";


import { Drawer, Form, Input, message, Upload, Select, DatePicker, Icon, Switch, Menu, Dropdown, notification } from 'antd';

import { ScaleLoader } from 'react-spinners';
import BSCCE from '../../apis/BSCCEApi';

import '../../../node_modules/antd/lib/drawer/style/css';
import '../../../node_modules/antd/lib/date-picker/style/css';
import '../../../node_modules/antd/lib/switch/style/css';
import '../../../node_modules/antd/lib/dropdown/style/css';
import '../../../node_modules/antd/lib/badge/style/css';
import '../../../node_modules/antd/lib/message/style/css';
import '../../../node_modules/antd/lib/notification/style/css';

import moment from 'moment';
import { CreateTermsComponent } from "../Terms/CreateTermsComponent";
import { EditTermsComponent } from "../Terms/EditTermsComponent";
import { DeleteTermsComponent } from "../Terms/DeleteTermsComponent";
import { ListTermsComponent } from "../Terms/ListTermsComponent";

import { connect } from 'react-redux';
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

const mapStateToProps = (state) => {
  return {
    currentUser: state.user,
    tokens: state.tokens
  }
}

const { RangePicker } = DatePicker;
const { TextArea } = Input;

class Terms extends React.Component {

  constructor(props) {
    super(props);


  }

  componentWillMount() {
    BSCCE.terms.getTerms()
      .then(res => {
        // this.setState({ Terms: res.data.data, loading: false });

        this.setState({
          mut: {
            ...this.state.mut,
            terms: res.data.data
          },
          sem: {
            ...this.state.sem,
            loading: false
          }
        })
        console.log(res.data.data);
      });
  }

  state = {
    toggles: {
      showCreateTermDrawer: false,
      showDeleteTermDrawer: false,
      showEditTermDrawer: false
    },
    // semephores
    sem: {
      deleting: false,
      deleted: false,
      committing: false,
      committed: false,
      uploading: false,
      uploaded: false,
      loading: true
    },
    // permissions
    perms: {
      canCreateTerm: true,
      canDeleteTerm: true,
      canEditTerm: true
    },
    // mutable
    mut: {
      termToCreate: {},
      focusedTerm: {},
      fileList: [],
      terms: []
    }
  }
  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description
    });
  };
  actions = {
    list: {
      onSelectTermFromList: (item) => {
        this.setState({
          mut: {
            ...this.state.mut,
            focusedTerm: item
          }
        });
      }
    },
    create: {
      onShowUploadTermDrawer: (e) => {

        this.setState({
          toggles: {
            ...this.state.toggles,
            showCreateTermDrawer: true
          }
        })
      },
      onCloseCreateTermDrawer: (e) => {
        this.setState({
          toggles: {
            ...this.state.toggles,
            showCreateTermDrawer: false
          }
        });
      },
      onCompleteCreateTerm: (e) => {
        this.setState({
          mut: {
            ...this.state.mut,
            terms: [...this.state.mut.terms, e]
          }
        })
      }
    },
    edit: {
      onShowEditTermDrawer: (item) => {
        console.log('edit', item);
        this.setState({
          toggles: {
            ...this.state.toggles,
            showEditTermDrawer: true,
          },
          mut: {
            ...this.state.mut,
            focusedTerm: item
          }
        })

      },
      onCloseEditTermDrawer: (e) => {

        this.setState({
          toggles: {
            ...this.state.toggles,
            showEditTermDrawer: false
          },
          mut: {
            ...this.state.mut,
            focusedTerm: null
          }
        })

      },
    },

    delete: {
      onshowDeleteTermDrawer: (e) => {
        console.log('delete', e);
        this.setState({
          toggles: {
            ...this.state.toggles,
            showDeleteTermDrawer: true
          }
        })
      },
      onCloseDeleteconfirmationDrawer: (e) => {
        this.setState({
          toggles: {
            ...this.state.toggles,
            showDeleteTermDrawer: false
          }
        })
      },
      onDeleteTerm: (e) => {
        console.log('delete now', this.state.mut.focusedTerm)
        this.setState({
          sem: {
            ...this.state.sem,
            deleting: true
          }
        });

        let term_id = this.state.mut.focusedTerm._id;
        console.log('delete ', term_id);

        BSCCE.terms.deleteTerms(term_id)
          .then((res) => {

            if (res.data.status == 200) {
              this.setState({
                sem: {
                  deleting: false,
                  deleted: true
                },
                mut: {
                  ...this.state.mut,
                  terms: this.state.mut.terms.filter(term => term._id != this.state.mut.focusedTerm._id)
                }
              });

              this.actions.delete.onDeleteComplete();
            } else {
              // this.actions.delete.onDeleteFailed ()
              // error handler
              alert('an error occured');
              console.log(res);
            }

          })

          .catch((err) => {
            console.log('error', err);
          })




      },

      onDeleteComplete: (e) => {
        this.actions.edit.onCloseEditTermDrawer();
        this.actions.delete.onCloseDeleteconfirmationDrawer();
        this.openNotificationWithIcon(
          'success',
          'Delete success',
          `has now been deleted.`
          // `${this.state.mut.focusedTerm.term_name} has now been deleted.`
        );

        this.setState({
          sem: {
            ...this.state.sem,
            deleting: false,
            deleted: false,
          },
          mut: {
            ...this.state.mut,
            focusedTerm: null
          }
        });

      }
    }
  };


  render() {

    const { uploading, fileList } = this.state;
    const props = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };

    return (

      <>
        <CreateTermsComponent
          canCreateTerm={this.state.perms.canCreateTerm}
          showCreateTermDrawer={this.state.toggles.showCreateTermDrawer}
          termToCreate={this.state.mut.termToCreate}
          focusedTerm={this.state.mut.focusedTerm}
          actions={this.actions.create}
        />

        <DeleteTermsComponent
          canDeleteTerm={this.state.perms.canDeleteTerm}
          showDeleteTermDrawer={this.state.toggles.showDeleteTermDrawer}
          onCloseDeleteTermDrawer={this.actions.delete.onCloseDeleteTermDrawer}
          termToEdit={this.state.mut.focusedTerm}
          focusedTerm={this.state.mut.focusedTerm}
          actions={this.actions}
        />


        <EditTermsComponent
          canEditTerm={this.state.perms.canEditTerm}
          showEditTermDrawer={this.state.toggles.showEditTermDrawer}
          onCloseEditTermDrawer={this.actions.edit.onCloseEditTermDrawer}
          termToEdit={this.state.mut.focusedTerm}
          actions={this.actions}
        />

        <BlankHeader pageTitle={"Term Dates"} actions={[
          {
            icon: faPlusCircle,
            iconColour: 'green',
            title: 'Create',
            cb: this.actions.create.onShowUploadTermDrawer,
            forUserRoles: ['admin']
          }
        ]} />


        <ListTermsComponent
          loading={this.state.sem.loading}
          terms={this.state.mut.terms}
          actions={this.actions}
          forUserRole={this.props.currentUser.activeRole}
        />


      </>

    );
  }
}

export default connect(mapStateToProps)(Terms);
