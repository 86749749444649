import React, { Component } from 'react'

import { Drawer, Form, Input, message, Upload, Select, DatePicker, Icon, Switch, Menu, Dropdown, notification } from 'antd';

import {
    Button,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle
} from "reactstrap";

import { ScaleLoader } from 'react-spinners';

import BSCCE from '../../apis/BSCCEApi';
const base64 = require('base-64');

const { RangePicker } = DatePicker;
const { TextArea } = Input;

export class EditConstantsComponent extends Component {

    actions = {};

    state = {
        constantToEdit: {
            name: '',
            ref: '',
            type: '',
            defaultValue: '',
            hasVariant: null,
            variantValue: '',
            variantCondition: ''
        }
    }

    constructor(props) {
        super(props);

        this.actions = props.actions;
        this.setState({
            ...this.state,
            constantToEdit: props.constantToEdit
        })

    }

    componentWillReceiveProps = () => {
        console.log ('on will receive ', this.props.constantToEdit)

        if (this.props.constantToEdit) this.setState ({ ...this.state, constantToEdit: this.props.constantToEdit});
    }

    componentWillUnmount = () => {
        this.setState ({
            ...this.state,
            constantToEdit: {}
        })
    }

    onChange = (e) => this.setState({
        constantToEdit: {
            ...this.state.constantToEdit,
            [e.target.name]: e.target.value
        }
    });


    onIntentToCloseEditDrawer = () => {

        console.log ('on intent')

        this.setState ({
            ...this.state,
            committing: false,
            committed: false
        })
        this.actions.edit.onCloseEditConstantsDrawer ();
    }


    onCommitUpatedNotice = () => {

        console.log('submit ', this.state.constantToEdit);

        if (!!this.state.constantToEdit.variantCondition) {
            this.setState({
                constantToEdit: {
                    ...this.state.constantToEdit,
                    variantCondition: base64.encode(this.state.constantToEdit.variantCondition)
                }
            })
        }

        console.log('submit ', this.state.constantToEdit);

        BSCCE.constants.putConstants(this.state.constantToEdit)
            .then(resp => {
                if (resp.data.status == 200) {

                    message.success(`Updated successful.`);


                    this.setState({
                        committed: true,
                        committing: false
                    });

                    this.props.actions.edit.onCompleteUpdateConstants({...resp.data.data, updated: true});
                } else {

                    message.error('something went wrong.');
                }
            })
            .catch(err => { })
    }


    render() {

        const handleButtonClick = (e) => {
            message.info('Click on left button.');
            console.log('click left button', e);

        
          }

        const handleMenuClick = (e) => {
            message.info('Click on menu item.');
            console.log('click', e);

            this.setState({
                constantToEdit: {
                    ...this.state.constantToEdit,
                    type: e.key
                }
            })
        }

        const menu = (
            <Menu onClick={handleMenuClick}>
                <Menu.Item key="file">
                    <Icon type="user" />
                    File
              </Menu.Item>
                <Menu.Item key="text">
                    <Icon type="user" />
                    Text
              </Menu.Item>
                <Menu.Item key="url">
                    <Icon type="user" />
                    URL
              </Menu.Item>
            </Menu>
        );


        return (
            <div>
                <Drawer
                    title={<>

                    {this.state.constantToEdit && <> </>}
                        <UncontrolledDropdown>
                            <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={() => { }}
                            >
                                {this.state.constantToEdit.name &&
                                    <h4>{this.state.constantToEdit.name}
                                        <Icon type="tool" theme="twoTone" style={{ fontSize: '14pt' }} color="#1890ff" />
                                    </h4>
                                }

                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem href="#pablo" >
                                    <Button style={{ color: 'darkred' }} type="primary" onClick={() => {

                                        this.actions.delete.onshowDeleteConstantsDrawer(this.state.constantToEdit)
                                    }}>
                                        Delete {this.state.constantToEdit && this.state.constantToEdit.name}
                                    </Button>
                                </DropdownItem>

                            </DropdownMenu>
                        </UncontrolledDropdown> </>}
                    width={520}
                    closable={false}
                    anchor="right"
                    onClose={ this.onIntentToCloseEditDrawer }

                    open={this.props.canEditConstants && this.props.showEditConstantsDrawer}
                    visible={this.props.canEditConstants && this.props.showEditConstantsDrawer}
                >

                    {/* start edit form */}

                    <b>Constants Name <i>(internal)</i></b>
                    <Input name="name" value={ this.state.constantToEdit.name } onChange={(e) => { this.onChange(e) }} value={this.state.constantToEdit.name } />

                    <br />
                    <br />
                    <b>Constant Ref <i>(internal)</i></b>
                    <Input value={this.state.constantToEdit.ref } name="ref" onChange={(e) => { this.onChange(e) }} />

                    <br />
                    <br />
                    <b>Constant Type <i>(internal)</i></b>
                    <br />
                    <Dropdown.Button onClick={handleButtonClick} overlay={menu}>
                        {!this.state.constantToEdit.type && 'Select a type from the dropdown list'}
                        {!!this.state.constantToEdit.type && this.state.constantToEdit.type}
                    </Dropdown.Button>

                    <br />
                    <br />

                    <b>Constant value <i>(shown)</i></b>
                    <TextArea value={this.state.constantToEdit.defaultValue }name="defaultValue" onChange={(e) => { this.onChange(e) }} />

                    <br /><br />
                    <b>Create a variant case? (<i>Advanced</i>)</b>
                    <br />
                    <p>A varient case allows a specific item in the Copy to change automatically when the <b>variant condition</b> is met.</p>

                    <Switch
                        name='hasVariant'
                        checked={this.state.constantToEdit.hasVariant }
                        onChange={() => {
                            this.setState({
                                constantToEdit: {
                                    ...this.state.constantToEdit,
                                    hasVariant: !this.state.constantToEdit.hasVariant
                                }
                            })
                        }}
                        checkedChildren={
                            <>
                                <a>&emsp; This copy has a variant case  &nbsp;</a>
                            </>
                        }
                        unCheckedChildren={
                            <>

                                <a>&emsp; This copy does not have a variant case &emsp;</a>
                            </>
                        }
                        defaultUnchecked
                    />

                    <br /><br />
                    <b>Variant Value (<i>Advanced</i>)</b>
                    <br />
                    <p>This value will be displayed when the associated condition is met.</p>
                    <TextArea disabled={!this.state.constantToEdit.hasVariant} name="varirantValue" onChange={(e) => { this.onChange(e) }} value={this.state.constantToEdit.variantValue} />

                    <br />
                    <br />

                    <TextArea disabled={!this.state.constantToEdit.hasVariant} name="variantCondition" onChange={(e) => { this.onChange(e) }} value={this.state.constantToEdit.variantCondition} />

                    {/* end edit form */}

                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                        }}
                    >
                        <Button
                            style={{
                                marginRight: 8,
                            }}
                            onClick={this.onIntentToCloseEditDrawer}>
                            Cancel
                        </Button>

                        {!this.state.committed && <Button onClick={ () => { this.onCommitUpatedNotice () }} type="primary">  Save  </Button>}
                        
                        {this.state.commiting && <Button disabled={true} type="primary" >
                            Saving ...
                    <ScaleLoader
                                // css={{}}
                                sizeUnit={"px"}
                                size={40}
                                color={'#176599'}
                            />
                        </Button>}
                        {this.state.committed && <Button onClick={this.onIntentToCloseEditDrawer} type="primary"> Close </Button>}


                    </div>
                </Drawer>
            </div>
        )
    }
}

export default EditConstantsComponent
