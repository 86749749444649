import React, { Component } from 'react';

import { Drawer,  Icon,  Button } from 'antd';

import { ScaleLoader } from 'react-spinners';
import BSCCE from '../../apis/BSCCEApi';

import 'antd/lib/drawer/style/css';
import 'antd/lib/date-picker/style/css';
import 'antd/lib/switch/style/css';
import 'antd/lib/dropdown/style/css';
import 'antd/lib/badge/style/css';
import 'antd/lib/message/style/css';
import 'antd/lib/notification/style/css';

export class DeleteUsersComponent extends Component {

    actions = {};

    state = {
        deleting: false,
        deleted: false
    };

    constructor (props) {
        super (props);

        this.actions = props.actions;
    }

    render() {
        return (
            <div>
                <Drawer
                    title="Confirm Deletion"
                    width={400}
                    closable={false}
                    onClose={this.actions.onCloseDeleteconfirmationDrawer}
                    visible={this.props.canDeleteUser && this.props.showDeleteUserDrawer}
                    open={this.props.canDeleteUser && this.props.showDeleteUserDrawer}
                >
                    Are you sure your want to delete {this.state.userToEdit && <b>{this.state.userToEdit.title}</b>}?
                     <br /><br />

                    {!this.state.deleted && <Button type="primary" onClick={this.actions.delete.onDeleteUser} style={{ border: 'darkred', color: 'darkred' }} disabled={this.state.deleting}>
                        {!this.state.deleted && !this.state.deleting && <>Yes, delete {this.state.userToEdit && this.state.userToEdit.title} </>}
                        {this.state.deleting && <>Deleting  <Icon type="loading" color="#52c41a" /> </>}
                    </Button>
                    }

                    <br /><br />

                    {!this.state.deleted && <Button type="primary" disabled={this.state.deleting} onClick={this.onCloseDeleteconfirmationDrawer}>No, cancel</Button>}


                    {this.state.deleted && <Button onClick={this.onDeleteComplete} type="success"> Finish </Button>}

                </Drawer>
            </div>
        )
    }
}

export default DeleteUsersComponent
