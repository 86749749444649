import React, { Component } from 'react';

import ReactQuill from 'react-quill';

import debounce from 'lodash/debounce';
import { Drawer, Form, Input, message, Upload, DatePicker, Icon, Switch, Menu, Dropdown, Cascader, Button, Select, Divider, List } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';

import { ScaleLoader } from 'react-spinners';
import '../../../node_modules/antd/lib/drawer/style/css';
import '../../../node_modules/antd/lib/date-picker/style/css';
import '../../../node_modules/antd/lib/switch/style/css';
import '../../../node_modules/antd/lib/dropdown/style/css';
import '../../../node_modules/antd/lib/badge/style/css';
import '../../../node_modules/antd/lib/message/style/css';
import '../../../node_modules/antd/lib/notification/style/css';
import 'react-quill/dist/quill.snow.css';


import reqwest from 'reqwest';
import BSCCE from '../../apis/BSCCEApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const { TextArea } = Input;

const { Option } = Select;
const { SubMenu } = Menu;

const props = {};

export class CreateDocumentsComponent extends Component {

    state = {
        documentToCreate: {
            filter: []
        },
        fileList: [],
        fileCategoryList: [],
        isNewCategory: false,
        showPreview: false,
        name: '',
        recipientsList: [],
        loadingRecipients: true,
        text: '' 

    };

    onDescriptionChange(value) {
        this.setState({ text: value,
        documentToCreate: {
            ...this.state.documentToCreate,
            description: value
        } })
    }

    actions = {};

    constructor(props) {
        super(props);

        this.onDescriptionChange = this.onDescriptionChange.bind(this)

        this.actions = this.props.actions;
    }



    componentWillMount() {
        BSCCE.files.getFileCategories()
            .then(resp => {
                this.setState({
                    ...this.state,
                    fileCategoryList: resp.data.data
                })
            })
    }

    onSelectCreateDocumentIcon = (e) => {
        console.log(e);
    }


    addItem = () => {
        console.log('addItem');
        const { items, name } = this.state;

        this.setState({
            items: [...items, name || `New item`],
            name: '',
        });
    };

    onNameChange = event => {

        console.log('name set');
        this.setState({
            name: event.target.value,
        });
    };



    onChange = (e) => this.setState({
        documentToCreate: {
            ...this.state.documentToCreate,
            [e.target.name]: e.target.value
        }
    });


    handleUpload = () => {

        message.info ('Uploading your file. Please wait ...');

        const { fileList } = this.state;
        const formData = new FormData();

        // validation 
        if (!this.state.documentToCreate.forContactIds && this.state.documentToCreate.forContactIds.length > 0) {

            message.info('Please select who should be able to access this document', 4);
            return;
        }


        // if ()

        let forAudience = [];
        this.state.documentToCreate.forContactIds.map((audience, i) => {
            forAudience.push (audience.label)
        })

        formData.append('forAudience', forAudience);

        formData.append('description', this.state.documentToCreate.description);
        formData.append('title', this.state.documentToCreate.title);
        formData.append('category', this.state.documentToCreate.category);

        var contactList = [];

        BSCCE.contacts.filterContacts(this.state.documentToCreate.forContactIds)
            .then(resp => {
                if (resp.status == 200) {
                    this.setState({
                        recipientsList: resp.data.data,
                        loadingRecipients: false
                    })

                    resp.data.data.map(contact => contactList.push(contact._id))

                    formData.append('forContactIds', [contactList]);

                    fileList.forEach(file => {
                        formData.append('file', file);
                    });

                    this.setState({
                        uploading: true,
                    });


                    reqwest({
                        url: BSCCE.host + '/files/create',
                        method: 'post',
                        processData: false,
                        data: formData,
                        success: (resp) => {
                            this.setState({
                                fileList: [],
                                uploading: false,
                            });

                            message.success('upload successfully.');

                            this.actions.onCompleteCreateDocument(resp.newFile);
                        },
                        error: () => {
                            this.setState({
                                uploading: false,
                            });
                            message.error('upload failed.');
                        },
                    });

                } else {
                    this.setState({
                        //  recipientsList: resp.data.data,
                        loadingRecipients: false
                    });

                    message.error('Could not assign recipients ilist', 4);

                    return;
                }
            })

    }


    showRecipientsPreviewDrawer() {
        message.loading(' generating preview  ', 3)

        this.setState({ showPreview: true, loadingRecipients: true })

        let filters = [
            [...this.state.documentToCreate.filters1],
            [...this.state.documentToCreate.filters2]
        ]

        BSCCE.contacts.filterContacts(filters)
        // BSCCE.contacts.filterContacts(this.state.documentToCreate.forContactIds)
            .then(resp => {
                if (resp.status == 200) {
                    this.setState({
                        recipientsList: resp.data.data,
                        loadingRecipients: false
                    })
                } else {
                    message.error('Could not generate preview. Please try again later.', 4);

                    this.setState({
                        //  recipientsList: resp.data.data,
                        loadingRecipients: false
                    });

                }
            })
    }

    render() {


        const { uploading, fileList } = this.state;
        const uploadProps = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };

        let index = 0;

        const handleFileCategoryListMenuClick = (e) => {
            message.info('Click on menu item.');
            console.log('click', e);
        }

        const onAssignNewCategoryClicked = () => {

            this.setState({
                isNewCategory: true
            });

        }

        const selectCategoryDropDown = (<Menu>

            <Menu.Item style={{ borderBottom: 'thin solid lightgrey' }} onClick={onAssignNewCategoryClicked}>

                <FontAwesomeIcon icon={faPlusCircle} style={{ color: 'lightgrey' }} /> Assign a new Category
            </Menu.Item>

            <Menu.ItemGroup title="Existing Categories">
                {
                    this.state.fileCategoryList && this.state.fileCategoryList.length > 0 && this.state.fileCategoryList.map((category, i) => (
                        <Menu.Item key={category} onClick={(e) => { this.setState({ documentToCreate: { ...this.state.documentToCreate, category: e.key }, isNewCategory: false }) }}>
                            <a> {category} </a>
                        </Menu.Item>
                    ))
                }
            </Menu.ItemGroup>
        </Menu>)

        return (
            <div>
                <FileRecipientListPreviewDrawer
                    loading={this.state.loadingRecipients}
                    previewData={this.state.recipientsList}
                    open={this.state.showPreview}
                    onClose={() => { this.setState({ showPreview: false }) }}

                />
                <Drawer
                    title="Upload a new document"
                    width={520}
                    closable={false}
                    anchor="right"
                    onClose={this.actions.onCloseCreateDocumentDrawer}
                    // open={this.state.canCreateDocument && this.state.showCreateDocumentDrawer}
                    // visible={this.state.canCreateDocument && this.state.showCreateDocumentDrawer}
                    open={this.props.canCreateDocument && this.props.showCreateDocumentDrawer}
                    visible={this.props.canCreateDocument && this.props.showCreateDocumentDrawer}
                >

                    <p>
                        <b>New File</b>
                    </p>

                    <b>File Title <i>(shown)</i></b>
                    <Input name="title" onChange={(e) => { this.onChange(e) }} placeholder="September File ..." />

                    <br />
                    <br />
                    <b>File Description <i>(shown)</i></b>
                    <div>
                        {/* <ReactQuill value={this.state.editorState}
                            onChange={this.onDescriptionChange} /> */}

                        <ReactQuill value={this.state.text}
                            onChange={this.onDescriptionChange} />

                    </div>
                    {/* <TextArea rows={4} placeholder="This document ..." name="description" onChange={(e) => { this.onChange(e) }} /> */}

                    <br />
                    <br />


                    <b>File Category <i>(shown)</i></b>

                    <br />
                    <Dropdown overlay={selectCategoryDropDown} trigger={['click']} disabled={this.state.isNewCategory}>
                        <Button>
                            {!this.state.documentToCreate.category && <>  Assign Document Category <DownOutlined /></>}

                            {this.state.documentToCreate.category && <> {this.state.documentToCreate.category} <DownOutlined /> </>}
                        </Button>
                    </Dropdown>


                    {
                        this.state.isNewCategory && <>

                            <br />
                            <br />

                            <Button onClick={(e) => { this.setState({ ...this.state, isNewCategory: false }) }}>
                                <FontAwesomeIcon icon={faArrowLeft} /> &emsp; return to select existing category
                            </Button>

                            <br />
                            <br />

                            <Input name="category" onChange={(e) => { this.onChange(e) }} placeholder="Enter new category name ..." />

                            <br />
                            <br />

                        </>
                    }

                    <br />
                    <br />

                    <b>Who should have access to this file?<i>(shown)</i> &emsp;

                            <Button style={{ float: 'right', color: 'green', border: 'thin solid green' }} onClick={() => {

                                this.showRecipientsPreviewDrawer()

                            }
                            }>Preview List</Button>
              
                    </b>


                    <br />
                    <br />
                    <ContactRemoteSelect
                        setValue={(value) => {
                            this.setState({ documentToCreate: { ...this.state.documentToCreate, secondFilter: false, filters1: value } })
                        }}
                    />
                        <div>
                            {! this.state.documentToCreate.secondFilter && 
                            <Button style={{ float: 'left', color: 'green', border: 'thin solid green' }} onClick={() => {

                                this.setState({
                                    ...this.state,
                                    documentToCreate: {
                                        ...this.state.documentToCreate,
                                        secondFilter: true,
                                    }
                                })
                            }
                            }>Add Another Filter</Button>}

 {this.state.documentToCreate && this.state.documentToCreate.secondFilter && 
                    <ContactRemoteSelect
                        setValue={(value) => {
                            this.setState({ documentToCreate: { ...this.state.documentToCreate, filters2: value } })
                        }}
                    />}

                            {!! this.state.documentToCreate.secondFilter && 
                            <Button style={{ float: 'left', color: 'red', border: 'thin solid green' }} onClick={() => {

                                this.setState({
                                    ...this.state,
                                    documentToCreate: {
                                        ...this.state.documentToCreate,
                                        filters2: [],
                                        secondFilter: false
                                    }
                                })
                            }
                            }>Remove Additional Filter</Button>}
                            </div>

 

                   

                    <br />
                    <br />

                    <b>Select file to upload</b>

                    <br />
                    <br />

                    <Upload {...uploadProps}>
                        <Button disabled={this.state.fileList.length > 0}>
                            <Icon type="upload" /> Select File
                        </Button>
                    </Upload>


                    <div style={{ height: '250px' }}>

                        <div
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                width: '100%',
                                borderTop: '1px solid #e8e8e8',
                                padding: '10px 16px',
                                textAlign: 'right',
                                left: 0,
                                background: '#fff',
                                borderRadius: '0 0 4px 4px',
                            }}
                        >
                            {!this.state.committed && <Button style={{
                                marginRight: 8,
                            }} disabled={this.state.committing} onClick={this.actions.onCloseCreateDocumentDrawer} >Cancel</Button>}
                            <>


                                {!this.state.committed &&
                                    <Button
                                        type="primary"
                                        onClick={this.handleUpload}
                                        disabled={(this.state.fileList.length === 0 || this.state.committing)}
                                        loading={this.state.committing}
                                        style={{ marginTop: 16 }}
                                    >

                                        {!this.state.committed && !this.state.committing && 'Publish'}


                                    </Button>
                                }


                                {/* <Button onClick={this.onCommitNewNotice} disabled={this.state.committing} type="primary">

                                {!this.state.committed && !this.state.committing && 'Publish'}

                                {this.state.committing && <>Publishing  <Icon type="loading" color="#52c41a" /> </>}

                            </Button> */}


                                {this.state.committed && <Button onClick={this.actions.onCloseCreateDocumentDrawer} type="success"> Finish </Button>}

                            </>


                        </div>
                    </div>
                </Drawer>


            </div >
        )
    }
}

export default CreateDocumentsComponent


class ContactRemoteSelect extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        options: [
            {
                value: 'dynamic',
                label: 'Dynamic',
                isLeaf: false,
                children: [
                    {
                        value: 'activeParents',
                        label: 'All Active Parents',
                        isLeaf: true,
                    },
                    {
                        value: 'activeStaff',
                        label: 'All Active Staff',
                        isLeaf: true,
                    },
                    {
                        value: 'allActiveParentsAndStaff',
                        label: 'All Active Parents & Staff',
                        isLeaf: true,
                    },
                    {
                        value: 'contractHoldersOf',
                        label: 'Contract Holders Of ...',
                        isLeaf: false,
                    },
                    {
                        value: 'guardiansOf',
                        label: 'Guardians Of ...',
                        isLeaf: false,
                    },
                    {
                        value: 'emergencyContactsOf',
                        label: 'Emergency Contacts Of ...',
                        isLeaf: false,
                    }
                ]
            },
            {
                value: 'contacts',
                label: 'Contacts',
                isLeaf: false,
                children: [
                    {
                        value: 'all',
                        label: 'All Contacts ...',
                        isLeaf: true
                    },
                    {
                        value: 'single',
                        label: 'Single',
                        isLeaf: false
                    }
                ]
            },
            {
                value: 'tags',
                label: 'Tags',
                isLeaf: false,
                children: [
                    {
                        value: 'single',
                        label: 'Single',
                        isLeaf: false
                    }
                ]
            }

        ]
    };

    onChange = (value, selectedOptions) => {
        console.log(value, selectedOptions);
        this.props.setValue(selectedOptions);
    };

    loadData = selectedOptions => {


        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;

        console.log('start  lazyload')

        console.log('selected option ', selectedOptions)
        console.log('load target option ', targetOption)
        console.log('load data for ', selectedOptions[0]);

        // switch (targetOption.label) {
        if (selectedOptions[0].value.toLowerCase() == 'dynamic') {

            switch (targetOption.value) {
                case "guardiansOf":
                case "contractHoldersOf":
                case "emergencyContactsOf":

                    if (!this.state.childrenList) {
                        BSCCE.children.getChildren()
                            .then(resp => {
                                targetOption.loading = false;
                                targetOption.children = resp.data.data.map(child => {
                                    return {
                                        label: child.firstName + ' ' + child.lastName,
                                        value: child._id
                                    }
                                })
                                this.setState({
                                    options: [...this.state.options],
                                    childrenList: resp.data.data
                                });
                            })
                    } else {
                        targetOption.loading = false;
                        targetOption.children = this.state.childrenList.map(child => {
                            return {
                                label: child.firstName + ' ' + child.lastName,
                                value: child._id
                            }
                        })
                        this.setState({
                            options: [...this.state.options],
                        });
                    }


                    break;


            }

        }

        if (selectedOptions[0].value == 'staff' || selectedOptions[0].value == 'contacts') {
            if (!this.state.contactList) {
                BSCCE.contacts.getContacts()
                    .then(resp => {
                        targetOption.loading = false;
                        targetOption.children = resp.data.data.map(contact => {
                            return {
                                label: contact.firstName + ' ' + contact.lastName,
                                value: contact._id
                            }
                        })
                        this.setState({
                            options: [...this.state.options],
                            contactList: resp.data.data
                        });
                    })
            } else {
                targetOption.loading = false;
                targetOption.children = this.state.contactList.map(contact => {
                    return {
                        label: contact.firstName + ' ' + contact.lastName.split(' ').join(' '),
                        value: contact._id
                    }
                })
                this.setState({
                    options: [...this.state.options],
                });
            }

        }

        if (selectedOptions[0].value == 'tags') {

            let tags = [];


                BSCCE.contacts.getContacts()
                    .then(resp => {
                        targetOption.loading = false;
                        resp.data.data.forEach((contact, i) => {


                            if (!! contact.tags && contact.tags.length > 0) {
                                console.log (contact.tags);

                                contact.tags.forEach ((tag, i) => {

                                    console.log ('tag ', tag);
                                    if (tags.indexOf (tag) == -1) tags.push({
                                        label: tag,
                                        value: tag
                                    });

                                })
                            }
                        })

                        targetOption.children = tags;
                        console.log ('tags list ', tags);

                        this.setState({
                            options: [...this.state.options],
                        });
                    })

        }

        // load options lazily

    };

    render() {
        return (
            <Cascader
                style={{ width: '100%' }}
                options={this.state.options}
                loadData={this.loadData}
                onChange={this.onChange}
                changeOnSelect
            />
        );
    }
}


class FileRecipientListPreviewDrawer extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        loading: true,
        data: []
    }

    componentDidMount() {


    }

    render() {


        return <>
            <Drawer
                title="Recipients Preview"
                width={400}
                closable={true}
                anchor="right"
                onClose={this.props.onClose}
                open={this.props.open}
                visible={this.props.open}
            >

                {this.props.loading && <>Loading ... </>}

                {!this.props.loading && <>

                    <List
                        size="small"
                        header={<div><h2>This document will be available to the following contacts</h2><br /><br /></div>}
                        footer={<div></div>}
                        bordered
                        dataSource={this.props.previewData}
                        renderItem={item => <> <List.Item>{item.firstName} {' '} {item.lastName}</List.Item></>}
                    />


                </>}


            </Drawer>


        </>
    }

}
