import React from 'react'

import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import LinkbooksParentsViewComponent from './Parents/LinkbooksParentsViewComponent';
import LinkbooksStaffViewComponent from './Staff/LinkbooksStaffViewComponent';

const mapStateToProps = (state) => {
    return {
        currentUser: state.user,
        context: state.context || {}
    }
}



class LinkbooksComponent extends React.Component {

    constructor(props) {

        super(props);

        console.log ('linkbook ', props);

    }

    render() {

        return <>

            {this.props.currentUser.activeRole == 'parent' &&
                <>
                    {
                        this.props.context.focusedChild && this.props.context.focusedChild._id && <>
                            <LinkbooksParentsViewComponent />
                        </>
                    }

                    {
                        ! this.props.context.focusedChild && <>
                            <Redirect to='/admin/children' />
                        </>
                    }
                </>

            }
            {this.props.currentUser.activeRole == 'staff' && <LinkbooksStaffViewComponent />}

        </>

    }

}


export default connect(mapStateToProps)(LinkbooksComponent)