/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardFooter,
  UncontrolledDropdown,
  DropdownToggle,
  Container,
  Row
} from "reactstrap";

import { Input, DatePicker, notification, Table, Modal, Button, Drawer, Checkbox, Tabs, Divider, Skeleton, List, Empty, Tag } from 'antd';
import {
  SyncOutlined,
} from '@ant-design/icons';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faSpinner, faHourglassStart } from '@fortawesome/free-solid-svg-icons';

import { connect } from 'react-redux';




// core components
import BlankHeader from "components/Headers/BlankHeader.jsx";

import BSCCE from '../../apis/BSCCEApi';

import "../../../node_modules/antd/lib/modal/style/css"
import "../../../node_modules/antd/lib/tabs/style/css"
import "../../../node_modules/antd/lib/tag/style/css"
import "../../../node_modules/@ant-design/icons/SyncOutlined";

import { ListItem } from "@material-ui/core";

const { TabPane } = Tabs;

const { RangePicker } = DatePicker;
const { TextArea } = Input;


const mapStateToProps = (state) => {
  return {
    notesFromStore: state.notes
  }
}


class ListContactsComponent extends React.Component {

  state = {
    focusedContact: {},
    contactDrawer: {

    },
    userToInvite: {},
    contactsToDisplay: [],
    searchTerm: '',
    passwordResetEmailPending: false,
    inviteToPortalPending: false
  }


  notifications = {
    keys: {
      inviteContactToPortal: 'inviteContactToPortal',
      createUser: 'createUser',
      updateContact: 'updateContact'
    },
    type: {
      overallStatus: {
        working: {
          key: 'overallStatus',
          duration: 0,
          message: 'working ...',
          description: 'This task is not yet complete'
        },
        success: {
          key: 'overallStatus',
          duration: 10,
          message: 'Task Complete',
          description: 'This task was completed successfully'
        },
        error: {
          key: 'overallStatus',
          duration: 10,
          message: 'This task failed',
          description: ''
        }
      },
      updateContact: {
        pending: {
          key: 'updateContact',
          duration: 0,
          message: 'Update Contact',
          description: 'Waiting to update the contact record'
        },
        working: {
          key: 'updateContact',
          duration: 0,
          message: 'Updating Contact',
          description: 'Updating contact record.'
        },
        success: {
          key: 'updateContact',
          duration: 10,
          message: 'User Updated',
          description: 'Successfully Updated contact from user.'
        },
        error: {
          key: 'updateContact',
          duration: 10,
          message: 'Create user failed.',
          description: 'New description.'
        }
      },
      inviteContactToPortal: {
        pending: {
          key: 'inviteContactToPortal',
          duration: 0,
          message: 'Invite Contact',
          description: 'Waiting to create contact'
        },
        working: {
          key: 'inviteContactToPortal',
          duration: 0,
          message: 'Sending Invite',
          description: 'Sending invite .'
        },
        success: {
          key: 'inviteContactToPortal',
          duration: 10,
          message: 'Invite Sent',
          description: 'Successfully invited contact.'
        },
        error: msg => {
          return {
            key: 'inviteContactToPortal',
            duration: 10,
            message: msg,
            description: 'Failed to invite user'
          }
        }
      },
      sendPasswordResetEmail: {
        pending: {
          key: 'sendPasswordResetEmail',
          duration: 0,
          message: 'Reset Password',
          description: 'Waiting to send email'
        },
        working: {
          key: 'sendPasswordResetEmail',
          duration: 0,
          message: 'Sending email',
          description: 'Sending email .'
        },
        success: {
          key: 'sendPasswordResetEmail',
          duration: 10,
          message: 'Password Reset Email Sent',
          description: 'Successfully sent Password Reset Email.'
        },
        error: msg => {
          return {
            key: 'sendPasswordResetEmail',
            duration: 10,
            message: msg,
            description: 'Failed to send email'
          }
        }
      }
    }
  }




  constructor(props) {
    super(props);

    console.log('contact component props ', props);
  }

  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description
    });
  };


  onChangeSearchTerm = (e) => {

    let term = e.target.value;

    this.setState({
      searchTerm: term,
      contactsToDisplay: this.props.contacts.filter((contact, index) => {

        if (

          contact.firstName.toLowerCase().indexOf(term.toLowerCase()) > -1
          ||
          contact.lastName.toLowerCase().indexOf(term.toLowerCase()) > -1

        ) {

          return contact;

        }

      })
    });

  }

  onSelectContactFromList = (e) => {
    this.setState({ selectedContact: e });
  }

  onDeleteContact = (e) => {
    console.log('delete notice ', e);
    console.log('delete notice ', this.state.contactToEdit);
    this.setState({
      deleting: true,
    });


    BSCCE.contacts.deleteContact(e._id)
      .then(res => {
        if (res.data.status == 200) {

          this.setState({
            deleting: false,
            deleted: true
          });

          this.setState({
            contacts: [...this.props.contacts.filter(contact => contact._id != this.state.contactToEdit._id)]
          });

          this.onDeleteComplete();

        } else {
          this.openNotificationWithIcon('error', 'Something went wrong', res.data.data.reason);
          this.setState({
            deleting: false
          });

        }
      }, err => {
        console.log('err ', err);
        this.openNotificationWithIcon('error', 'Something went wrong', err);
        this.setState({
          deleting: false
        });
      });

    setTimeout(() => {



    }, 2000);

  }

  onDeleteComplete = (e) => {
    this.openNotificationWithIcon('success', 'Delete success', `${this.state.contactToEdit.firstName} has now been deleted.`);
    this.onCloseDeleteconfirmationDrawer();
    this.onCloseEditContactDrawer();

    this.setState({
      deleting: false,
      deleted: false,
      selectedContact: null,
      contactToEdit: null,
    });

  }

  onFieldChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onInviteContactToPortal = () => {
    this.setState({
      inviteToPortalPending: !this.state.inviteToPortalPending
    })


    let userInviteObj = {
      id: this.state.focusedContact._id,
      roles: []
    }


    if (this.state.userToInvite.isParent) userInviteObj.roles.push({ name: 'parent' });
    if (this.state.userToInvite.isAdmin) userInviteObj.roles.push({ name: 'admin' });
    if (this.state.userToInvite.isStaff) userInviteObj.roles.push({ name: 'staff' });

    notification.info(this.notifications.type.overallStatus.working)
    notification.warn(this.notifications.type.inviteContactToPortal.working)
    notification.info(this.notifications.type.updateContact.pending)

    BSCCE.contacts.inviteContactToPortal(
      // contact Id
      userInviteObj
    )
      .then(resp => {

        if (resp.data.status != 200) {
          this.setState({
            inviteToPortalPending: false
          })
          notification.destroy()

          notification.error(this.notifications.type.overallStatus.error)
          notification.error(this.notifications.type.inviteContactToPortal.error(resp.data.data.reason))

          return;
        }

        this.setState({
          inviteToPortalPending: false
        })

        notification.success(this.notifications.type.inviteContactToPortal.success)
        notification.warn(this.notifications.type.updateContact.working)

        setTimeout(() => {

          notification.success(this.notifications.type.overallStatus.success)
          notification.success(this.notifications.type.updateContact.success)

        }, 3000)

      })
      .catch(err => {
        console.log(err);
        this.setState({
          inviteToPortalPending: false
        })
        notification.destroy()

        notification.error(this.notifications.type.overallStatus.error)
        notification.error(this.notifications.type.inviteContactToPortal.error)

      })


  }

  render() {

    const columns = [
      {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName'
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        render: (text, record) => {
          return (
            <>

              {record.type && record.type.length }

            </>
          )
        }
      },
      {
        title: '',
        key: 'action',
        render: (text, record) => {


          const onOpenContactCardDrawer = contact => {

            this.setState({
              contactCardDrawerVisible: true,
              focusedContact: contact,
              contactDrawer: {
                ...this.state.contactDrawer,
                loadingContactFiles: true,
                loadingContactRelationships: true,
                loadingContactActivity: false,
              }
            })
            // pass through contact details 
            BSCCE
              .contacts
              .getContactRelationships(
                contact._id
              )
              .then(contactRelationships => this.setState({
                contactDrawer: {
                  ...this.state.contactDrawer,
                  loadingContactRelationships: false,
                  contactRelationships: contactRelationships.data.data
                }
              }))
            // load contact relationships

            let forContactIds = contact._id;

            BSCCE.files.queryFiles(
              forContactIds
            )
              .then(contactFiles => this.setState({
                contactDrawer: {
                  ...this.state.contactDrawer,
                  loadingContactFiles: false,
                  contactFiles: contactFiles.data.data
                }
              }))
            // load contact files



          }



          return (
            // <UncontrolledDropdown>
            //   <DropdownToggle
            //     className="btn-icon-only text-light"
            //     // href="#pablo"
            //     role="button"
            //     size="sm"
            //     color=""
            //     onClick={() => { this.props.actions.edit.onShowEditContactDrawer(record) }}
            //   >
            //     <Icon type="tool" theme="twoTone" style={{ fontSize: '14pt' }} color="#1890ff" />

            //   </DropdownToggle>

            // </UncontrolledDropdown>

            <>
              <Button onClick={() => { onOpenContactCardDrawer(record) }}>
                <a> <FontAwesomeIcon icon={faEye} />
                  <span>
                    {(this.state.focusedContact && this.state.focusedContact._id) == record._id ? ' viewing contact' : ' view contact card'}
                  </span>
                </a>
              </Button>
            </>
          )

        }
      }
    ];

    // rowSelection objects indicates the need for row selection
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      },
      onSelect: (record, selected, selectedRows) => {
        console.log(record, selected, selectedRows);
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
        console.log(selected, selectedRows, changeRows);
      },
      onSelectInvert: (selectedRowKeys) => {
        console.log('selected invert ', selectedRowKeys);
      }
    };

    const onCloseContactCardDrawer = contact => {

      this.setState({
        contactCardDrawerVisible: false,
        focusedContact: {},
        contactDrawer: {}
      })

    }

    const onCloseInviteToPortalModal = () => {
      this.setState({
        inviteToPortalModalVisible: false
      })
    }

    const onOpenInviteToPortalModal = () => {
      this.setState({
        inviteToPortalModalVisible: true
      })
    }

    const onSendPasswordResetEmail = () => {
      this.setState({
        passwordResetEmailPending: true
      });

      notification.info(this.notifications.type.sendPasswordResetEmail.working);

      BSCCE.access.prompt({ identifier: this.state.focusedContact.email, identifierType: 'email' })
        .then(resp => {
          if (resp.data.status != 200) {

            notification.info(this.notifications.type.sendPasswordResetEmail.error(resp.data.data.reason));
            this.setState({
              passwordResetEmailPending: false
            });

            return;
          }
          notification.success(this.notifications.type.sendPasswordResetEmail.success);
          this.setState({
            passwordResetEmailPending: false
          });

        }, err => {
          // notification.
        })

    }

    return (
      <>

        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">

                <Drawer
                  title={<>
                    {this.state.focusedContact && this.state.focusedContact.firstName && this.state.focusedContact.firstName}

                    <UncontrolledDropdown>
                      <DropdownToggle
                        className="btn-icon-only text-light"
                        // href="#pablo"
                        role="button"
                        size="sm"
                        color=""
                        onClick={() => { this.props.actions.edit.onShowEditContactDrawer(this.state.focusedContact) }}
                      >
                        {/* <Icon type="tool" theme="twoTone" style={{ fontSize: '14pt' }} color="#1890ff" />
                               */}

                        <FontAwesomeIcon icon={faEdit} />

                      </DropdownToggle>

                    </UncontrolledDropdown>
                  </>}
                  open={this.state.contactCardDrawerVisible}
                  visible={this.state.contactCardDrawerVisible}
                  onClose={() => onCloseContactCardDrawer()}

                  width={520}
                  closable={true}
                  anchor="right"
                // open={this.state.canCreateContact && this.state.showCreateContactDrawer}
                // visible={this.state.canCreateContact && this.state.showCreateContactDrawer}
                >


                  <Button style={{ margin: '2px' }} onClick={() => {
                    onOpenInviteToPortalModal();

                  }}

                  >
                    Invite to portal
                  </Button>

                  &emsp;

                  <Button style={{ margin: '2px' }} onClick={() => {

                    onSendPasswordResetEmail();

                  }} loading={this.state.passwordResetEmailPending}>
                    Send Password Reset Email
                  </Button>

                  <Divider />

                  <Tabs defaultActiveKey="1" onChange={(key) => {
                    console.log(key);
                  }}>

                    <TabPane tab="Details" key="1">

                    <br />
                      <List>

                        
                      <ListItem>
                          <b>First Name: </b> <a>
                            {this.state.focusedContact && this.state.focusedContact.firstName && this.state.focusedContact.firstName}
                          </a>
                        </ListItem>
                      <ListItem>
                          <b>Middle Names: </b> <a>
                            {this.state.focusedContact && this.state.focusedContact.middleName && this.state.focusedContact.middleName}
                          </a>
                        </ListItem>
                      <ListItem>
                          <b>Last Name: </b> <a>
                            {this.state.focusedContact && this.state.focusedContact.lastName && this.state.focusedContact.lastName}
                          </a>
                        </ListItem>
                      <ListItem>
                          <b>Email: </b> <a>
                            {this.state.focusedContact && this.state.focusedContact.email && this.state.focusedContact.email}
                          </a>
                        </ListItem>
                      <ListItem>
                          <b>Gender: </b> <a>
                            {this.state.focusedContact && this.state.focusedContact.gender && this.state.focusedContact.gender}
                          </a>
                        </ListItem>
                      <ListItem>
                          <b>DOB: </b> <a>
                            {this.state.focusedContact && this.state.focusedContact.dob && this.state.focusedContact.dob}
                          </a>
                        </ListItem>
                      <ListItem>
                          <b>phone: </b> <a>
                            {this.state.focusedContact && this.state.focusedContact.phone && this.state.focusedContact.phone}
                          </a>
                        </ListItem>

                        <ListItem>
                          <b>last updated:  </b> <a>
                            {this.state.focusedContact && this.state.focusedContact.updatedAt && this.state.focusedContact.updatedAt}
                          </a>
                        </ListItem>

                        <ListItem>
                          <b>created:</b> <a>
                            {this.state.focusedContact && this.state.focusedContact.createdAt && this.state.focusedContact.createdAt}
                          </a>
                        </ListItem>

                        <ListItem>
                          <b>tags:</b> <a>
                            <span style={{display: 'block', padding: '5px'}}>
                            {this.state.focusedContact && this.state.focusedContact.tags && this.state.focusedContact.tags.map ((tag,i) => (
                               <Tag>{tag}</Tag>
                            ))}
                            </span>
                          </a>
                        </ListItem>

                      </List>

                      <br /><br />

                    </TabPane>
                    <TabPane tab={
                      (
                        <>
                          Relationships
                        {this.state.contactDrawer.loadingContactRelationships && <SyncOutlined spin />}
                          {this.state.contactDrawer.contactRelationships && this.state.contactDrawer.contactRelationships.length > 0 && <Tag> {this.state.contactDrawer.contactRelationships.length}</Tag>}
                        </>
                      )
                    } key="2">

                      {/* 
                      // ** Relationships Tab  
                      */}

                      {this.state.contactDrawer.contactRelationships && this.state.contactDrawer.contactRelationships.length > 0 && this.state.contactDrawer.contactRelationships.map(relationship => (
                        <>
                          <br />
                          {relationship.firstName} {''} {relationship.lastName} {''} {relationship.type}
                        </>
                      ))}

                      {
                        !this.state.contactDrawer.loadingContactRelationships && (!this.state.contactDrawer.contactRelationships || this.state.contactDrawer.contactRelationships.length < 1) && <Empty />
                      }

                      {this.state.contactDrawer.loadingContactRelationships && <Skeleton active />}


                    </TabPane>
                    <TabPane tab={
                      (
                        <>
                          Files
                        {this.state.contactDrawer.loadingContactFiles && <SyncOutlined spin />}
                          {this.state.contactDrawer.contactFiles && this.state.contactDrawer.contactFiles.length > 0 && <Tag> {this.state.contactDrawer.contactFiles.length}</Tag>}
                        </>
                      )
                    } key="3">

                      {/* 
                      // ** Files Tab  
                      */}


                      {this.state.contactDrawer.ContactFiles && this.state.contactDrawer.contactFiles.length > 0 && this.state.contactDrawer.contactFiles.map(file => (
                        <>
                          <br />
                          {file.title} {''} {file.description} {''} {file.cateory} {''} {file.created}
                        </>
                      ))}

                      {
                        !this.state.contactDrawer.loadingContactFiles && (!this.state.contactDrawer.contactFiles || this.state.contactDrawer.contactFiles.length < 1) && <Empty />
                      }

                      {this.state.contactDrawer.loadingContactFiles && <Skeleton active />}



                    </TabPane>
                    <TabPane tab="Activity" key="4">
                      Activity

                      {this.state.contactDrawer.contactActivity && this.state.contactDrawer.contactActivity.length > 0 && this.state.contactDrawer.contactActivity.map(relationship => (
                      <>
                        <br />
                          file
                      </>
                    ))}

                      {this.state.contactDrawer.loadingContactActivity && <Skeleton active />}

                      {
                        !this.state.contactDrawer.loadingContactActivity && (!this.state.contactDrawer.contactActivity || this.state.contactDrawer.contactActivity.length < 1) && <Empty />
                      }

                      <Skeleton active />
                    </TabPane>
                  </Tabs>


                </Drawer>



                {/*  */}
                <Modal
                  title={<>
                    Invite
                    {this.state.focusedContact && this.state.focusedContact.firstName && this.state.focusedContact.firstName}
                    to the portal
                  </>}
                  centered
                  visible={this.state.inviteToPortalModalVisible}
                  onOk={() => onCloseInviteToPortalModal()}
                  onCancel={() => onCloseInviteToPortalModal()}
                >

                  <a>Please select the roles to assign this user</a>
                  <b>Roles <i>(shown)</i></b>
                  <p>This determines what functionality will be available to the user and will change layout of the portal depending on the content being shown to this user. </p>
                  < br />



                  <Checkbox
                    checked={this.state.userToInvite.isParent}
                    // disabled={(this.state.userToInvite.isStaff || this.state.userToInvite.isAdmin)}
                    onChange={(e) => {
                      this.setState({
                        userToInvite: {
                          ...this.state.userToInvite,
                          isParent: !this.state.userToInvite.isParent
                        }
                      });
                    }}
                  >
                    {' '} Parent
                    </Checkbox>

                  <br />

                  <Checkbox
                    checked={this.state.userToInvite.isStaff}
                    // disabled={(this.state.userToInvite.isAdmin || this.state.userToInvite.isParent)}
                    onChange={(e) => {
                      this.setState({
                        userToInvite: {
                          ...this.state.userToInvite,
                          isStaff: !this.state.userToInvite.isStaff
                        }
                      });
                    }}
                  >
                    {' '} Staff
                    </Checkbox>

                  <br />

                  <Checkbox
                    checked={this.state.userToInvite.isAdmin}
                    // disabled={(this.state.userToInvite.isParent || this.state.userToInvite.isStaff)}
                    onChange={(e) => {
                      this.setState({
                        userToInvite: {
                          ...this.state.userToInvite,
                          isAdmin: !this.state.userToInvite.isAdmin
                        }
                      });
                    }}
                    disabled>
                    {' '} Admin
                    </Checkbox>




                  <br /><br />


                  <Button style={{ margin: '2px' }} onClick={() => {

                    this.onInviteContactToPortal();

                  }} loading={this.state.inviteToPortalPending}>

                    Send invitation to {this.state.focusedContact && this.state.focusedContact.firstName && this.state.focusedContact.firstName}
                  </Button>

                </Modal>

                <Input
                  style={{ background: 'transparent', borderBottom: 'thin solid darkgrey', borderRadius: '0', boxShadow: 'inset lightgrey 0 -4px 6px', height: '45px', fontSize: '15pt', color: '#333', padding: '10px', }}
                  placeholder="Start typing to filter by first or last name ..."
                  onChange={(e) => { this.onChangeSearchTerm(e) }}
                />



                <Table
                  columns={columns}
                  loading={this.props.loading}
                  rowSelection={rowSelection}
                  dataSource={this.state.searchTerm.length > 0 ? this.state.contactsToDisplay : this.props.contacts}
                />

                <CardFooter className="py-4">
                </CardFooter>
              </Card>
            </div>
          </Row>

        </Container>

      </>
    );
  }
}

export default connect(mapStateToProps)(ListContactsComponent);
