/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,

  Container,
  Row,
  UncontrolledTooltip
} from "reactstrap";


import { ScaleLoader } from 'react-spinners';

import { Input, DatePicker, Icon, notification, Tag, Table } from 'antd';
import moment from 'moment';
import Truncate from 'react-truncate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';

import { connect } from 'react-redux';



// import Drawer from '@material-ui/core/Drawer';
import 'antd/lib/drawer/style/css';
import 'antd/lib/date-picker/style/css';
import 'antd/lib/switch/style/css';
import 'antd/lib/dropdown/style/css';
import 'antd/lib/tag/style/css';
import 'antd/lib/message/style/css';
import 'antd/lib/notification/style/css';
import 'antd/lib/notification/style/css';
import 'antd/lib/table/style/css';


// core components
import BlankHeader from "components/Headers/BlankHeader.jsx";

import BSCCE from '../../../apis/BSCCEApi';


const { RangePicker } = DatePicker;
const { TextArea } = Input;


const mapStateToProps = (state) => {
  return {
    notesFromStore: state.notes
  }
}


class ListEntryrenComponent extends React.Component {

  state = {
    childToCreate: {}
  }

  constructor(props) {
    super(props);

    console.log('child component props ', props);
  }

  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description
    });
  };




  onSelectEntryFromList = (e) => {
    this.setState({ selectedEntry: e });
  }

  onDeleteEntry = (e) => {
    console.log('delete notice ', e);
    console.log('delete notice ', this.state.childToEdit);
    this.setState({
      deleting: true,
    });


    BSCCE.children.deleteEntry(e._id)
      .then(res => {
        if (res.data.status == 200) {

          this.setState({
            deleting: false,
            deleted: true
          });

          this.setState({
            children: [...this.props.children.filter(child => child._id != this.state.childToEdit._id)]
          });

          this.onDeleteComplete();

        } else {
          this.openNotificationWithIcon('error', 'Something went wrong', res.data.data.reason);
          this.setState({
            deleting: false
          });

        }
      }, err => {
        console.log('err ', err);
        this.openNotificationWithIcon('error', 'Something went wrong', err);
        this.setState({
          deleting: false
        });
      });

    setTimeout(() => {



    }, 2000);

  }

  onDeleteComplete = (e) => {
    this.openNotificationWithIcon('success', 'Delete success', `${this.state.childToEdit.firstName} has now been deleted.`);
    this.onCloseDeleteconfirmationDrawer();
    this.onCloseEditEntryDrawer();

    this.setState({
      deleting: false,
      deleted: false,
      selectedEntry: null,
      childToEdit: null,
    });

  }

  onFieldChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {

    const columns = [
      {
        title: 'Linkbook Entries',
        dataIndex: 'entryDate',
        key: 'entryDate',
      },
      // {
      //   title: 'Last Name',
      //   dataIndex: 'lastName',
      //   key: 'lastName',
      //   // width: '12%',
      // },
      {
        title: '',
        key: 'action',
        render: (text, entry) => {

          return (
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                // href="#pablo"
                role="button"
                size="sm"
                color=""
                onClick={() => { this.props.actions.list.onSelectEntryFromList(entry) }}
              >
                <Icon type="tool" theme="twoTone" style={{ fontSize: '14pt' }} color="#1890ff" />

              </DropdownToggle>

            </UncontrolledDropdown>
          )

        }
      }
    ];

    // rowSelection objects indicates the need for row selection
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      },
      onSelect: (record, selected, selectedRows) => {
        console.log(record, selected, selectedRows);
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
        console.log(selected, selectedRows, changeRows);
      },
      onSelectInvert: (selectedRowKeys) => {
        console.log('selected invert ', selectedRowKeys);
      }
    };

    return (
      <>

        <Container className="mt--7 container-fluid" fluid>
          {/* Table */}
          <Row>
            <Col>

              <div class="card ">
                <div class="card-header bg-transparent">

                  <Row>
                    {/* <Col> */}

                    <h3 class="mb-0">

                      {this.props.focusedEntry && <>
                        <span style={{ fontSize: '16pt', fontWeight: 'bold', color: 'blue' }}
                          onClick={() => { this.props.actions.list.onDeselectEntryFromList() }}
                        >
                          <a style={{ display: 'block', float: 'left' }} class="btn btn-sm btn-neutral" >


                            <FontAwesomeIcon icon={faAngleLeft} />  &nbsp;

                          {this.props.focusedEntry.title}
                          </a>
                          {'  '}

                        </span>

                      </>
                      }

                      {! this.props.focusedEntry && <>
                        <span style={{ fontSize: '16pt', fontWeight: 'bold', color: 'blue' }}
                          onClick={() => { this.props.actions.list.onReturnToChildView () }}
                        >
                          <a style={{ display: 'block', float: 'left' }} class="btn btn-sm btn-neutral" >


                            <FontAwesomeIcon icon={faAngleLeft} />  &nbsp;

                          back
                          </a>
                          {'  '}

                        </span>

                      </>
                      }

                      {
                        this.props.focusedChild.firstName
                      }
                      {
                        ' '
                      }
                      {
                        this.props.focusedChild.lastName
                      }

                    </h3>
                    {/* </Col> */}

                  </Row>



                </div>


                {/* <CardBody> */}

                {!this.props.focusedEntry && <>

                  <Table
                    columns={columns}
                    dataSource={this.props.entries}
                    size="small"
                  />

                </>

                }

                {/* </CardBody> */}
              </div>

            </Col>
          </Row>

          <div style={{ marginTop: '10px' }}> </div>

          {/* // linkBookEntryComponenet */}


        </Container>
        <style jsx>
          {`
        
.timeline {
  position: relative
}

.timeline:before {
  position: absolute;
  top: 0;
  left: 1rem;
  height: 100%;
  content: '';
  border-right: 2px solid #e9ecef
}

[data-timeline-axis-style=dashed]:before {
  border-right-style: dashed!important
}

[data-timeline-axis-style=dotted]:before {
  border-right-style: dotted!important
}

.timeline-block {
  position: relative;
  margin: 2em 0;

  padding-bottom: 10px;
}

.timeline-block:after {
  display: table;
  clear: both;
  content: ''
}

.timeline-block:first-child {
  margin-top: 0
}

.timeline-block:last-child {
  margin-bottom: 0
}

.timeline-step {
  font-size: 1rem;
  font-weight: 600;
  position: absolute;
  z-index: 1;
  left: 0;
  display: inline-flex;
  width: 33px;
  height: 33px;
  transform: translateX(-50%);
  text-align: center;
  border-radius: 50%;
  align-items: center;
  justify-content: center
}

.timeline-step i,.timeline-step svg {
  line-height: 1.4
}

.timeline-step-icon {
  border: 2px solid #e9ecef;
  background: #fff
}

.timeline-step-xs {
  font-size: .75rem;
  width: 17px;
  height: 17px
}

.timeline-step-sm {
  font-size: .75rem;
  width: 23px;
  height: 23px
}

.timeline-step-lg {
  font-size: 1.75rem;
  width: 47px;
  height: 47px
}

.timeline-content {
  position: relative;
  position: relative;
  top: -6px;
  margin-left: 60px;
  padding-top: .5rem;

  background: rgba(100,200,100, 0.1);
    border-radius: 10px;
}

.timeline-content:after {
  display: table;
  clear: both;
  content: ''
}

.timeline-body {
  padding: 1.5rem
}

@media (min-width: 992px) {
  .timeline:before {
      left:50%;
      margin-left: -2px
  }

  .timeline-step {
      left: 50%
  }

  .timeline-content {
      width: 38%
  }

  .timeline-body {
      padding: 1.5rem
  }

  .timeline-block:nth-child(even) .timeline-content {
      float: right
  }

  [data-timeline-axis-color=primary]:before {
      border-color: #5e72e4
  }

  [data-timeline-axis-color=secondary]:before {
      border-color: #f7fafc
  }

  [data-timeline-axis-color=success]:before {
      border-color: #2dce89
  }

  [data-timeline-axis-color=info]:before {
      border-color: #11cdef
  }

  [data-timeline-axis-color=warning]:before {
      border-color: #fb6340
  }

  [data-timeline-axis-color=danger]:before {
      border-color: #f5365c
  }

  [data-timeline-axis-color=light]:before {
      border-color: #adb5bd
  }

  [data-timeline-axis-color=dark]:before {
      border-color: #212529
  }

  [data-timeline-axis-color=default]:before {
      border-color: #172b4d
  }

  [data-timeline-axis-color=white]:before {
      border-color: #fff
  }

  [data-timeline-axis-color=neutral]:before {
      border-color: #fff
  }

  [data-timeline-axis-color=darker]:before {
      border-color: #000
  }
}

.timeline-one-side:before {
  left: 1rem
}

.timeline-one-side .timeline-step {
  left: 1rem
}

.timeline-one-side .timeline-content {
  width: auto
}

@media (min-width: 992px) {
  .timeline-one-side .timeline-content {
      max-width:30rem
  }
}

.timeline-one-side .timeline-block:nth-child(even) .timeline-content {
  float: none
}

`}
        </style>
      </>
    );
  }
}

export default connect(mapStateToProps)(ListEntryrenComponent);
