import React, { Component } from 'react';

import { Drawer,Icon, Button } from 'antd';

import { ScaleLoader } from 'react-spinners';
import BSCCE from '../../apis/BSCCEApi';

import '../../../node_modules/antd/lib/drawer/style/css';
import '../../../node_modules/antd/lib/date-picker/style/css';
import '../../../node_modules/antd/lib/switch/style/css';
import '../../../node_modules/antd/lib/dropdown/style/css';
import '../../../node_modules/antd/lib/badge/style/css';
import '../../../node_modules/antd/lib/message/style/css';
import '../../../node_modules/antd/lib/notification/style/css';

export class DeleteConstantsComponent extends Component {

  actions = {};

    state = {
        constantToDelete: '',
        deleting: false,
        deleted: false
    };

    constructor (props) {
        super (props);

        this.actions = props.actions;
    }

    render() {
        return (
            <div>
                <Drawer
                    title="Confirm Deletion"
                    width={400}
                    closable={false}
                    onClose={this.actions.delete.onCloseDeleteconfirmationDrawer}
                    visible={this.props.canDeleteConstants   && this.props.showDeleteConstantsDrawer}
                    open={this.props.canDeleteConstants   && this.props.showDeleteConstantsDrawer}
                >
                    Are you sure your want to delete {this.props.constantToEdit && <b>{this.props.constantToEdit.name}</b>}?
                     <br /><br />

                    {!this.state.deleted && <Button type="primary" onClick={this.actions.delete.onDeleteConstant  } style={{ border: 'darkred', color: 'darkred' }} disabled={this.state.deleting}>
                        {!this.state.deleted && !this.state.deleting && <>Yes, delete {this.state.constantToEdit && this.state.constantToEdit.title} </>}
                        {this.state.deleting && <>Deleting  <Icon type="loading" color="#52c41a" /> </>}
                    </Button>
                    }

                    <br /><br />

                    {!this.state.deleted && <Button type="primary" disabled={this.state.deleting} onClick={this.onCloseDeleteconfirmationDrawer}>No, cancel</Button>}


                    {this.state.deleted && <Button onClick={this.onCloseDeleteconfirmationDrawer} type="success"> Finish </Button>}

                </Drawer>
            </div>
        )
    }
}

export default DeleteConstantsComponent
