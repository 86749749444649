/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { setState } from "react";
import PropTypes, {instanceOf} from 'prop-types'; 
import { withCookies, Cookies } from 'react-cookie';
import BSCCE from '../../apis/BSCCEApi';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";


import { ScaleLoader } from 'react-spinners';
import { IconButton } from '@material-ui/core';


import LockIcon from '@material-ui/icons/Lock';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

class Login extends React.Component {

  state = {
    submitting: false,
    canContinue: false,
    // promptAuth: this.props.location.state.promptAuth || false,
    user: '',
    password: '',
    userData: {roles: []},
    selectedUserRole: {}
  };

  cookies = {};
  
  onChange = (e) => { 
    this.setState ({ [e.target.name]: e.target.value });
  };

  
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };


  constructor (props) {
    console.log ('login props', props);
    super(props);

    this.cookies = props.cookies;

    console.log (this.state.promptAuth);
  }

onContinue = (e) => {
  console.log (this.props);

  this.props.history.push('/admin/index');
}


onGoBack = (e) => {
    this.props.history.push('/auth/login?test');
}

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            {/* <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>Sign in with</small>
              </div>
              <div className="btn-wrapper text-center">
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/github.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Github</span>
                </Button>
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/google.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
              </div>
            </CardHeader> */}
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
              <h1>Easy tiger!</h1>
                <small>
                     This is not an action you can perform
                </small>
              </div>
             
             <p>You have attempted to perform and action which requires permissions greater than those assigned to you. If you think this is an error, please contact your administration.</p>
             
              <Button className="my-4" 
                   color={"primary"}
                   type="button" 
                   onClick={this.onGoBack}
                   >       
                   go back
                  </Button> 

            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Create new account</small>
              </a>
            </Col>
          </Row>
        </Col>


         <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          // open={this.state.promptAuth}
          autoHideDuration={1000}
          onClose={() => { this.setState.bind({promptAuth: false}) } }
        >
          <MySnackbarContentWrapper
            onClose={() => {}}
            variant="warning"
            message="Please log in first"
          />
        </Snackbar>

         <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={(!! this.state.err)}
          autoHideDuration={3000}
          onClose={() => {}}
        >
          <MySnackbarContentWrapper
            onClose={() => {}}
            variant="danger"
            message={this.state.err}
          />
        </Snackbar>
      </>
    );
  }
}


// snackbars

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));


function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = WarningIcon;


  return (
    <SnackbarContent
      className='warning'
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};



export default withCookies(Login);
