import {
  UNSET_VIEW_AS_USER_INFO,
  SET_VIEW_AS_USER_INFO
} from '../actions/viewAsActions';

const initialState = {};

function userReducer(state = initialState, action) {
  console.log(action);
  // debugger;
  switch (action.type) {

    case SET_VIEW_AS_USER_INFO:
      console.log('set user reducer ', action);
      return {
          viewAsTokens: action.viewAsUserTokens,
          active: true
  
    }

 
      case UNSET_VIEW_AS_USER_INFO:
      return {
          viewAsTokens: {},
          active: false
      };

      default:
        return state;
  };
}

export default userReducer;