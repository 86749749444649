/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    CardFooter,
    Col,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Container,
    Row,
    UncontrolledTooltip
} from "reactstrap";


import { ScaleLoader } from 'react-spinners';

import { Drawer, Form, Input, message, Upload, Select, DatePicker, Icon, Switch, Menu, Dropdown, notification, Tag, Empty, Table } from 'antd';
import moment from 'moment';
import Truncate from 'react-truncate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faEye } from '@fortawesome/free-solid-svg-icons';

import { faArrowCircleRight, faChevronCircleRight, faCross, faTimes, faAddressBook, faCaretRight } from '@fortawesome/free-solid-svg-icons'

import { connect } from 'react-redux';



// import Drawer from '@material-ui/core/Drawer';
import 'antd/lib/drawer/style/css';
import 'antd/lib/date-picker/style/css';
import 'antd/lib/switch/style/css';
import 'antd/lib/dropdown/style/css';
import 'antd/lib/tag/style/css';
import 'antd/lib/message/style/css';
import 'antd/lib/notification/style/css';
import 'antd/lib/notification/style/css';
import 'antd/lib/table/style/css';


// core components
import BlankHeader from "components/Headers/BlankHeader.jsx";

import BSCCE from '../../../apis/BSCCEApi';
import Moment from "react-moment";


const { RangePicker } = DatePicker;
const { TextArea } = Input;


const girlChildIcon = require('assets/img/undraw/undraw_doll_play_evbw.svg')
const boyChildIcon = require('assets/img/undraw/undraw_toy_car_7umw.svg')


const mapStateToProps = (state) => {
    return {
        notesFromStore: state.notes
    }
}


class ListChildrenCardsComponent extends React.Component {

    state = {
        childToCreate: {}
    }

    constructor(props) {
        super(props);

        console.log('child component props ', props);
    }

    openNotificationWithIcon = (type, message, description) => {
        notification[type]({
            message,
            description
        });
    };




    onSelectChildFromList = (e) => {
        this.setState({ selectedChild: e });
    }


    onFieldChange = (e) => this.setState({ [e.target.name]: e.target.value });

    render() {

        const columns = [
            {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
            },
            {
                title: 'Last Name',
                dataIndex: 'lastName',
                key: 'lastName',
                // width: '12%',
            },
            {
                title: 'D.O.B',
                dataIndex: 'dob',
                // width: '30%',
                key: 'dob',
                render: (record, i) => {
                    return <Moment format="D MMM YYYY" date={record} />
                }
            },
            {
                title: '',
                key: 'action',
                render: (text, record) => {

                    // return (
                    //     <UncontrolledDropdown>
                    //         <DropdownToggle
                    //             className="btn-icon-only text-light"
                    //             // href="#pablo"
                    //             role="button"
                    //             size="sm"
                    //             color=""
                    //             onClick={() => { this.props.actions.list.onSelectChildFromList({ focusedChild: record }) }}
                    //         >
                    //             <FontAwesomeIcon icon={faEye} style={{ color: 'green' }} />

                    //         </DropdownToggle>

                    //     </UncontrolledDropdown>
                    // )

                }
            }
        ];


        return (
            <>

                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <Col>



                            {/* { 
                            this.props.children && this.props.children.length > 0 && <>
                            
                            <Card className="card-stats mb-4 mb-xl-0">
                                <Button onClick={() => {
                                    this.props.actions.list.onSelectChildFromList(
                                        {
                                            focusedChildId: '1234567890',
                                        }
                                    )
                                }}>
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    Girl Child
                                             
                                                </CardTitle>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-danger text-white rounded-circle shadow">

                                                    <img src={girlChildIcon} style={{ height: 'auto', width: '100px' }} alt="Child Icon" />

                                                </div>
                                            </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-muted text-sm">
                                            <span className="text-nowrap">Show child name</span>
                                            <span className="text-success mr-2"> {' '} {' '}
                                                <FontAwesomeIcon icon={faArrowCircleRight} />
                                            </span>
                                        </p>
                                    </CardBody>
                                </Button>
                            </Card>

                            </>
                        } */}

                            <Card>
                                {!this.props.focusedChild && <>

                                    <Table
                                        columns={columns}
                                        loading={this.props.loading}
                                        dataSource={this.props.children}
                                        scroll={{ x: 1 }}
                                    />
                                </>
                                }
                            </Card>



                            {this.props.focusedChild && this.props.focusedChild._id && <>

                                <Card>
                                    <CardBody>

                                        <Row>


                                            <Col>
                                                <span style={{ fontSize: '16pt', fontWeight: 'bold', color: 'black' }}
                                                    onClick={() => { this.props.actions.list.onClearChildContext(null) }}
                                                >
                                                    <a style={{ display: 'block', float: 'left', color: 'black' }} class="btn btn-sm btn-neutral" >


                                                        <FontAwesomeIcon icon={faAngleLeft} />  &nbsp;

                                                        back
                                                        </a>
                                                    {'  '}

                                                    &emsp; {this.props.focusedChild.firstName} {' '} {this.props.focusedChild.lastName}

                                                </span>

                                            </Col>
                                            {/* <Col>
                                                <a style={{ display: 'block', float: 'right' }} class="btn btn-sm btn-neutral" onClick={() => { this.props.actions.list.onClearChildContext(null) }} >

                                                    <FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} />
                                                    Clear Filter
                                                </a>
                                            </Col> */}




                                        </Row>






                                    </CardBody>

                                </Card>


                            </>
                            }



                        </Col>
                    </Row>

                </Container>

            </>
        );
    }
}

export default connect(mapStateToProps)(ListChildrenCardsComponent);
