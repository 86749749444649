/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  // Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

import { Button, message } from 'antd'
import Async from 'react-async'

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import BSCCE from '../../apis/BSCCEApi'
import Header from "components/Headers/Header.jsx";
import ParentDashboardWelcomeIntroDrawer from '../../components/Drawers/Parents/ParentDashboardWelcomeIntroDrawer';
import DashboardNewFileNotificationModal from '../../components/Modals/Dashboards/DashboardNewFileNotificationModal';

import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'


import { withCookies, Cookies } from 'react-cookie';
import PropTypes, { instanceOf } from 'prop-types';
import { Redirect } from "react-router-dom";


const mapStateToProps = (state) => {
  return {
    currentUser: state.user,
    tokens: state.tokens
  }
}


const noUnreadMessagesPNG = require('assets/img/undraw/undraw_chore_list_iof3.png')
const readOurNewsletterPNG = require('assets/img/undraw/undraw_reading_0re1.png');

class Parents extends React.Component {

  cookies = {};

  // remoteData = {
  //   loadNewsletterData: async ({ playerId }, { signal }) => {
  //     const res = await fetch(`https://api.bscce.com/files/`, { signal })
  //     if (!res.ok) throw new Error(res.statusText)
  //     return res.json()
  //   }
  // }

  constructor(props) {

    super(props);




    this.cookies = props.cookies;

  }

  componentDidMount() {

    if (this.props.location.search) {
      var search = this.props.location.search.substring(1);
      this.setState({
        ...this.state,
        qs: JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
      })
    }

    if (!this.cookies.get('bpis')) {
      this.setState({
        welcomeDrawerVisible: true
      });

      console.log('did mount props', this.props);

      this.cookies.set('bpis', 1, { path: '/' });

    }


  }

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  state = {
    welcomeDrawerPosition: 'bottom',
    welcomeDrawerVisible: false,
    downloadingNewsletter: false
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  // onDownloadNewsletter(id) {

  //   this.setState({
  //     ...this.state,
  //     downloadingNewsletter: true,
  //   })

  //   console.log('download ', id);
  //   BSCCE.files.getFile({ id: id })
  //     .then((resp) => {
  //       console.log('open ', resp.data.data.url);
  //       window.open(resp.data.data.url, '_blank');
  //       this.setState({
  //         ...this.state,
  //         downloadingNewsletter: false
  //       })
  //     })
  // }


  render() {
    return (
      <>

        {
          this.state.qs && this.state.qs.r && this.state.qs.r == 'ndn' &&
          <>
            <Redirect to={{
              pathname: "/admin/documents",
              search: this.props.location.search,
            }} />
          </>
        }

        <Header />

        <ParentDashboardWelcomeIntroDrawer visible={this.state.welcomeDrawerVisible} placement={this.state.welcomeDrawerPosition} onClose={() => { this.setState({ ...this.state, welcomeDrawerVisible: false }) }} />


        <DashboardNewFileNotificationModal visible={this.state.qs && this.state.qs.r == 'ndn'} placement={this.state.welcomeDrawerPosition} onClose={(navigate) => { if (navigate) { this.props.history.push('/admin/documents') } else { this.setState({ ...this.state, qs: { r: '' } }) } }} />





        <Container className="mt--7" fluid >
          <Row >
            <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="bg-gradient-default shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <h4 className="text-uppercase text-light ">
                      &emsp; Welcome to your Parents' Portal
                      </h4>
                    {' '}


                    {/* <a href=''>
                      <h2 className="text-white mb-0">&emsp; learn more about the portal and its features <FontAwesomeIcon icon={faArrowCircleRight} /></h2>
                    </a> */}


                  </Row>
                </CardHeader>
                {/* <CardBody>
                 ??  room for a notice ??
                </CardBody> */}
              </Card>
              <div style={{ height: '60px' }}>&nbsp;</div>
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <h6 className="text-dark ls-1 mb-1">
                      <span>
                        &emsp;Hello, {this.props.currentUser.firstName && this.props.currentUser.firstName.trim()}. You have no new messages.
                      </span>
                    </h6>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>

                      <img src={readOurNewsletterPNG}
                        style={{ marginTop: '20px' }} style={{ maxWidth: '250px' }} />

                    </Col>
                    <Col>

                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Welcome to your new Parents' Portal
                      </h6>

                      <div style={{ marginTop: '5px' }}> </div>

                      <br />
                      <p>
                        Your Parents' Portal is the new, faster and easier way to interact with BrightStart ChildCare &amp; Education.
                      </p>
                      <br />
                      <p>
                        You will be able to access direct messages from the nursery staff as well as updates about your child(ren)'s care.
                        We will also use your Parents' Portal to upload safely share documents with you.
                      </p>

                      <br />

                      <p>You can learn more about your Parents' Portal and stay up to date with its latest features by clicking on "Learn more about your Parents' Portal" in the menu bar.</p>

                      {/* <Async promiseFn={this.remoteData.loadNewsletterData} >

                        {
                          ({ data, error, isPending }) => {
                            if (isPending) return "Loading..."
                            if (error) return `Something went wrong: ${error.message}`
                            if (data) {
                              let newsletters = data.data;
                              let mostRecentDocument = newsletters[newsletters.length - 1];
                              return (

                                <div>

                                  {mostRecentDocument && <>
                                    <h2 className="mb-0">{mostRecentDocument.title}</h2>

                                    <div style={{ marginTop: '5px' }}> </div>

                                    <p> {mostRecentDocument.description}</p>

                                    <div style={{ marginTop: '20px', }}>


                                      <Button onClick={() => { this.onDownloadNewsletter(mostRecentDocument._id) }} loading={this.state.downloadingNewsletter}>read now &emsp; <FontAwesomeIcon icon={faChevronCircleRight} /></Button>

                                    </div>
                                  </>}
                                </div>
                              )

                            }
                          }
                        }

                      </Async>

<br />
                      <p style={{ marginTop: '7px' }}>
                        <a href="documents">view all documents <FontAwesomeIcon icon={faArrowCircleRight} /></a>
                      </p> */}

                    </Col>
                  </Row >
                </CardBody >
              </Card >
            </Col >

            <Col xl="4">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Noticeboard
                      </h6>
                      <h2 className="mb-0">You have no unread messages</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div className="chart">
                    <img src={noUnreadMessagesPNG}
                      style={{ marginTop: '80px', maxWidth: '250px' }} />

                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row >
          {/* 

          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Page visits</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        See all
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Page name</th>
                      <th scope="col">Visitors</th>
                      <th scope="col">Unique users</th>
                      <th scope="col">Bounce rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">/argon/</th>
                      <td>4,569</td>
                      <td>340</td>
                      <td>
                        <i className="fas fa-arrow-up text-success mr-3" />{" "}
                        46,53%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/index.html</th>
                      <td>3,985</td>
                      <td>319</td>
                      <td>
                        <i className="fas fa-arrow-down text-warning mr-3" />{" "}
                        46,53%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/charts.html</th>
                      <td>3,513</td>
                      <td>294</td>
                      <td>
                        <i className="fas fa-arrow-down text-warning mr-3" />{" "}
                        36,49%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/tables.html</th>
                      <td>2,050</td>
                      <td>147</td>
                      <td>
                        <i className="fas fa-arrow-up text-success mr-3" />{" "}
                        50,87%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/profile.html</th>
                      <td>1,795</td>
                      <td>190</td>
                      <td>
                        <i className="fas fa-arrow-down text-danger mr-3" />{" "}
                        46,53%
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Social traffic</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        See all
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Referral</th>
                      <th scope="col">Visitors</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Facebook</th>
                      <td>1,480</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">60%</span>
                          <div>
                            <Progress
                              max="100"
                              value="60"
                              barClassName="bg-gradient-danger"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Facebook</th>
                      <td>5,480</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">70%</span>
                          <div>
                            <Progress
                              max="100"
                              value="70"
                              barClassName="bg-gradient-success"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Google</th>
                      <td>4,807</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">80%</span>
                          <div>
                            <Progress max="100" value="80" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Instagram</th>
                      <td>3,678</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">75%</span>
                          <div>
                            <Progress
                              max="100"
                              value="75"
                              barClassName="bg-gradient-info"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">twitter</th>
                      <td>2,645</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">30%</span>
                          <div>
                            <Progress
                              max="100"
                              value="30"
                              barClassName="bg-gradient-warning"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
       
        */}
        </Container >
      </>
    );
  }
}

export default connect(mapStateToProps)(withCookies(Parents));
