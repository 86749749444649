  
export const SET_CHILD_CONTEXT = 'SET_CHILD_CONTEXT';
export const UNSET_CHILD_CONTEXT = 'UNSET_CHILD_CONTEXT';

export function SET_CONTEXT_CHILD (contextChild) {
  let action = { type: SET_CHILD_CONTEXT, contextChild };
  return action;
}

export function UNSET_CONTEXT_CHILD() {
  return { type: UNSET_CHILD_CONTEXT, contextChild: null };
}

// export function SET_CHILD_ROLE(role) {
//   return { type: SET_CHILD_ROLE, role: role };
// }



