import React, { Component } from 'react'

import { Drawer, Checkbox, Input, DatePicker, message, Switch } from 'antd';

import {
    Button,

} from "reactstrap";


import { ScaleLoader } from 'react-spinners';

import BSCCEApi from '../../apis/BSCCEApi';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';


const { RangePicker } = DatePicker;
const { TextArea } = Input;


export class CreateVacancyComponent extends Component {

    state = {
        vacancyToCreate: {
            title: '',
            description: '',
            requirements: '',
            status: '',
            scope: ',,',
            tags: ''
        }
    };

    onCommitNewVacancy = (vacancy) => {

        console.log(this.state.vacancyToCreate)


        BSCCEApi.vacancies.createVacancy(this.state.vacancyToCreate)
            .then(resp => {
                console.log('on after submit', resp);

                if (resp.data.status == 200) {
                    message.success ("Vacancy created successfully");
                    this.props.actions.onCompleteCreateVacancy(resp);

                } else {

                    message.error(resp.data.data.reason);
                }


            })
            .catch(e => {
                console.log('on error', e);
            })

    }

    onFieldChange = (e) => this.setState({
        vacancyToCreate: {
            ...this.state.vacancyToCreate,
            [e.target.name]: e.target.value
        }
    });



    // title: '',
    // description: '',
    // requirements: '',
    // status: '',
    // scope: ',,',
    // tags: ''


    permissionModel = (roleName) => {
        return {
            [roleName]: {

            }
        }
    }


    render() {
        return (
            <>
                <Drawer
                    title="Create a new vacancy"
                    width={520}
                    closable={false}
                    anchor="right"
                    onClose={this.props.actions.onCloseCreateVacancyDrawer}
                    // open={this.state.canCreateVacancy && this.state.showCreateVacancyDrawer}
                    // visible={this.state.canCreateVacancy && this.state.showCreateVacancyDrawer}
                    open={this.props.canCreateVacancy && this.props.showCreateVacancyDrawer}
                    visible={this.props.canCreateVacancy && this.props.showCreateVacancyDrawer}
                >

                    <b>Job Title<i>(shown)</i></b>
                    <Input name="title" onChange={(e) => { this.onFieldChange(e) }} placeholder="e.g., Practitioner" />

                    <br />
                    <br />
                    <b>Job Description <i>(shown)</i></b>
                    <TextArea rows={4} placeholder="e.g., Description of role and responsibilites" name="description" onChange={(e) => { this.onFieldChange(e) }} />

                    <br />
                    <br />

<b>Job Requirements <i>(shown)</i></b>
<br />
<Input placeholder="e.g., Qualifications" name="requirements" onChange={(e) => { this.onFieldChange(e) }} />

<br />
<br />

<b>Type <i>(shown)</i></b>
<br />
<Input placeholder="e.g., Full time" name="type" onChange={(e) => { this.onFieldChange(e) }} />

<br />
<br />

                    <b>Status<i>(internal)</i></b>
                    <br />
                   
                    <Switch
                        checkedChildren={<>
                            <a><b>Show</b> this vacancy on the bscce.com website </a>
                            <FontAwesomeIcon icon={faCheckCircle} />
                        </>}ß
                        unCheckedChildren={<>
                            <a><b>Do not</b> show this vacancy on the bscce.com website </a>
                        </>}
                        defaultUnChecked
                        name="notice_persist"
                        onChange={(e) => { this.setState ({ vacancyToCreate: { ...this.state.vacancyToCreate, status: e }})}}
                    />
                    <br /><br />

                    <b>Scope <i></i></b>
                    <br />
                    <Input placeholder="alicia.thomas@gmail.com" name="password" type="password" onChange={(e) => { this.onFieldChange(e) }} />



                    {/* <br />
                    <br />
                    <b>Roles <i>(shown)</i></b>
                    <p>This determines what functionality will be available to the vacancy and will change layout of the portal depending on the content being shown to this vacancy. </p>
                    < br />



                    <Checkbox
                        checked={this.state.vacancyToCreate.isParent}
                        // disabled={(this.state.vacancyToCreate.isStaff || this.state.vacancyToCreate.isAdmin)}
                        onChange={(e) => {
                            this.setState({
                                vacancyToCreate: {
                                    ...this.state.vacancyToCreate,
                                    isParent: !this.state.vacancyToCreate.isParent
                                }
                            });
                        }}
                    >
                        {' '} Parent
                    </Checkbox>

                    <br />

                    <Checkbox
                        checked={this.state.vacancyToCreate.isStaff}
                        // disabled={(this.state.vacancyToCreate.isAdmin || this.state.vacancyToCreate.isParent)}
                        onChange={(e) => {
                            this.setState({
                                vacancyToCreate: {
                                    ...this.state.vacancyToCreate,
                                    isStaff: !this.state.vacancyToCreate.isStaff
                                }
                            });
                        }}
                    >
                        {' '} Staff
                    </Checkbox>

                    <br />

                    <Checkbox
                        checked={this.state.vacancyToCreate.isAdmin}
                        // disabled={(this.state.vacancyToCreate.isParent || this.state.vacancyToCreate.isStaff)}
                        onChange={(e) => {
                            this.setState({
                                vacancyToCreate: {
                                    ...this.state.vacancyToCreate,
                                    isAdmin: !this.state.vacancyToCreate.isAdmin
                                }
                            });
                        }}
                    >
                        {' '} Admin
                    </Checkbox> */}

                    <br />
                    <br />

                    {/* <b>Mark this as the current Vacancy?</b>
                    <br />
                    <br />
                    <Switch
                        name='isCurrentVacancy'
                        onChange={() => {
                            this.setState({
                                VacancyToCreateVars: {
                                    ...this.state.VacancyToCreateVars,
                                    isCurrentVacancy: !this.state.VacancyToCreate.isCurrentVacancy
                                }
                            })
                        }}
                        checkedChildren={
                            <>
                                <a>&emsp;Will be shown as the current Vacancy dates on the website &nbsp;</a>
                            </>
                        }
                        unCheckedChildren={
                            <>

                                <a>&emsp;Will not be shown as the current Vacancy on the website &emsp;</a>
                            </>
                        }
                        defaultChecked
                    /> */}

                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                        }}
                    >
                        {!this.state.committed && <Button style={{
                            marginRight: 8,
                        }} disabled={this.state.committing} onClick={this.props.actions.onCloseCreateVacancyDrawer} >Cancel</Button>}
                        <>

                            {!this.state.committed &&
                                <Button
                                    type="primary"
                                    onClick={this.onCommitNewVacancy}
                                    disabled={this.state.props}
                                    loading={this.state.committing}
                                    style={{ marginTop: 16 }}
                                >

                                    {!this.state.committed && !this.state.committing && 'Publish'}

                                </Button>
                            }


                            {/* <Button onClick={this.onCommitNewNotice} disabled={this.state.committing} type="primary">

                                {!this.state.committed && !this.state.committing && 'Publish'}

                                {this.state.committing && <>Publishing  <Icon type="loading" color="#52c41a" /> </>}

                            </Button> */}


                            {this.state.committed && <Button onClick={this.actions.onCloseCreateVacancyDrawer} type="success"> Finish </Button>}

                        </>

                    </div>
                </Drawer>   </>
        )
    }
}

export default CreateVacancyComponent
