/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip
} from "reactstrap";


import { Drawer, Form, Input, message, Upload, Select, DatePicker, Icon, Switch, Menu, Dropdown, notification, Tag } from 'antd';
import moment from 'moment';
import Truncate from 'react-truncate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faAmbulance, faBriefcase, faBullhorn, faCalendarAlt, faCamera, faCheckCircle, faClock, faCloudSun, faCloudSunRain, faCloudShowersHeavy, faHeartbeat, faHourglassEnd, faCogs, faHourglassStart, faHourglass, faKey, faSnowboarding, faSnowflake, faPlusCircle, faSync } from '@fortawesome/free-solid-svg-icons';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';

import { connect } from 'react-redux';



// import Drawer from '@material-ui/core/Drawer';
import '../../../node_modules/antd/lib/drawer/style/css';
import '../../../node_modules/antd/lib/date-picker/style/css';
import '../../../node_modules/antd/lib/switch/style/css';
import '../../../node_modules/antd/lib/dropdown/style/css';
import '../../../node_modules/antd/lib/tag/style/css';
import '../../../node_modules/antd/lib/message/style/css';
import '../../../node_modules/antd/lib/notification/style/css';


import CreateDocumentsComponent from "../Documents/CreateDocumentsComponent";
import EditDocumentsComponent from "../Documents/EditDocumentsComponent";
import DeleteDocumentsComponent from "../Documents/DeleteDocumentsComponent";
import ListDocumentsComponent from "../Documents/ListDocumentsComponent";
import ListDocumentsCategoriesComponent from "../Documents/ListDocumentsCategoriesComponent";


// core components
import BlankHeader from "components/Headers/BlankHeader.jsx";

import BSCCE from '../../apis/BSCCEApi';
import NewDocumenetNotificationModal from "../../components/Modals/Documents/NewDocumentNotificationModal";


const { RangePicker } = DatePicker;
const { TextArea } = Input;


const mapStateToProps = (state) => {
  return {
    currentUser: state.user
  }
}


class Documents extends React.Component {

  constructor(props) {
    super(props);

    console.log('documents props ', props);
  }

  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description
    });
  };

  componentDidMount() {

    let qs = {};
    if (this.props.location.search) {
      var search = this.props.location.search.substring(1);

      qs = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');

    if (qs && qs.r == 'ndn') {

      
      this.setState({
        ...this.state,
        qs: qs,
        ndnLoading: true

      })
    }

      console.log('should load');

          BSCCE.files.getFile({ id: qs.d })
        .then(resp => {

          if (resp.data.status == 200) {
            this.setState({
              ndnLoading: false,
              newDocument: {
                ...resp.data.data
              }
            })
          }

        })
    }

  }

  state = {
    qs: {},
    filters: [

    ],
    toggles: {
      showCreateDocumentDrawer: false,
      showDeleteDocumentDrawer: false,
      showEditDocumentDrawer: false
    },
    // semephores
    sem: {
      deleting: false,
      deleted: false,
      committing: false,
      committed: false,
      uploading: false,
      uploaded: false,
      loading: true
    },
    // permissions
    perms: {
      canCreateDocument: true,
      canDeleteDocument: true,
      canEditDocument: true
    },
    // mutable
    mut: {
      documentToCreate: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        isActive: '',
        isVerified: '',
        roles: []
      },
      focusedDocument: {},
      focusedCategory: null,
      fileCategoryList: [],
      documents: []
    }
  }

  componentWillMount() {

    this.setState({
      sem: {
        ...this.state.sem,
        loadingCategories: true
      }
    });

    // 

    // if user role is parent
    let filterContactId = this.props.currentUser.activeRole == 'admin' ? null : this.props.currentUser.contactId

    BSCCE.files.getFileCategories(filterContactId)
      .then(res => {

        if (res.status == 200) {
          this.setState({

            mut: {
              ...this.state.mut,
              fileCategoryList: res.data.data
            },
            sem: {
              ...this.state.sem,
              loadingCategories: false
            }

          });


        } else {

          message.error('Unable to load Documents. Please try again.', 5);
          console.log('failed')

        }

      })
  }

  actions = {
    list: {
      onSelectDocumentFromList: (item) => {
        this.setState({
          mut: {
            ...this.state.mut,
            focusedDocument: item
          }
        });
      },
      onSelectCategoryFromList: (item) => {
        this.setState({
          mut: {
            ...this.state.mut,
            focusedCategory: item,
            documents: []
          }
        })


        if (!!item) {

          this.setState({
            sem: {
              ...this.state.sem,
              loadingDocuments: true
            }
          })



          let filters = {};
          filters.category = item;


          if (this.props.currentUser.activeRole != 'staff') {
            filters.forContactIds = this.props.currentUser.contactId
          }



          BSCCE.files.queryFiles(
            filters
          )
            .then(resp => {
              console.log(resp);
              if (resp.status == 200) {

                this.setState({
                  mut: {
                    ...this.state.mut,
                    documents: resp.data.data
                  },
                  sem: {
                    ...this.state.sem,
                    loadingDocuments: false
                  }
                })

              } else {

                message.error('could not retireve files', 4);

              }

            })
        }

      }
    },
    create: {
      onShowCreateDocumentDrawer: (e) => {

        this.setState({
          toggles: {
            ...this.state.toggles,
            showCreateDocumentDrawer: true
          }
        })
      },
      onCloseCreateDocumentDrawer: (e) => {
        this.setState({
          toggles: {
            ...this.state.toggles,
            showCreateDocumentDrawer: false
          }
        });
      },
      onCompleteCreateDocument: (e) => {
        this.setState({
          mut: {
            ...this.state.mut,
            documents: [...this.state.mut.documents, e]
          }
        })
      }
    },
    edit: {
      onShowEditDocumentDrawer: (item) => {
        console.log('edit', item);
        this.setState({
          toggles: {
            ...this.state.toggles,
            showEditDocumentDrawer: true,
          },
          mut: {
            ...this.state.mut,
            focusedDocument: item
          }
        })

      },
      onCloseEditDocumentDrawer: (e) => {

        this.setState({
          toggles: {
            ...this.state.toggles,
            showEditDocumentDrawer: false
          },
          mut: {
            ...this.state.mut,
            focusedDocument: null
          }
        })

      },
    },

    delete: {
      onshowDeleteDocumentDrawer: (e) => {
        console.log('delete', e);
        this.setState({
          toggles: {
            ...this.state.toggles,
            showDeleteDocumentDrawer: true
          }
        })
      },
      onCloseDeleteDocumentDrawer: (e) => {
        this.setState({
          toggles: {
            ...this.state.toggles,
            showDeleteDocumentDrawer: false
          }
        })
      },
      onDeleteDocument: (e) => {
        console.log('delete now', this.state.mut.focusedDocument._id)
        this.setState({
          sem: {
            ...this.state.sem,
            deleting: true
          }
        });

        let document = {
          id: this.state.mut.focusedDocument._id,
          key: this.state.mut.focusedDocument.key,
        }

        BSCCE.files.deleteFiles(this.state.mut.focusedDocument._id)
          .then((res) => {

            if (res.data.status == 200) {
              this.setState({
                sem: {
                  deleting: false,
                  deleted: true
                },
                mut: {
                  ...this.state.mut,
                  documents: this.state.mut.documents.filter(document => document._id != this.state.mut.focusedDocument._id)
                }
              });

              this.actions.delete.onDeleteComplete();
            } else {
              // this.actions.delete.onDeleteFailed ()
              // error handler
              alert('an error occured');
              console.log(res);
            }

          })

          .catch((err) => {
            console.log('error', err);
          })




      },

      onDeleteComplete: (e) => {
        this.actions.edit.onCloseEditDocumentDrawer();
        this.actions.delete.onCloseDeleteDocumentDrawer();
        this.openNotificationWithIcon(
          'success',
          'Delete success',
          // `has now been deleted.`
          `${this.state.mut.focusedDocument.firstName} has now been deleted.`
        );

        this.setState({
          sem: {
            ...this.state.sem,
            deleting: false,
            deleted: false,
          },
          mut: {
            ...this.state.mut,
            focusedDocument: null
          }
        });

      }
    }
  };



  render() {
    return (

      <>

        <NewDocumenetNotificationModal visible={this.state.qs && this.state.qs.r == 'ndn'} placement={this.state.welcomeDrawerPosition} onClose={(navigate) => { if (navigate) { this.props.history.push('/admin/documents') } else { this.setState({ ...this.state, qs: { r: '' } }) } }} loading={this.state.ndnLoading} document={this.state.newDocument} />


        <CreateDocumentsComponent
          canCreateDocument={this.state.perms.canCreateDocument}
          showCreateDocumentDrawer={this.state.toggles.showCreateDocumentDrawer}
          documentToCreate={this.state.mut.documentToCreate}
          focusedDocument={this.state.mut.focusedDocument}
          actions={this.actions.create}
        />

        <DeleteDocumentsComponent
          canDeleteDocument={this.state.perms.canDeleteDocument}
          showDeleteDocumentDrawer={this.state.toggles.showDeleteDocumentDrawer}
          onCloseDeleteDocumentDrawer={this.actions.delete.onCloseDeleteDocumentDrawer}
          onDeleteComplete={this.onDeleteComplete}
          documentToEdit={this.state.mut.focusedDocument}
          focusedDocument={this.state.mut.focusedDocument}
          actions={this.actions}
        />


        <EditDocumentsComponent
          canEditDocument={this.state.perms.canEditDocument}
          showEditDocumentDrawer={this.state.toggles.showEditDocumentDrawer}
          onCloseEdiDocumentDrawer={this.actions.edit.onCloseEdiDocumentDrawer}
          documentToEdit={this.state.mut.focusedDocument}
          onFieldChange={this.onFieldChange}
          actions={this.actions}
        />

        <BlankHeader pageTitle={["Documents"]} actions={[
          {
            icon: faPlusCircle,
            iconColour: 'green',
            title: 'Create',
            cb: this.actions.create.onShowCreateDocumentDrawer,
            forUserRoles: ['admin']
          }
          // ,{
          //   icon: faSync,
          //   iconColour: 'green',
          //   title: 'Refresh',
          //   cb: () => { message.info ('reloading ... ', 4); },
          //   forUserRoles: ['staff', 'admin', 'parent']
          // }
        ]} />


        <ListDocumentsCategoriesComponent
          loadingCategories={this.state.sem.loadingCategories}
          actions={this.actions}
          forUserRole={this.props.currentUser.activeRole}
          categories={this.state.mut.fileCategoryList}
          focusedCategory={this.state.mut.focusedCategory}
        />



        {this.state.mut.focusedCategory != null &&

          <>
            <br />
            <br />

            <ListDocumentsComponent
              loadingDocuments={this.state.sem.loadingDocuments}
              documents={this.state.mut.documents}
              actions={this.actions}
              forUserRole={this.props.currentUser.activeRole}
              focusedCategory={this.state.mut.focusedCategory}
            />

          </>
        }


      </>
    );
  }
}

export default connect(mapStateToProps)(Documents);
