import React, { PropTypes } from 'react';
import { push } from 'react-router-redux';
import BSCCE from '../../apis/BSCCEApi';

import AdminLayout from "../../layouts/Admin.jsx";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { ScaleLoader } from 'react-spinners';
import { withCookies, Cookies } from 'react-cookie';
import { SET_USER } from '../../redux/actions/userActions';

import { SET_VIEW_AS_USER } from '../../redux/actions/userActions';

import { connect } from 'react-redux';

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSetUser: user => {
      console.log('dispatch on set user');
      dispatch(SET_USER(user));
    },
    onSetViewAsUser: (tokens, cb) => {

      dispatch(SET_VIEW_AS_USER(tokens));

    }

  }
}


class Authenticate extends React.Component {

  state = {
    isLoggedIn: false,
    isChecking: true
  }

  constructor(props) {
    super(props);


    const { cookies } = props;
    this.cookies = cookies;

    console.log('is authenticated props', props);
  }

  componentWillUpdate() {
    console.log('--------------- is auth : update -------------------');
    console.info(this.state)
    console.info(this.props)
  }

  componentWillReceiveProps() {
    console.log('--------------- is auth : willReceiveProps -------------------');
    console.info(this.state)
    console.info(this.props)
  }

  componentWillMount() {
    // const { dispatch, currentURL } = this.props;


    // if 

    if (this.props.cookies.get('boviewas')) this.props.onSetViewAsUser({
      boac: this.props.cookies.get('boac'),
      boat: this.props.cookies.get('boat')
    })



    // if ! view as mmode
    BSCCE.access.userInfo(this.cookies.get('boat'))
      .then(res => {
        console.log('res status', res.data.data);
        if (res.data.status == 200) {

          let role = res.data.data.roles.filter((role) => role._id === this.props.cookies.get('boro'));

          let user = {
            ...res.data.data,
            activeRole: role[0].name
          }

          // dispatch user details
          this.props.onSetUser(user);

          this.setState({ isLoggedIn: true, isChecking: false });
          console.log('logged in  set state ', { isLoggedIn: true, isChecking: false })



        } else {
          this.setState({ isLoggedIn: false, isChecking: false });
          console.log('not logged in set state', { isLoggedIn: false, isChecking: false })
          console.log(res.data.data);
        }
      })
      .catch((err) => {
        this.setState({ isLoggedIn: false, isChecking: false });
        console.log('err, set state', { isLoggedIn: false, isChecking: false });
        console.log('err, ', err);
      })



  }

  render() {

    return (
      <div>
        {this.state.isChecking &&
          <div style={{ height: '100%', width: '100%', position: 'absolute', zIndex: '-1', background: 'rgba(255,255,255,0.6)' }}>
            <div className='sweet-loading text-center'>
              <ScaleLoader
                css={{ margin: 'auto', top: '50%', right: '50%', position: 'absolute' }}
                sizeUnit={"px"}
                size={120}
                color={'#176599'}
              />
            </div>
          </div>}
        {(this.state.isLoggedIn && !this.state.isChecking) && <AdminLayout {...this.props} />}
        {(!this.state.isLoggedIn && !this.state.isChecking) && <Redirect to={{ pathname: '/auth/login', state: { promptAuth: true } }} />}
      </div>

    )
  }

}

export default
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withCookies(Authenticate))



