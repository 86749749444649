import React, { Component } from 'react';

import { Drawer, Form, Input, message, Upload, Select, DatePicker, Icon, Switch, Menu, Dropdown, notification, Tag } from 'antd';

import { ScaleLoader } from 'react-spinners';
import BSCCE from '../../apis/BSCCEApi';

import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardFooter,
    Col,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip
} from "reactstrap";


import PDFViewer from 'pdf-viewer-reactjs';

export class ListConstantssComponent extends Component {

    componentWillUpdate() {

    }

    state = {
        constants: [],
        pdf: '',
        forUserRole: ''
    };

    actions = {};

    constructor(props) {
        super(props);

        this.actions = this.props.actions;

    }

    onDownloadFile(id) {
        console.log('download ', id);
        BSCCE.files.getFile({ id: id })
            .then((resp) => {
                console.log('open ', resp.data.data.url);
                window.open(resp.data.data.url, '_blank');
            })
    }

    render() {
        return (
            <div>

                <Container className="mt--7" fluid>

                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                
                                {this.props.loading && <div className='sweet-loading text-center' style={{ padding: '20px'}}>
                                    <ScaleLoader
                                        // css={{}}
                                        sizeUnit={"px"}
                                        size={60}
                                        color={'#176599'}
                                    />
                                </div>}
                                {!this.props.loading &&
                                    <Table className="align-items-center table-flush" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Constants Name</th>
                                                <th scope="col">Dates</th>
                                                <th scope="col">Half Constants</th>
                                                <th scope="col" >Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {this.props.constants && this.props.constants.map((item, i) =>
                                                <>
                                                    {!this.props.forUserRole && <p>error </p>}

                                                    {
                                                        (this.props.forUserRole == 'staff' || this.props.forUserRole == 'admin') &&
                                                        // admin view
                                                        <>
                                                            < tr >
                                                                <th scope="row">

                                                                    <Media>

                                                                        <Tag color="blue" >
                                                                            <a>  {item.season} </a>
                                                                        </Tag>

                                                                        <span className="mb-0 text-sm">
                                                                            {item.name}
                                                                        </span>

                                                                    </Media>

                                                                </th>

                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        <div>
                                                                        <Tag color="grey" > {new Date(item.date_start).toLocaleDateString ()} </Tag>
                                                                       <a>{' '}to {' '} </a> 
                                                                        <Tag color="grey" > {new Date(item.date_end).toLocaleDateString ()} </Tag>
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        <div>
                                                                        <Tag color="grey" >   {new Date(item.date_halfterm_start).toLocaleDateString ()} </Tag>
                                                                       <a>{' '}to {' '} </a> 
                                                                        <Tag color="grey" >   {new Date(item.date_halfterm_end).toLocaleDateString ()} </Tag>
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        <div>
                                                                            {item.isCurrentConstants && <Icon type="smile" theme="twoTone" twoToneColor="#52c41a" />}
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                                <td className="text-right">


                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            className="btn-icon-only text-light"
                                                                            href="#pablo"
                                                                            role="button"
                                                                            size="sm"
                                                                            color=""
                                                                            onClick={() => {
                                                                                this.actions.edit.onShowEditConstantsDrawer(item);
                                                                            }}
                                                                        >

                                                                            <Icon type="tool" theme="twoTone" style={{ fontSize: '14pt' }} color="#1890ff" />

                                                                        </DropdownToggle>
                                                                    </UncontrolledDropdown>

                                                                </td>
                                                            </tr>
                                                        </>
                                                    }
                                                    {/* parents view */}
                                                    {
                                                        this.props.forUserRole && this.props.forUserRole == 'parent' &&
                                                        <>
                                                            <tr>
                                                                <th scope="row">

                                                                    <Media>
                                                                        <span className="mb-0 text-sm">
                                                                            <h4>{item.title}</h4>
                                                                        </span>

                                                                    </Media>
                                                                </th>

                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        <div>
                                                                            <p> {item.description}</p>

                                                                        </div>
                                                                    </div>
                                                                </td>


                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        <div>
                                                                            <Tag color="green" style={{ paddingTop: '10px' }} >
                                                                                <p>
                                                                                    <a target="_blank" onClick={() => { this.onDownloadFile(item._id) }}>
                                                                                        <Icon type="download" />
                                                                                        &emsp;
                                                                                    <span><b>Download</b></span>
                                                                                    </a></p>
                                                                            </Tag>
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        </>
                                                    }

                                                </>
                                            )

                                            }


                                        </tbody>
                                    </Table>
                                }
                                <CardFooter className="py-4">

                                    {/* <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className="disabled">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className="active">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          2 <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          3
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav> */}
                                </CardFooter>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default ListConstantssComponent
