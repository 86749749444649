import React, { Component } from 'react';
import { Drawer, Form, Input, message, Upload, DatePicker, Icon, Switch, Menu, Dropdown, notification, Button, Select, Divider } from 'antd';
import { ScaleLoader } from 'react-spinners';
import '../../../node_modules/antd/lib/drawer/style/css';
import '../../../node_modules/antd/lib/date-picker/style/css';
import '../../../node_modules/antd/lib/switch/style/css';
import '../../../node_modules/antd/lib/dropdown/style/css';
import '../../../node_modules/antd/lib/badge/style/css';
import '../../../node_modules/antd/lib/message/style/css';
import '../../../node_modules/antd/lib/notification/style/css';

import reqwest from 'reqwest';
import BSCCE from '../../apis/BSCCEApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

const { TextArea } = Input;

const { Option } = Select;

const props = {};

export class CreateNewslettersComponent extends Component {

    state = {
        newsletterToCreate: {
            category: 'newsletter'
        },
        fileList: [],
        items: ['jack', 'lucy'],
        name: ''

    };


    actions = {};

    constructor(props) {
        super(props);

        this.actions = this.props.actions;
    }

    onSelectCreateNewsletterIcon = (e) => {
        console.log(e);
    }


     addItem = () => {
        console.log('addItem');
        const { items, name } = this.state;

        this.setState({
            items: [...items, name || `New item`],
            name: '',
        });
    };

     onNameChange = event => {
        
        console.log('name set');
        this.setState({
            name: event.target.value,
        });
    };



    onChange = (e) => this.setState({
        newsletterToCreate: {
            ...this.state.newsletterToCreate,
            [e.target.name]: e.target.value
        }
    });


    handleUpload = () => {
        const { fileList } = this.state;
        const formData = new FormData();

        formData.append('description', this.state.newsletterToCreate.description);
        formData.append('title', this.state.newsletterToCreate.title);
        formData.append('category', this.state.newsletterToCreate.category);

        fileList.forEach(file => {
            formData.append('file', file);
        });

        this.setState({
            uploading: true,
        });

        reqwest({
            // url: 'http://127.0.0.1:3000/files/create',
            url: BSCCE.host + '/files/create',
            method: 'post',
            processData: false,
            data: formData,
            success: (resp) => {
                this.setState({
                    fileList: [],
                    uploading: false,
                });

                message.success('upload successfully.');

                this.actions.onCompleteCreateNewsletter(resp.newFile);
            },
            error: () => {
                this.setState({
                    uploading: false,
                });
                message.error('upload failed.');
            },
        });
    }

    render() {


        const { uploading, fileList } = this.state;
        const uploadProps = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };

        let index = 0;


        return (
            <div>

                <Drawer
                    title="Upload a new newsletter"
                    width={520}
                    closable={false}
                    anchor="right"
                    onClose={this.actions.onCloseCreateNewsletterDrawer}
                    // open={this.state.canCreateNewsletter && this.state.showCreateNewsletterDrawer}
                    // visible={this.state.canCreateNewsletter && this.state.showCreateNewsletterDrawer}
                    open={this.props.canCreateNewsletter && this.props.showCreateNewsletterDrawer}
                    visible={this.props.canCreateNewsletter && this.props.showCreateNewsletterDrawer}
                >

                    <p>
                        <b>New File</b>
                    </p>

                    <b>File Title <i>(shown)</i></b>
                    <Input name="title" onChange={(e) => { this.onChange(e) }} placeholder="September File ..." />

                    <br />
                    <br />
                    <b>File Description <i>(shown)</i></b>
                    <TextArea rows={4} placeholder="This newsletter ..." name="description" onChange={(e) => { this.onChange(e) }} />

                    <br />
                    <br />


                    <b>File Category <i>(shown)</i></b>
                   <Input value={this.state.newsletterToCreate.category} />

                    <br />
                    <br />

                    Select file to upload
                    <br />
                    <br />

                    <Upload {...uploadProps}>
                        <Button disabled={this.state.fileList.length > 0}>
                            <Icon type="upload" /> Select File
                        </Button>
                    </Upload>

                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                        }}
                    >
                        {!this.state.committed && <Button style={{
                            marginRight: 8,
                        }} disabled={this.state.committing} onClick={this.actions.onCloseCreateNewsletterDrawer} >Cancel</Button>}
                        <>


                            {!this.state.committed &&
                                <Button
                                    type="primary"
                                    onClick={this.handleUpload}
                                    disabled={(this.state.fileList.length === 0 || this.state.committing)}
                                    loading={this.state.committing}
                                    style={{ marginTop: 16 }}
                                >

                                    {!this.state.committed && !this.state.committing && 'Publish'}


                                </Button>
                            }


                            {/* <Button onClick={this.onCommitNewNotice} disabled={this.state.committing} type="primary">

                                {!this.state.committed && !this.state.committing && 'Publish'}

                                {this.state.committing && <>Publishing  <Icon type="loading" color="#52c41a" /> </>}

                            </Button> */}


                            {this.state.committed && <Button onClick={this.actions.onCloseCreateNewsletterDrawer} type="success"> Finish </Button>}

                        </>

                    </div>
                </Drawer>


            </div>
        )
    }
}

export default CreateNewslettersComponent
