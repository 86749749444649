/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip
} from "reactstrap";

import reqwest from 'reqwest';

// core components
import BlankHeader from "components/Headers/BlankHeader.jsx";


import { Drawer, Form, Input, message, Upload, Select, DatePicker, Icon, Switch, Menu, Dropdown, notification } from 'antd';

import { ScaleLoader } from 'react-spinners';
import BSCCE from '../../apis/BSCCEApi';

import '../../../node_modules/antd/lib/drawer/style/css';
import '../../../node_modules/antd/lib/date-picker/style/css';
import '../../../node_modules/antd/lib/switch/style/css';
import '../../../node_modules/antd/lib/dropdown/style/css';
import '../../../node_modules/antd/lib/badge/style/css';
import '../../../node_modules/antd/lib/message/style/css';
import '../../../node_modules/antd/lib/notification/style/css';

import moment from 'moment';
import { CreateNewslettersComponent } from "../Newsletters/CreateNewslettersComponent";
import { EditNewslettersComponent } from "../Newsletters/EditNewslettersComponent";
import { DeleteNewslettersComponent } from "../Newsletters/DeleteNewslettersComponent";
import { ListNewslettersComponent } from "../Newsletters/ListNewslettersComponent";

import { connect } from 'react-redux';
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";



const mapStateToProps = (state) => {
  return {
    currentUser: state.user,
    tokens: state.tokens
  }
}

const { RangePicker } = DatePicker;
const { TextArea } = Input;

class Newsletters extends React.Component {

  constructor(props) {
    super(props);

  }

  componentWillMount() {
    BSCCE.files.getFiles()
      .then(res => {
        // this.setState({ newsletters: res.data.data, loading: false });

        this.setState({
          mut: {
            ...this.state.mut,
            newsletters: res.data.data
          },
          sem: {
            ...this.state.sem,
            loading: false
          }
        })
        console.log(res.data.data);
      });
  }

  state = {
    toggles: {
      showCreateNewsletterDrawer: false,
      showDeleteNewsletterDrawer: false,
      showEditNewsletterDrawer: false
    },
    // semephores
    sem: {
      deleting: false,
      deleted: false,
      committing: false,
      committed: false,
      uploading: false,
      uploaded: false,
      loading: true
    },
    // permissions
    perms: {
      canCreateNewsletter: true,
      canDeleteNewsletter: true,
      canEditNewsletter: true
    },
    // mutable
    mut: {
      newsletterToCreate: {},
      focusedNewsletter: {},
      fileList: [],
      newsletters: []
    }
  }
  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description
    });
  };
  actions = {
    list: {
      onSelectNewsletterFromList: (item) => {
        this.setState({
          mut: {
            ...this.state.mut,
            focusedNewsletter: item
          }
        });
      }
    },
    create: {
      onShowUploadNewsletterDrawer: (e) => {

        this.setState({
          toggles: {
            ...this.state.toggles,
            showCreateNewsletterDrawer: true
          }
        })
      },
      onCloseCreateNewsletterDrawer: (e) => {
        this.setState({
          toggles: {
            ...this.state.toggles,
            showCreateNewsletterDrawer: false
          }
        });
      },
      onCompleteCreateNewsletter: (e) => {
        this.setState({
          mut: {
            ...this.state.mut,
            newsletters: [...this.state.mut.newsletters, e]
          }
        })
      }
    },
    edit: {
      onShowEditNewsletterDrawer: (item) => {
        console.log('edit', item);
        this.setState({
          toggles: {
            ...this.state.toggles,
            showEditNewsletterDrawer: true,
          },
          mut: {
            ...this.state.mut,
            focusedNewsletter: item
          }
        })

      },
      onCloseEditNewsletterDrawer: (e) => {

        this.setState({
          toggles: {
            ...this.state.toggles,
            showEditNewsletterDrawer: false
          },
          mut: {
            ...this.state.mut,
            focusedNewsletter: null
          }
        })

      },
    },

    delete: {
      onshowDeleteNewsletterDrawer: (e) => {
        console.log('delete', e);
        this.setState({
          toggles: {
            ...this.state.toggles,
            showDeleteNewsletterDrawer: true
          }
        })
      },
      onCloseDeleteconfirmationDrawer: (e) => {
        this.setState({
          toggles: {
            ...this.state.toggles,
            showDeleteNewsletterDrawer: false
          }
        })
      },
      onDeleteNewsletter: (e) => {
        console.log('delete now', this.state.mut.focusedNewsletter)
        this.setState({
          sem: {
            ...this.state.sem,
            deleting: true
          }
        });

        let file = {
          id: this.state.mut.focusedNewsletter._id,
          key: this.state.mut.focusedNewsletter.key,
        }

        BSCCE.files.deleteFiles(file)
          .then((res) => {

            if (res.data.status == 200) {
              this.setState({
                sem: {
                  deleting: false,
                  deleted: true
                },
                mut: {
                  ...this.state.mut,
                  newsletters: this.state.mut.newsletters.filter(newsletter => newsletter._id != this.state.mut.focusedNewsletter._id)
                }
              });

              this.actions.delete.onDeleteComplete();
            } else {
              // this.actions.delete.onDeleteFailed ()
              // error handler
              alert('an error occured');
              console.log(res);
            }

          })

          .catch((err) => {
            console.log('error', err);
          })




      },

      onDeleteComplete: (e) => {
        this.actions.edit.onCloseEditNewsletterDrawer();
        this.actions.delete.onCloseDeleteconfirmationDrawer();
        this.openNotificationWithIcon(
          'success',
          'Delete success',
          `has now been deleted.`
          // `${this.state.mut.focusedNewsletter.newsletter_name} has now been deleted.`
        );

        this.setState({
          sem: {
            ...this.state.sem,
            deleting: false,
            deleted: false,
          },
          mut: {
            ...this.state.mut,
            focusedNewsletter: null
          }
        });

      }
    }
  };


  render() {

    const { uploading, fileList } = this.state;
    const props = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };

    return (

      <>
        <CreateNewslettersComponent
          canCreateNewsletter={this.state.perms.canCreateNewsletter}
          showCreateNewsletterDrawer={this.state.toggles.showCreateNewsletterDrawer}
          newsletterToCreate={this.state.mut.newsletterToCreate}
          focusedNewsletter={this.state.mut.focusedNewsletter}
          actions={this.actions.create}
        />

        <DeleteNewslettersComponent
          canDeleteNewsletter={this.state.perms.canDeleteNewsletter}
          showDeleteNewsletterDrawer={this.state.toggles.showDeleteNewsletterDrawer}
          onCloseDeleteNewsletterDrawer={this.actions.delete.onCloseDeleteNewsletterDrawer}
          newsletterToEdit={this.state.mut.focusedNewsletter}
          focusedNewsletter={this.state.mut.focusedNewsletter}
          actions={this.actions}
        />


        <EditNewslettersComponent
          canEditNewsletter={this.state.perms.canEditNewsletter}
          showEditNewsletterDrawer={this.state.toggles.showEditNewsletterDrawer}
          onCloseEditNewsletterDrawer={this.actions.edit.onCloseEditNewsletterDrawer}
          newsletterToEdit={this.state.mut.focusedNewsletter}
          actions={this.actions}
        />


          <BlankHeader pageTitle={"Newsletters"} actions={[
            {
              icon: faPlusCircle,
              iconColour: 'green',
              title: 'Upload',
              cb: this.actions.create.onShowUploadNewsletterDrawer,
              forUserRoles: ['staff']
            }
          ]} />

          <ListNewslettersComponent
            loading={this.state.sem.loading}
            newsletters={this.state.mut.newsletters}
            actions={this.actions}
            forUserRole={this.props.currentUser.activeRole}
          />

        
      </>

    );
  }
}

export default connect(mapStateToProps)(Newsletters);
