/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { setState } from "react";
import PropTypes, { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import BSCCE from '../../apis/BSCCEApi';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

import { message } from 'antd';


import { ScaleLoader } from 'react-spinners';
import { IconButton } from '@material-ui/core';



import LockIcon from '@material-ui/icons/Lock';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

class Login extends React.Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  state = {
    submitting: false,
    canContinue: false,
    user: ''
  };


  constructor(props) {
    console.log('login props', props);
    super(props);


    console.log(this.state);
  }

  cookies = {};

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentWillMount() {

  }


  componentDidMount() {


  }



  onContinue = (e) => {
    console.log(this.props);

    this.props.history.push('/auth/login');
  }

  onPromptSubmit = (e) => {

    this.setState({ submitting: true });

    BSCCE.access.prompt({
      identifier: this.state.user,
      identifierType: 'email'
    })
      .then(
        res => {
          console.log('res', res.data);

          if (res.data.status != 200) {
            this.setState({ submitting: false });
            this.setState({ err: res.data.data.reason });
          } else if (res.data.status === 200) {
            this.setState({
              promptSent: true,
              submitting: false,
              canContinue: true
            });
          }

        }
      )
      .catch(err => {
        message.error('An error occured. Please try again', 4, () => { });
        console.error(err);

        this.setState({
          canContinue: false,
          submitting: false
        })
      })
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>
                </small>
                Regain access to your account

              </div>
              {/* <Form role="form"> */}
                {(this.state.submitting || this.state.loginPreCheck) &&
                  <div className='sweet-loading text-center'>
                    <ScaleLoader
                      // css={{}}
                      sizeUnit={"px"}
                      size={60}
                      color={'#176599'}
                    />
                  </div>
                }
                {!this.state.submitting && !this.state.promptSent &&
                  <>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Enter your email address" type="email" onChange={this.onChange} name="user" value={this.state.user} />
                      </InputGroup>
                    </FormGroup>

                  </>
                }

                {!this.state.submitting && this.state.promptSent &&
                  <>
                    <h4>We sent you a verification email</h4>
                    <p>If your email address has been successfully matched with an account in our records, you will shortly receive a verification email. Check your spam folder as well as your inbox for an email from us and follow the 'reset your password' link.  </p>

                    {/* <p><a href="#">Click here to find out how to identify a valid email from us</a></p> */}
                  </>
                }

                <div className="text-center">


                  <Button className="my-4"
                    color={(this.state.canContinue ? "success" : "primary")}
                    type="button"
                    onClick={(this.state.canContinue && this.state.promptSent ? this.onContinue : this.onPromptSubmit)}
                    disabled={this.state.submitting || this.state.loginPreCheck}>
                    {!this.state.submitting && !this.state.promptSent && 'continue'}
                    {(this.state.submitting) && 'please wait ...'}
                    {this.state.promptSent && !this.state.submitting && 'return to login'}
                  </Button> </div>

              {/* </Form> */}

            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => { e.preventDefault(); this.props.history.push('/'); }}
              >
                <small>Return to login?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              {/* <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small></small>
              </a> */}
            </Col>
          </Row>
        </Col>


        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          // open={this.state.promptAuth}
          autoHideDuration={1000}
          onClose={() => { this.setState.bind({ promptAuth: false }) }}
        >
          <MySnackbarContentWrapper
            onClose={() => { }}
            variant="warning"
            message="Please log in first"
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={(!!this.state.err)}
          autoHideDuration={3000}
          onClose={() => { }}
        >
          <MySnackbarContentWrapper
            onClose={() => { }}
            variant="danger"
            message={this.state.err}
          />
        </Snackbar>
      </>
    );
  }
}


// snackbars

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));


function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = WarningIcon;


  return (
    <SnackbarContent
      className='warning'
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};



export default withCookies(Login);
