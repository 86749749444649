import {
  SET_USER_INFO,
  UNSET_USER_INFO,
  SET_USER_ROLE,
  UNSET_VIEW_AS_USER_INFO,
  SET_VIEW_AS_USER_INFO
} from '../actions/userActions';

const initialState = {};

function userReducer(state = initialState, action) {
  console.log(action);
  // debugger;
  switch (action.type) {

    case SET_USER_INFO:
      console.log('set user reducer ', action);
      return action.user


    case SET_VIEW_AS_USER_INFO:
      console.log('set user reducer ', action);
      return {
        ...state,
        viewAsUser: {
          viewAsTokens: action.viewAsUserTokens,
          active: true
      }
    }

    case UNSET_USER_INFO:
      return {
        user: {}
      };

    
      case UNSET_VIEW_AS_USER_INFO:
      return {
        
        ...state,
        viewAsUser: {
          viewAsTokens: {},
          active: false
        },
      };

    case SET_USER_ROLE:

      console.log('set active user role ', action.role);
      return {
          user: {...state.user},
          viewAsUser: {...state.viewAsUser},
          activeRole: action.role
      }

      default:
        return state;
  };
}

export default userReducer;