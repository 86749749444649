import React from "react";
import { Redirect } from 'react-router-dom';

import {
  // Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col
} from "reactstrap"

import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight, faChevronCircleRight, faFolderPlus, faBookMedical, faBullhorn, faThumbtack, faComment, faBook } from '@fortawesome/free-solid-svg-icons'
import { withCookies, Cookies } from 'react-cookie';
import PropTypes, { instanceOf } from 'prop-types';


import { Button, Tooltip, Menu, Dropdown, message } from 'antd'
import { DownOutlined, UserOutlined } from '@ant-design/icons';

import Async from 'react-async'

import BSCCE from '../../apis/BSCCEApi'
import Header from "components/Headers/Header.jsx";

import '../../../node_modules/antd/lib/tooltip/style';
import BlankHeader from "../../components/Headers/BlankHeader";


import { NavLink as NavLinkRRD, Link } from "react-router-dom";


const noUnreadMessagesPNG = require('assets/img/undraw/undraw_chore_list_iof3.png')
const readOurNewsletterPNG = require('assets/img/undraw/undraw_reading_0re1.png');

const iconMap = {
  faArrowCircleRight: faArrowCircleRight,
  faChevronCircleRight: faChevronCircleRight,
  faFolderPlus: faFolderPlus,
  faBookMedical: faBookMedical,
  faBullhorn: faBullhorn,
  faThumbtack: faThumbtack,
  faComment: faComment,
  faBook: faBook
}



const mapStateToProps = (state) => {
  return {
    currentUser: state.user,
    tokens: state.tokens
  }
}



class ViewChildComponent extends React.Component {

  cookies = {};

  remoteData = {
    loadNewsletterData: async ({ playerId }, { signal }) => {
      const res = await fetch(`https://api.bscce.com/files/`, { signal })
      if (!res.ok) throw new Error(res.statusText)
      return res.json()
    }
  }
  constructor(props) {

    super(props);

    this.cookies = props.cookies;
  }

  componentDidMount() {

    if (!this.cookies.get('bpis')) {
      this.setState({
        welcomeDrawerVisible: true
      });


      this.cookies.set('bpis', 1, { path: '/' });

    }

  }

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  state = {
    redirect: false,
    redirectTo: null
  };

  componentWillMount() {

  }

  onDownloadNewsletter(id) {

    this.setState({
      ...this.state,
      downloadingNewsletter: true,
    })

    console.log('download ', id);
    BSCCE.files.getFile({ id: id })
      .then((resp) => {
        console.log('open ', resp.data.data.url);
        window.open(resp.data.data.url, '_blank');
        this.setState({
          ...this.state,
          downloadingNewsletter: false
        })
      })
  }

  styles = {
    quickLinks: {
      float: 'left',
      display: 'block',
      fontSize: '21pt',
      color: 'white',
      // padding: '5px 20px', 
      paddingBottom: '15px',
      paddingRight: '10px',
      paddingLeft: '10px',
      paddingTop: '5px',
      margin: '-20px auto',
      borderRight: 'thin solid black'
    }
  }



  quickLinks = [
    {
      name: 'new-link-book-entry',
      label: 'Link Books',
      icon: 'faBookMedical',
      href: '/admin/linkbooks'
    },
    // {
    //   name: 'new-direct-message-parent',
    //   label: 'Incidents',
    //   icon: 'faComment',
    //   href: ''
    // },
  ];


  render() {
    
    const { redirect, redirectTo } = this.state;

    if (redirect) {
      return <Redirect to={redirectTo} /> 
    }

    return (
      <>
 
        <Container className="mt--7" fluid >
          <Row >
            <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="bg-gradient-default shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <h4 className="text-uppercase text-light ">
                      &emsp; 
                      
                      Quick Links
                      </h4>

                    {/* <a href=''>
                      <h2 className="text-white mb-0">&emsp; learn more about the portal and its features <FontAwesomeIcon icon={faArrowCircleRight} /></h2>
                    </a> */}
                  </Row>

                </CardHeader>
                <CardBody style={{
                  borderTop: ' thin solid #222',
                  boxShadow: 'inset #000 0 0 14px'
                }}>
                  <ul style={{ color: 'white' }}>
                    {this.quickLinks && this.quickLinks.map(quickLink => <>

                      <li style={this.styles.quickLinks}>
                        
                          <a onClick={ () => { this.setState({ redirect: true, redirectTo: quickLink.href })}  }>

                            <Button>
                              <span>{quickLink.label} &nbsp; </span>
                              <FontAwesomeIcon icon={iconMap[quickLink.icon]} />
                              
                            </Button>
                          </a>

                      </li>
                    </>)}
                  </ul>
                </CardBody>
              </Card>
              <div style={{ height: '60px' }}>&nbsp;</div>
            

              <h6 className="text-uppercase text-muted ls-1 mb-1">
                Welcome to your new Children's Dashboard
              </h6>

              <div style={{ marginTop: '5px' }}> </div>

                <br />
              <p>
                As you use the Portal you will start to see content specific to your child's care show on this page. 
              </p>
              <br />
              <p>
                You will also find links to new features in the "Quick Links" bar above. These will make it easier to find the content you are looking for, once it is available.


              </p>

<p>We are getting this section ready for you. Please await further announcements and then come back to this page.</p>

             
            </Col >

            <Col xl="4">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Actions
                      </h6>
                      <h2 className="mb-0">You have no unread messages</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div className="chart">
                    <img src={noUnreadMessagesPNG}
                      style={{ marginTop: '80px' }} />

                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row >
          {/* 

          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Page visits</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        See all
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Page name</th>
                      <th scope="col">Visitors</th>
                      <th scope="col">Unique users</th>
                      <th scope="col">Bounce rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">/argon/</th>
                      <td>4,569</td>
                      <td>340</td>
                      <td>
                        <i className="fas fa-arrow-up text-success mr-3" />{" "}
                        46,53%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/index.html</th>
                      <td>3,985</td>
                      <td>319</td>
                      <td>
                        <i className="fas fa-arrow-down text-warning mr-3" />{" "}
                        46,53%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/charts.html</th>
                      <td>3,513</td>
                      <td>294</td>
                      <td>
                        <i className="fas fa-arrow-down text-warning mr-3" />{" "}
                        36,49%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/tables.html</th>
                      <td>2,050</td>
                      <td>147</td>
                      <td>
                        <i className="fas fa-arrow-up text-success mr-3" />{" "}
                        50,87%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/profile.html</th>
                      <td>1,795</td>
                      <td>190</td>
                      <td>
                        <i className="fas fa-arrow-down text-danger mr-3" />{" "}
                        46,53%
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Social traffic</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        See all
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Referral</th>
                      <th scope="col">Visitors</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Facebook</th>
                      <td>1,480</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">60%</span>
                          <div>
                            <Progress
                              max="100"
                              value="60"
                              barClassName="bg-gradient-danger"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Facebook</th>
                      <td>5,480</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">70%</span>
                          <div>
                            <Progress
                              max="100"
                              value="70"
                              barClassName="bg-gradient-success"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Google</th>
                      <td>4,807</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">80%</span>
                          <div>
                            <Progress max="100" value="80" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Instagram</th>
                      <td>3,678</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">75%</span>
                          <div>
                            <Progress
                              max="100"
                              value="75"
                              barClassName="bg-gradient-info"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">twitter</th>
                      <td>2,645</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">30%</span>
                          <div>
                            <Progress
                              max="100"
                              value="30"
                              barClassName="bg-gradient-warning"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
       
        */}
        </Container >
      </>
    );
  }
}

export default connect(mapStateToProps)(withCookies(ViewChildComponent));
