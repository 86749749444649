
const options = {
  enable: 1,
}

const emptyObj = {
  log: (args) => {
    console.log (args);
  },
  table: () => {},
  warn: () => {},
  error: () => {},
  info: () => {},
  clear: () => {},
  assert: () => {},
  debug: () => {},
  group: () => {},
  groupCollapsed: () => {},
  groupEnd: () => {},
  time: () => {},
  timeEnd: () => {},
  timeLog: () => {},
  trace: () => {},
}

const BSCCEDebug = (options.enable) ? window.console : emptyObj;

export default BSCCEDebug;