import React, { Component } from 'react';

import { Table, Drawer, Form, Input, message, Upload, Select, DatePicker, Icon, Switch, Menu, Dropdown, notification, Tag } from 'antd';

import { ScaleLoader } from 'react-spinners';
import BSCCE from '../../apis/BSCCEApi';

import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardFooter,
    Col,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Container,
    Row,
    UncontrolledTooltip
} from "reactstrap";

import PDFViewer from 'pdf-viewer-reactjs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'



const newsletterHeadSVG = require('assets/img/undraw/undraw_online_articles_79ff.svg');

export class ListNewslettersComponent extends Component {

    componentWillUpdate() {

    }

    state = {
        newsletters: [],
        pdf: '',
        forUserRole: ''
    };

    actions = {};

    constructor(props) {
        super(props);

        this.actions = this.props.actions;

    }

    onDownloadFile(id) {
        console.log('download ', id);
        message.loading('your download should begin shortly', 5)
        BSCCE.files.getFile({ id: id })
            .then((resp) => {
                console.log('open ', resp.data.data.url);
                window.open(resp.data.data.url, '_blank');
            })
    }

    columns = [
        {
            title: 'Title',
            key: 'title',
            dataIndex: 'title',
            fixed: 'left'
        },
        {
            title: 'Description',
            key: 'description',
            dataIndex: 'description'
        },
        {
            title: 'Download',
            key: 'download',
            dataIndex: '',
            fixed: 'right',
            render: record => (
                <>
                    <Button
                        onClick={() => { this.onDownloadFile(record._id) }}
                    // loading={this.state.downloadingNewsletter}
                    >
                        read now &emsp; <FontAwesomeIcon icon={faChevronCircleRight} />
                    </Button>
                </>
            )
        }
    ]

    render() {
        return (
            <div>

                <Container className="mt--7" fluid>

                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row>
                                        <div className="col">

                                            <Col>
                                                <img src={newsletterHeadSVG} style={{ width: '20%', height: 'auto', float: 'left', padding: '20px' }} />
                                            </Col>

                                            <Col>
                                                <div >
                                                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                                                        Our Newsletters
                                                </h6>
                                                    <p>
                                                        We post a monthly newsletter to keep our parents informed of what is happening in our local area and how this may affect them or their childcare services.
                                                        Our newsletters also include handly tips for parents and lots of other useful information.
                                            </p>
                                                </div>
                                            </Col>

                                        </div>

                                    </Row>

                                </CardHeader>

                                <Table columns={this.columns} loading={this.props.loading} dataSource={this.props.newsletters} scroll={{ x: 1 }} />

                                <CardFooter className="py-4">

                                </CardFooter>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default ListNewslettersComponent
