/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip
} from "reactstrap";


import { Drawer, Form, Input, message, Upload, Select, DatePicker, Icon, Switch, Menu, Dropdown, notification, Tag } from 'antd';
import moment from 'moment';
import Truncate from 'react-truncate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faAmbulance, faBriefcase, faBullhorn, faCalendarAlt, faCamera, faCheckCircle, faClock, faCloudSun, faCloudSunRain, faCloudShowersHeavy, faHeartbeat, faHourglassEnd, faCogs, faHourglassStart, faHourglass, faKey, faSnowboarding, faSnowflake, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';

import { connect } from 'react-redux';



// import Drawer from '@material-ui/core/Drawer';
import '../../../node_modules/antd/lib/drawer/style/css';
import '../../../node_modules/antd/lib/date-picker/style/css';
import '../../../node_modules/antd/lib/switch/style/css';
import '../../../node_modules/antd/lib/dropdown/style/css';
import '../../../node_modules/antd/lib/tag/style/css';
import '../../../node_modules/antd/lib/message/style/css';
import '../../../node_modules/antd/lib/notification/style/css';


import CreateContactsComponent from "../Contacts/CreateContactsComponent";
import EditContactsComponent from "../Contacts/EditContactsComponent";
import DeleteContactsComponent from "../Contacts/DeleteContactsComponent";
import ListContactsComponent from "../Contacts/ListContactsComponent";


// core components
import BlankHeader from "components/Headers/BlankHeader.jsx";

import { ScaleLoader } from 'react-spinners';
import BSCCE from '../../apis/BSCCEApi';


const { RangePicker } = DatePicker;
const { TextArea } = Input;


const mapStateToProps = (state) => {
  return {
    currentUser: state.user
  }
}


class Contacts extends React.Component {

  constructor(props) {
    super(props);

    console.log('contacts props ', props);
  }

  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description
    });
  };



  state = {
    toggles: {
      showCreateContactDrawer: false,
      showDeleteContactDrawer: false,
      showEditContactDrawer: false
    },
    // semephores
    sem: {
      deleting: false,
      deleted: false,
      committing: false,
      committed: false,
      uploading: false,
      uploaded: false,
      loading: true
    },
    // permissions
    perms: {
      canCreateContact: true,
      canDeleteContact: true,
      canEditContact: true
    },
    // mutable
    mut: {
      contactToCreate: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        isActive: '',
        isVerified: '',
        roles: []
      },
      focusedContact: {},
      fileList: [],
      contacts: []
    }
  }

  componentWillMount() {
    BSCCE.contacts.getContacts()
      .then(res => {

        if (res.status == 200) {
          this.setState({

            mut: {
              ...this.state.mut,
              contacts: res.data.data
            },
            sem: {
              ...this.state.sem,
              loading: false
            }

          });


        } else {

          message.error('Unable to load Contacts. Please try again.', 5);
          console.log('failed')

        }

      })
  }

  actions = {
    list: {
      onSelectContactFromList: (item) => {
        this.setState({
          mut: {
            ...this.state.mut,
            focusedContact: item
          }
        });
      }
    },
    create: {
      onShowCreateContactDrawer: (e) => {

        this.setState({
          toggles: {
            ...this.state.toggles,
            showCreateContactDrawer: true
          }
        })
      },
      onCloseCreateContactDrawer: (e) => {
        this.setState({
          toggles: {
            ...this.state.toggles,
            showCreateContactDrawer: false
          }
        });
      },
      onCompleteCreateContact: (e) => {
        this.setState({
          mut: {
            ...this.state.mut,
            contacts: [...this.state.mut.contacts, e]
          }
        })
      }
    },
    edit: {
      onShowEditContactDrawer: (item) => {
        console.log('edit', item);
        this.setState({
          toggles: {
            ...this.state.toggles,
            showEditContactDrawer: true,
          },
          mut: {
            ...this.state.mut,
            focusedContact: item
          }
        })

      },
      onCloseEditContactDrawer: (e) => {

        this.setState({
          toggles: {
            ...this.state.toggles,
            showEditContactDrawer: false
          },
          mut: {
            ...this.state.mut,
            focusedContact: null
          }
        })

      },
    },

    delete: {
      onshowDeleteContactDrawer: (e) => {
        console.log('delete', e);
        this.setState({
          toggles: {
            ...this.state.toggles,
            showDeleteContactDrawer: true
          }
        })
      },
      onCloseDeleteContactDrawer: (e) => {
        this.setState({
          toggles: {
            ...this.state.toggles,
            showDeleteContactDrawer: false
          }
        })
      },
      onDeleteContact: (e) => {
        console.log('delete now', this.state.mut.focusedContact._id)
        this.setState({
          sem: {
            ...this.state.sem,
            deleting: true
          }
        });

        let contact = {
          id: this.state.mut.focusedContact._id,
          key: this.state.mut.focusedContact.key,
        }

        BSCCE.contacts.deleteContact(this.state.mut.focusedContact._id)
          .then((res) => {

            if (res.data.status == 200) {
              this.setState({
                sem: {
                  deleting: false,
                  deleted: true
                },
                mut: {
                  ...this.state.mut,
                  contacts: this.state.mut.contacts.filter(contact => contact._id != this.state.mut.focusedContact._id)
                }
              });

              this.actions.delete.onDeleteComplete();
            } else {
              // this.actions.delete.onDeleteFailed ()
              // error handler
              alert('an error occured');
              console.log(res);
            }

          })

          .catch((err) => {
            console.log('error', err);
          })




      },

      onDeleteComplete: (e) => {
        this.actions.edit.onCloseEditContactDrawer();
        this.actions.delete.onCloseDeleteContactDrawer();
        this.openNotificationWithIcon(
          'success',
          'Delete success',
          // `has now been deleted.`
          `${this.state.mut.focusedContact.firstName} has now been deleted.`
        );

        this.setState({
          sem: {
            ...this.state.sem,
            deleting: false,
            deleted: false,
          },
          mut: {
            ...this.state.mut,
            focusedContact: null
          }
        });

      }
    }
  };



  render() {
    return (

      <>
        <CreateContactsComponent
          canCreateContact={this.state.perms.canCreateContact}
          showCreateContactDrawer={this.state.toggles.showCreateContactDrawer}
          contactToCreate={this.state.mut.contactToCreate}
          focusedContact={this.state.mut.focusedContact}
          actions={this.actions.create}
        />

        <DeleteContactsComponent
          canDeleteContact={this.state.perms.canDeleteContact}
          showDeleteContactDrawer={this.state.toggles.showDeleteContactDrawer}
          onCloseDeleteContactDrawer={this.actions.delete.onCloseDeleteContactDrawer}
          onDeleteComplete={this.onDeleteComplete}
          contactToEdit={this.state.mut.focusedContact}
          focusedContact={this.state.mut.focusedContact}
          actions={this.actions}
        />


        <EditContactsComponent
          canEditContact={this.state.perms.canEditContact}
          showEditContactDrawer={this.state.toggles.showEditContactDrawer}
          onCloseEdiContactDrawer={this.actions.edit.onCloseEdiContactDrawer}
          contactToEdit={this.state.mut.focusedContact}
          onFieldChange={this.onFieldChange}
          actions={this.actions}
        />

        <BlankHeader pageTitle={"People"} actions={[
          {
            icon: faPlusCircle,
            iconColour: 'green',
            title: 'Create',
            cb: this.actions.create.onShowCreateContactDrawer,
            forUserRoles: ['admin']
          }
        ]} />


        <ListContactsComponent
          loading={this.state.sem.loading}
          contacts={this.state.mut.contacts}
          actions={this.actions}
          forUserRole={this.props.currentUser.activeRole}
        />

      </>
    );
  }
}

export default connect(mapStateToProps)(Contacts);
