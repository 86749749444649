/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { setState } from "react";
import PropTypes, { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import BSCCE from '../../apis/BSCCEApi';
import RELEASE from '../../apis/BSCCERelease';
import DEBUGGER from '../../apis/BSCCEDebug';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

import { message } from 'antd';
import '../../../node_modules/antd/lib/message/style/css';



import { ScaleLoader } from 'react-spinners';
import { IconButton } from '@material-ui/core';


import LockIcon from '@material-ui/icons/Lock';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { SET_USER_ROLE } from '../../redux/actions/userActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { connect } from 'react-redux';
import { faCheckCircle, faEye } from "@fortawesome/free-solid-svg-icons";

const mapStateToProps = state => {
  return {
    viewAsUser: state.viewAsUser
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSetUserRole: role => {
      DEBUGGER.log('dispatch on set user role');
      dispatch(SET_USER_ROLE(role));
    }
  }
}

class Login extends React.Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  state = {
    loginPreCheck: false,
    submitting: false,
    canContinue: false,
    // promptAuth: this.props.location.state.promptAuth || false,
    user: '',
    password: '',
    passwordVisible: false,
    userData: { roles: [] },
    selectedUserRole: {},
    tokens: {
      auth: null,
      access: null,
    },
    focusedRole: ''
  };


  constructor(props) {
    DEBUGGER.log('login props', props);
    super(props);

    this.cookies = props.cookies;
    this.state = {
      ...this.state, tokens: {
        access: this.cookies.get('boac'),
        auth: this.cookies.get('boat'),
      }
    };
    DEBUGGER.log(this.state);
  }

  cookies = {};

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentWillMount() {

    DEBUGGER.log('will mount', this.state);
    this.setState({
      loginPreCheck: true
    })
  }


  componentDidMount() {

    if (!this.state.tokens.access) {
      DEBUGGER.log('no boac');
      // this.setState = ({ loginPreCheck: false }); 


      this.setState({
        loginPreCheck: false
      })
      // this.state = {...this.state, loginPreCheck: false }; 
      DEBUGGER.log('did mount no auth token');

    } else {

      // login view as
      if (this.props.viewAsUser && this.props.viewAsUser.active) {
        message.loading('Activating view as mode. Please wait ...');
        // assign boviewas = 1

        // update this.state.tokens
        this.setState({
          tokens: {
            access: this.props.viewAsUser.viewAsTokens.bovac,
            auth: this.props.viewAsUser.viewAsTokens.bovat,
          },
          isViewAs: true
        })

      }

      let token = this.state.isViewAs ? this.props.viewAsUser.viewAsTokens.bovac : this.state.tokens.access;
      console.log('getting info with token ', token)
      BSCCE.access.userInfo(token)
        .then(res => {
          if (res.data.status == 200) {
            this.setState({
              canContinue: true,
              submitting: false,
              loginPreCheck: false,
              userData: res.data.data
            });

            if (res.data.data.roles.length == 1) {
              DEBUGGER.log('auto select role');

              let item = res.data.data.roles[0];

              this.setState({ selectedUserRole: item });
              this.cookies.set('boro', item._id, { path: '/' });
              this.props.onSetUserRole(item.name);
              this.onContinue();
            }


          } else {

            this.setState({
              canContinue: false,
              submitting: false,
              loginPreCheck: false
            });


            DEBUGGER.log('res', res.data);
            if (res.data.reason.message) {
              DEBUGGER.log(res.data.reason.message)
              message.error('Your session has expired. Please log in again', 4, () => { });

            }

          }

          // set login cookie
        })
        .catch(
          err => {
            this.setState({
              canContinue: false,
              submitting: false,
              loginPreCheck: false,
              hasError: true,
              errMsg: err
            });

            message.error('Your session has expired. Please log in again', 4, () => { });

          });


    }

  }

  actions = {
    login: () => { },
    finaliseLogin: () => { },


  }

  onContinue = (e) => {
    DEBUGGER.log(this.props);

    this.props.history.push('/admin/index' + this.props.location.search);
  }

  onLoginSubmit = (e) => {

    e.preventDefault();
    this.setState({ submitting: true });

    BSCCE.login.initLogin({
      identifier: this.state.user,
      identifierType: 'email',
      password: this.state.password
    })
      .then(res => {
        DEBUGGER.log('res', res.data);

        if (res.data.status != 200) {
          this.setState({ submitting: false });
          this.setState({ err: res.data.data.reason });
          DEBUGGER.log('initlogin response not 200')

          // show password requirements

          // show password typed


        } else if (res.data.status === 200) {

          BSCCE.login.finaliseLogin(res.data.data.token)
            .then(
              res => {
                DEBUGGER.log('res', res.data);
                // set error
                // res.data.data.reason     

                DEBUGGER.log(res.data.data);

                this.cookies.set('boat', res.data.data.accessToken, { path: '/' });
                this.cookies.set('boac', res.data.data.authToken, { path: '/' });

                this.setState({ submitting: false, canContinue: true, accessToken: res.data.data.accessToken });

                DEBUGGER.log('log in complete');
                DEBUGGER.log(this.state);

                BSCCE.access.userInfo(this.state.accessToken)
                  .then(res => {
                    this.setState({ userData: res.data.data });
                    this.setState({ userData: { ...res.data.data, roles: res.data.data.roles } })
                    DEBUGGER.log(this.state);

                    if (!!res.data.data.roles && res.data.data.roles.length == 1) {

                      let item = res.data.data.roles[0];

                      this.setState({ selectedUserRole: item });
                      this.cookies.set('boro', item._id, { path: '/' });
                      this.props.onSetUserRole(item.name);
                      this.onContinue();
                    }

                  });

              },
              err => {
                DEBUGGER.log('finalise login fail', err);
              }
            );

        }

      })
      .catch(err => {

        this.setState({
          submitting: false,
          canContinue: false,
          hasError: true
        });
        DEBUGGER.log('an error has occurred');

        message.error('An error occured. Please try again', 4, () => { });


      })
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            {/* <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>Sign in with</small>
              </div>
              <div className="btn-wrapper text-center">
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/github.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Github</span>
                </Button>
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/google.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
              </div>
            </CardHeader> */}
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <b>

                  {this.state.canContinue && <> <b>Welcome {this.state.userData.firstName}</b>  <br /></>}

                  {this.state.isViewAs && this.state.isViewAs && "View as mode enabled"}

                  {!this.state.canContinue && !this.state.loginPreCheck && "Login to your Portal Account"}

                  {(this.state.canContinue && this.state.userData.roles.length >= 1) && 'Select your role'}

                </b>
              </div>
              <Form role="form">
                {(this.state.submitting || this.state.loginPreCheck) &&
                  <div className='sweet-loading text-center'>
                    <ScaleLoader
                      // css={{}}
                      sizeUnit={"px"}
                      size={60}
                      color={'#176599'}
                    />
                  </div>
                }
                {(!this.state.submitting && !this.state.canContinue) && !this.state.loginPreCheck &&
                  <>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input style={{ paddingLeft: "10px" }} placeholder="Email" type="email" onChange={this.onChange} name="user" value={this.state.user} />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input style={{ paddingLeft: "10px" }} placeholder="Password" type={this.state.passwordVisible ? 'text' : 'password'} onChange={this.onChange} name='password' >

                        </Input>

                      </InputGroup>
                      <p style={{ float: 'right', fontSize: '10pt', padding: '5px', cursor: 'pointer' }} onClick={() => { this.setState({ ...this.state, passwordVisible: !this.state.passwordVisible }) }}> {this.state.passwordVisible ? 'hide password' : 'show password'}
                        {/* <FontAwesomeIcon icon={faEye} style={{ float: 'right' }} /> */}
                      </p>
                    </FormGroup>

                    {/* <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div> */}
                  </>
                }
                <div className="text-center">

                  {
                    this.state.canContinue && !this.state.userData && <>
                      please wait ...
                  </>}

                  {
                    this.state.userData.roles.map((item, i) =>

                      <Button
                        className="btn-icon-clipboard"
                        data-clipboard-text="circle-08"
                        id="tooltip63796078"
                        // type={(function () {
                        //   if (!!this.selectedUserRole) {
                        //     if (this.selectedUserRole.name == item.name) {
                        //       return 'Primary';
                        //     }
                        //   }
                        // }).bind(this)()}
                        type="primary"

                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ selectedUserRole: item });
                          this.cookies.set('boro', item._id, { path: '/' });
                          this.props.onSetUserRole(item.name);
                        }}
                      >
                        <div>
                          <i className=" ni ni-circle-08" />
                          <span>{item.name}</span>

                          { this.state.selectedUserRole && this.state.selectedUserRole.name == item.name && <FontAwesomeIcon color='#000' style={{float: 'right', color: 'blue'}} icon={faCheckCircle} /> }

                        </div>
                      </Button>
                    )
                  }
                  <br />
                  <br />

                  <Button
                    color={(this.state.canContinue ? "success" : "primary")}
                    type="primary"
                    onClick={(this.state.canContinue && this.state.selectedUserRole ? this.onContinue : this.onLoginSubmit)}
                    disabled={this.state.submitting || this.state.loginPreCheck}>
                    {(!this.state.submitting && !this.state.canContinue && !this.state.loginPreCheck) && 'Sign in'}
                    {(this.state.submitting || this.state.loginPreCheck || (this.state.canContinue && !this.state.selectedUserRole)) && 'please wait ...'}
                    {this.state.canContinue && this.state.selectedUserRole && 'continue'}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href=""
                onClick={e => { e.preventDefault(); this.props.history.push('forgot'); }}
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>
                  Version: {RELEASE.version}
                </small>
              </a>
            </Col>
          </Row>
        </Col>


        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          // open={this.state.promptAuth}
          autoHideDuration={1000}
          onClose={() => { this.setState.bind({ promptAuth: false }) }}
        >
          <MySnackbarContentWrapper
            onClose={() => { }}
            variant="warning"
            message="Please log in first"
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={(!!this.state.err)}
          autoHideDuration={3000}
          onClose={() => { }}
        >
          <MySnackbarContentWrapper
            onClose={() => { }}
            variant="danger"
            message={this.state.err}
          />
        </Snackbar>
      </>
    );
  }
}


// snackbars

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));


function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = WarningIcon;


  return (
    <SnackbarContent
      className='warning'
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};


export default
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withCookies(Login))
