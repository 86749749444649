

const BSCCERelease = {

  target: 'prod',

  version: '1.7.1',
  notes: '',
  changes: [
    {
      feature: 'User Roles ',
      notes: 'Introduced better separation of tasks for each user role. Contact and User invitations are now restricted to admin users only.'
    },
    {
      feature: 'Documents ',
      notes: 'Now allows for two filters to be used together to specify target users.'
    },
    {
      feature: 'People ',
      notes: 'Now allows for tags to be assigned to contacts.'
    }
  ],
  previous: [
    {
      'version': '1.4.2',
      notes: '',
      changes: [
        
      ]
    },
    {
      'version': '1.4.1',
      notes: '',
      changes: [
        
      ]
    }
  ]

}


export default BSCCERelease;