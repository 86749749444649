/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import AuthNavbar from "../components/Navbars/AuthNavbar";
import AuthFooter from "../components/Footers/AuthFooter";

import routes from "../routes";

import { useCookies } from 'react-cookie';

class Auth extends React.Component {


  state = {
    isAuthenticated: false,

  }

  constructor (props) {
    super(props);

    console.log ('auth layout props', props);
    
  }

  componentWillMount() {
    console.log ('will check for user');
  }
  
  componentDidMount() {
    document.body.classList.add("bg-default");


  }
  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <>
        <div className="main-content">

          {/* <AuthNavbar /> */}

          <div className="header bg-gradient-info py-7 py-lg-8">
            <Container>
              <div className="header-body text-center mb-5">
                <Row className="justify-content-center">
                  <Col lg="5" md="6">
                    <h1 className="text-white" style={{fontSize: '21pt'}}>
                      BrightStart ChildCare &amp; Education
                      </h1>
                      <br />
                    {/* <p className="text-lead " style={{color: '#fff'}}>
                     Our Parents' Portal provides access to exclusive content for children in our care.
                    </p> */}
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <div style={{background: 'white', borderRadius: '15px', boxShadow: '0 0 3px grey'}}>
                    {/* <span style={{width: '140px', padding: '10px'}}> */}

                        <img src='https://bscce.com/images/ofsted_outstanding_official.png' 
                      style={{height: '80px', width:'auto', float: 'right'}} />
                      <p style={{width: '290px', margin: '0', padding: '10px 25px 10px 10px'}}>
                        We are rated <b>Outsanding</b> 
                        <br /> by Ofsted
                        
                        </p>

                      {/* </span> */}
                      
                  </div>

                    </Row>
                  </div>
              
          </Container>
            {/* <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-default"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div> */}
          </div>
          {/* Page content */}
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Switch>{this.getRoutes(routes)}</Switch>
            </Row>
          </Container>
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default Auth;
