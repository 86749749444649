/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link, Redirect } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";

import { Drawer, Skeleton, Popconfirm, List, message } from 'antd'
import "../../../node_modules/antd/lib/popconfirm/style/css"
import "../../../node_modules/antd/lib/skeleton/style/css"
import "../../../node_modules/antd/lib/list/style/css"


import { withCookies, Cookies } from 'react-cookie';
import PropTypes, { instanceOf } from 'prop-types';

import BSCCE from '../../apis/BSCCEApi';

import { connect } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faUser } from "@fortawesome/free-solid-svg-icons";
import { SET_VIEW_AS_USER } from '../../redux/actions/userActions';

const avatarImagePNG = require("assets/img/theme/team-4-800x800.jpg")


const mapStateToProps = (state) => {
  return {
    currentUser: state.user,
    tokens: state.tokens,
    viewAsUser: state.viewAsUser
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSetViewAsUser: (tokens, cb) => {

      dispatch(SET_VIEW_AS_USER(tokens));

      cb(tokens);

    }
  }
}

class AdminNavbar extends React.Component {

  state = {
    viewAsDrawerVisible: false,
    loadingViewAsContacts: false,
    viewAsContactsList: []
  };

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    console.log('Admin Props', props);

    // console.log('admin navbar props ', props);
  }

  onOpenViewAsDrawer = () => {

    this.setState({
      loadingViewAsContacts: true,
      viewAsDrawerVisible: true
    });

    // set !loading
    BSCCE.contacts.getContacts()
      .then(contacts => this.setState({ viewAsContactsList: contacts.data.data, loadingViewAsContacts: false }))

  }


  onCloseViewAsDrawer = () => this.setState({
    viewAsDrawerVisible: false
  });

  onEndViewAsMode = () => {

    // reassign cookies 

    this.props.cookies.set('boac', this.props.cookies.get('bostac'), { path: '/' })
    this.props.cookies.set('boat', this.props.cookies.get('bostat'), { path: '/' })

    this.props.cookies.remove('bovat', { path: '/' });
    this.props.cookies.remove('bovac', { path: '/' });
    this.props.cookies.remove('boviewas', { path: '/' });
    this.props.cookies.remove('bostac', { path: '/' });
    this.props.cookies.remove('bostat', { path: '/' });

    message.loading('leaving viewAs mode');

    setTimeout(() => {
      window.location.reload();
    }, 500)

  }

  render() {

    const logout = () => {

      let token = '';
      BSCCE.access.logout(token)
        .then(res => {

          this.props.cookies.remove('boac', { path: '/' });
          this.props.cookies.remove('boat', { path: '/' });

          window.location.reload();

        });

    }

    const onViewAsContinue = (contact) => {
      message.info('switching context to ' + contact.firstName + ' ' + contact.lastName);

      // BSCCE.access.info ( 
      BSCCE.access.viewAs({
        ac: this.props.cookies.get('boac'),
        at: this.props.cookies.get('boat'),
        ci: contact._id
      })
        .then(resp => {

          console.log(resp.data.data)

          // call success
          if (resp.data.status == 200) {
            // identify success
            if (resp.data.data.status == 200) {

              let cookieTokens = {
                bostac: this.props.cookies.get('boac', { path: '/' }),
                bostat: this.props.cookies.get('boat', { path: '/' }),
                bovat: resp.data.data.tokens.bovat,
                bovac: resp.data.data.tokens.bovac,
                boviewas: 1
              }

              console.log('cookie tokens');
              console.log(cookieTokens);


              this.props.cookies.remove('boat', { path: '/' });
              this.props.cookies.remove('boac', { path: '/' });
              this.props.cookies.remove('bostac', { path: '/' });
              this.props.cookies.remove('bostat', { path: '/' });
              this.props.cookies.remove('bovac', { path: '/' });
              this.props.cookies.remove('bovat', { path: '/' });

              this.props.cookies.set('boat', cookieTokens.bovat, { path: '/' });
              this.props.cookies.set('boac', cookieTokens.bovac, { path: '/' });

              this.props.cookies.set('bostac', cookieTokens.bostac, { path: '/' });
              this.props.cookies.set('bostat', cookieTokens.bostat, { path: '/' });

              this.props.cookies.set('bovac', cookieTokens.bovac, { path: '/' });
              this.props.cookies.set('bovat', cookieTokens.bovat, { path: '/' });


              this.props.cookies.set('boviewas', 1, { path: '/' });


              this.props.onSetViewAsUser(resp.data.data, (tokens) => {
                this.setState({
                  redirectViewAsMode: true,
                  viewAsTokens: tokens
                })
              })
            } else {

              // USER NOT FOUND 
              message.error('this contact does not have a portal account. View as mode not available.')
            }

          } else {
            // request error
            message.error('your request could not be completed. View as mode not available at this time.')
          }




        })

      // prompt for password
      // send password to login
      // autofinalise with staff
      // submit
      // submit access token to /validate 
      // if role == staff
      // if permissions.viewAs
      // get current access token 
      // get current auth token
      // submit to viewAs
      // return viewAs token
      // stash current access and auth tokens
      // replace with new viewAs token
      // reload application 



    }

    const onViewAsCancel = () => {
      return;
    }


    return (
      <>

        {this.state.redirectViewAsMode &&
          <Redirect to={{ pathname: '/auth/login' }} state={this.state.viewAsTokens} />
        }


        <Drawer
          title="View as ..."
          placement="top"
          closable={true}
          onClose={this.onCloseViewAsDrawer}
          visible={this.state.viewAsDrawerVisible}
          key="top"
          height={"100%"}
        >

          {
            this.state.loadingViewAsContacts && <>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </>
          }

          {
            !this.state.loadingViewAsContacts && <>
              {this.state.viewAsContactsList.length > 0 &&

                <List
                  size="small"
                  header={<div><h2>Select a contact to navigate the portal as them</h2></div>}
                  // footer={<div>Footer</div>}
                  bordered
                  dataSource={this.state.viewAsContactsList}
                  renderItem={contact =>
                    <List.Item>
                      <Popconfirm
                        title={"Continue using portal as " + contact.firstName + " " + contact.lastName + "?"}
                        onConfirm={() => { onViewAsContinue(contact) }}
                        onCancel={onViewAsCancel}
                        okText="Yes"
                        cancelText="No"
                        placement="topLeft"
                      >
                        <p>
                          <FontAwesomeIcon icon={faUser} /> {" "}
                          {contact.firstName} {" "} {contact.lastName} {" "} {contact.email} </p>
                      </Popconfirm>

                    </List.Item>}
                />

              }


            </>
          }
        </Drawer>

        {/* end view as drawer */}


        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main" style={{ borderBottom: 'black' }}>
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >

              {/* {this.props.currentUser.activeRole} */}

            </Link>
            {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Search" type="text" />
                </InputGroup>
              </FormGroup>
            </Form> */}
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  {/* <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={avatarImagePNG}
                      />
                    </span> */}
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">

                      {this.props.currentUser && <>


                        {!this.props.viewAsUser || !this.props.viewAsUser.active && <>
                          {this.props.currentUser.firstName} {' '} {this.props.currentUser.lastName} {this.props.currentUser.activeRole && <> logged in as {this.props.currentUser.activeRole} </>}
                        </>}

                        {this.props.viewAsUser && this.props.viewAsUser.active &&

                            <> 
                              viewing as "
                                <span style={{ color: "lightgreen" }}>
                                  {this.props.currentUser.firstName} {' '} {this.props.currentUser.lastName}   {this.props.currentUser.activeRole} 
                                </span>
                              "
                            </>
                        }

                    </>}

                      &nbsp; <FontAwesomeIcon icon={faCaretDown} />
                    </span>
                  </Media>
                {/* </Media> */}
                </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>

                <DropdownItem divider />

                {this.props.currentUser.roles.length > 1 && <DropdownItem to="/auth/login" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Switch Roles</span>
                </DropdownItem>
                }

                {this.props.currentUser.activeRole == 'admin' && <DropdownItem onClick={() => { this.onOpenViewAsDrawer() }} >
                  <i className="ni ni-support-16" />
                  <span>View as ...</span>
                </DropdownItem>
                }


                {this.props.viewAsUser.active && <DropdownItem onClick={() => { this.onEndViewAsMode() }} >
                  <i className="ni ni-support-16" />
                  <span style={{ color: 'darkred' }}><b>End View as ...</b></span>
                </DropdownItem>
                }



                <DropdownItem divider />

                <DropdownItem href="#pablo" onClick={logout}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
      </Navbar>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(AdminNavbar));

