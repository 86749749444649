

import axios from 'axios';

import { useCookies } from 'react-cookie';
import { CardActions } from '@material-ui/core';

import BSCCERelease from './BSCCERelease';

const baseURL = BSCCERelease.target == 'prod' ? 'https://api.bscce.com' : "http://localhost:3000";

const phoneRegex = /test/;
const emailRegex = /email/;
const usernameRegex = /username/;

const addSiteRequestData = (obj) => {
  obj.siteid = 'sample';
  obj.signature = 'sample';

  // accesstoken
  obj.requester = 'sample';

  return obj;
}

const BSCCE = {
  host: baseURL,
  login: {
    initLogin: (user) => {
      user = addSiteRequestData(user);

      // if (emailRegex.test(user.identifier)) { user.identifierType = 'email'; }
      // else if (phoneRegex.test(user.identifier)) { user.identifierType = 'phone'; }
      // else { user.identifierType = 'username'; }

      return axios.post(baseURL + '/auth/login', user);
    },
    finaliseLogin: (authToken) => {

      // debugger;      
      authToken = addSiteRequestData({
        authToken
      });
      return axios.post(baseURL + '/auth/finalise', authToken);
    },
  },
  access: {
    userInfo: (accessToken) => {

      accessToken = addSiteRequestData({
        accessToken
      });
      return axios.post(baseURL + '/access/info', accessToken);
    },
    prompt: (identifier) => {

      identifier = addSiteRequestData({
        ...identifier
      });
      return axios.post(baseURL + '/access/prompt', { ...identifier });
    },
    validateResetToken: promptToken => axios.post(baseURL + '/access/validatePrompt', { promptToken }),
    resetPassword: resetToken => axios.post(baseURL + '/access/reset', { ...resetToken }),
    logout: () => { return axios.post(baseURL + '/auth/logout') },
    viewAs: (shadowObj) => axios.post (baseURL + '/access/viewAs', shadowObj)
  },
  files: {
    getFiles: () => {
      return axios.get(baseURL + '/files/');
    },
    getFileCategories: (contactId) => {
      let url = baseURL + '/files/categories';
      if (contactId) url += '/?id=' + contactId;

      return axios.get(url)
    },
    getFile: (id) => {
      return axios.get(baseURL + '/files/' + id.id)
    },

    queryFiles: (filter, contactId) => {
      return axios.post(baseURL + '/files/filter', { filter, contactId })
    },
    putFiles: () => { },
    deleteFiles: fileDetails => axios.post(baseURL + `/files/delete`, fileDetails)
  },

  notices: {
    getNotices: () => {
      return axios.get(baseURL + '/notices/');
    },
    createNotice: (notice) => {
      return axios.post(baseURL + '/notices/create', notice);
    },
    putNotices: (notice) => {
      if (!notice.id) {
        return axios.post(baseURL + '/notices', {});
      } else {
        return axios.put(baseURL + '/notices', {});
      }
    },
    deleteNotices: (notice) => {
      return axios.delete(baseURL + '/notices', {});
    }
  },

  terms: {
    getTerms: () => {
      return axios.get(baseURL + '/terms/');

    },
    putTerm: (term) => {
      if (term.id) {
        return axios.put(baseURL + '/terms/update', term);
      } else {
        return axios.post(baseURL + '/terms/create', term);
      }
    },
    deleteTerms: (id) => {
      return axios.post(baseURL + '/terms/delete/', {id});

    }
  },
  children: {
    createChild: (child) => {
      return axios.post(baseURL + '/children/create', child);
    },
    getChild: (id) => {
      return axios.get(baseURL + `/children/${id}`);
    },
    getChildren: () => {
      return axios.get(baseURL + '/children/');
    },
    putChild: (child) => {
      if (child.id || child._id) {
        return axios.put(baseURL + '/children/update', child);
      } else {
        return axios.post(baseURL + '/children/create', child);
      }
    },
    deleteChild: (id) => {
      return axios.post(baseURL + '/children/delete/', {id});
    },
    query: term => {
      return axios.post(baseURL + '/children/query/', { term })
    },
    unenroll: (id) => {
      return axios.post (baseURL + 'children/unenroll')
    }
  },
  contacts: {
    createContact: (contact) => {
      return axios.post(baseURL + '/contacts/create', contact);
    },
    getContact: (id) => {
      return axios.get(baseURL + `/contacts/${id}`);
    },
    getContacts: () => {
      return axios.get(baseURL + '/contacts/');
    },
    putContact: (contact) => {
      if (contact.id || contact._id) {
        return axios.put(baseURL + '/contacts/update', contact);
      } else {
        return axios.post(baseURL + '/contacts/create', contact);
      }
    },

    filterContacts: (filters) => {
      return axios.post(baseURL + '/contacts/filter', {filters})
    },
    deleteContact: (id) => {
      return axios.post(baseURL + '/contacts/delete/', { id });
    },
    getContactRelationships: id => axios.post(baseURL + '/contacts/relationships', {id}),
    inviteContactToPortal: inviteObj => axios.post(baseURL + '/contacts/inviteToPortal', inviteObj)
  },
  constants: {
    getConstants: () => {
      return axios.get(baseURL + '/constants/');
    },
    putConstants: (constant) => {
      if (constant.id || constant._id) {
        return axios.post(baseURL + '/constants/update', constant);
      } else {
        return axios.post(baseURL + '/constants/create', constant);
      }
    },
    deleteConstants: (id) => {
      return axios.post(baseURL + '/constants/delete', {id: id});

    },
  },
  users: {
    getUsers: () => {
      return axios.get(baseURL + '/users/');
    },
    createUser: (user) => {
      return axios.post(baseURL + '/users/create', user);
    },
    deleteUser: (id) => {
      return axios.post(baseURL + '/users/delete/', {id});

    },
    updateUser: (user) => {
      return axios.post(baseURL + '/users/update', user);
    },   
    query: term => {
      return axios.post(baseURL + '/users/query/', { term })
    }
  },
  vacancies: {
    getVacancies: () => {
      return axios.get(baseURL + '/vacancies/');
    },
    createVacancy: (vacancy) => {
      return axios.post(baseURL + '/vacancies/create', vacancy);
    },
    deleteVacancy: (id) => {
      return axios.delete(baseURL + '/vacancies/delete/' + id);

    },
    updateVacancy: (vacancy) => {
      return axios.post(baseURL + '/vacancies/update', vacancy);
    }
  }
}


export default BSCCE;