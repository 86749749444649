import {
  SET_CHILD_CONTEXT,
  UNSET_CHILD_CONTEXT,
} from '../actions/contextActions';

const initialState = {};

function userReducer(state = initialState, action) {
  console.log(action);
  // debugger;
  switch (action.type) {
    case SET_CHILD_CONTEXT:
      console.log('set child context reducer ', action);
      return action.contextChild

    case UNSET_CHILD_CONTEXT:
      return {
        contextChild: null
      };


      default:
        return state;
  };
}

export default userReducer;