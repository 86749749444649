
import React, { Component } from 'react';
import { Drawer, Form, Input, message, Upload, Select, DatePicker, Icon, Switch, Menu, Dropdown, notification, Button } from 'antd';
import { ScaleLoader } from 'react-spinners';
import '../../../node_modules/antd/lib/drawer/style/css';
import '../../../node_modules/antd/lib/date-picker/style/css';
import '../../../node_modules/antd/lib/switch/style/css';
import '../../../node_modules/antd/lib/dropdown/style/css';
import '../../../node_modules/antd/lib/badge/style/css';
import '../../../node_modules/antd/lib/message/style/css';
import '../../../node_modules/antd/lib/notification/style/css';

import reqwest from 'reqwest';
import BSCCE from '../../apis/BSCCEApi';
import { textChangeRangeIsUnchanged } from 'typescript';
import { faPlusCircle, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { DownOutlined, UserOutlined } from '@ant-design/icons';



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const { TextArea } = Input;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

const props = {};
const base64 = require('base-64');

export class CreateWebsiteCopyComponent extends Component {

    state = {
        constantToCreate: {
              name: '',
              ref: '',
              type: '',
              defaultValue: '',
              hasVariant: null,
              variantValue: '',
              variantCondition: ''
        },
    };


    actions = {};

    constructor(props) {
        super(props);

        this.actions = this.props.actions;
    }

    onChange = (e) => this.setState({
        constantToCreate: {
            ...this.state.constantToCreate,
            [e.target.name]: e.target.value
        }
    });


   
    onHalfConstantsDatesChange = (dates, dateStrings) => {
        console.log('From: ', dates[0], ', to: ', dates[1]);
        console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);

        this.setState({
            constantToCreate: {
                ...this.state.constantToCreate,
                date_halfconstant_start: dates[0],
                date_halfconstant_end: dates[1]
            }
        })
    }


    commitConstants = () => {

        console.log('submit ', this.state.constantToCreate);

        message.success('uploading ');

        this.setState ({
            ...this.state,
            committing: true
        })


        if (!! this.state.constantToCreate.variantCondition) {
            this.setState({
                constantToCreate: {
                    ...this.state.constantToCreate,
                    variantCondition: base64.encode(this.state.constantToCreate.variantCondition)
                }
            })
        }

        console.log('submit ', this.state.constantToCreate);

        BSCCE.constants.putConstants(this.state.constantToCreate)
            .then(resp => {
                if (resp.data.status == 200) {

                    this.setState({
                        committed: true,
                        committing: false
                    });

                    this.props.actions.create.onCompleteCreateConstants(resp.data.data);
                } else {
                    message.error ('an error occured ');
                    this.setState({
                        ...this.state,
                        committing: false
                    })
                }
            })
            .catch(err => { 
                message.error ('an error occured ')
                console.log (err);
                this.setState({
                    ...this.state,
                    committing: false
                })
            })
    }

    render() {
        const handleButtonClick = (e) => {
            message.info('Click on left button.');
            console.log('click left button', e);

        
          }
          
          const handleMenuClick = (e) => {
            message.info('Click on menu item.');
            console.log('click', e);

            this.setState({
                constantToCreate: {
                    ...this.state.constantToCreate,
                    type: e.key
                }
            })
          }

          const menu = (
            <Menu onClick={handleMenuClick}>
              <Menu.Item key="file">
                <Icon type="user" />
                File
              </Menu.Item>
              <Menu.Item key="text">
                <Icon type="user" />
                Text
              </Menu.Item>
              <Menu.Item key="url">
                <Icon type="user" />
                URL
              </Menu.Item>
            </Menu>
          );

          const onAssignNewCategoryClicked = () => {

            this.setState({
                isNewCategory: true
            });

        }


          const selectCategoryDropDown = (<Menu>

            <Menu.Item style={{ borderBottom: 'thin solid lightgrey' }} onClick={onAssignNewCategoryClicked}>

                <FontAwesomeIcon icon={faPlusCircle} style={{ color: 'lightgrey' }} /> Assign a new Category
            </Menu.Item>

            <Menu.ItemGroup title="Existing Categories">
                {
                    this.props.constantCategories && this.props.constantCategories.length > 0 && this.props.constantCategories.map((category, i) => (
                        <Menu.Item key={category} onClick={(e) => { this.setState({ constantToCreate: { ...this.state.constantToCreate, category: e.key }, isNewCategory: false }) }}>
                            <a> {category} </a>
                        </Menu.Item>
                    ))
                }
            </Menu.ItemGroup>
        </Menu>)

        return (
            <div>

                <Drawer
                    title="Upload a new constant"
                    width={520}
                    closable={false}
                    anchor="right"
                    onClose={this.actions.create.onCloseCreateConstantsDrawer}
                    // open={this.state.canCreateConstants && this.state.showCreateConstantsDrawer}
                    // visible={this.state.canCreateConstants && this.state.showCreateConstantsDrawer}
                    open={this.props.canCreateConstants && this.props.showCreateConstantsDrawer}
                    visible={this.props.canCreateConstants && this.props.showCreateConstantsDrawer}
                >

                    <p>
                        <b>New Constants</b>
                    </p>

                    <b>Constants Name <i>(internal)</i></b>
                    <Input name="name" onChange={(e) => { this.onChange(e) }} placeholder="Constants ..." />

                    <br />
                    <br />
                    <b>Constant Ref <i>(internal)</i></b>
                    <Input placeholder="" name="ref" onChange={(e) => { this.onChange(e) }} />


                    <br />
                    <br />

                    <b>File Category <i>(shown)</i></b>

                    <br />
                    <Dropdown overlay={selectCategoryDropDown} trigger={['click']} disabled={this.state.isNewCategory}>
                        <Button>
                            {!this.state.constantToCreate.category && <>  Assign Category <DownOutlined /></>}

                            {this.state.constantToCreate.category && <> {this.state.constantToCreate.category} <DownOutlined /> </>}
                        </Button>
                    </Dropdown>


                    {
                        this.state.isNewCategory && <>

                            <br />
                            <br />

                            <Button onClick={(e) => { this.setState({ ...this.state, isNewCategory: false }) }}>
                                <FontAwesomeIcon icon={faArrowLeft} /> &emsp; return to select existing category
                            </Button>

                            <br />
                            <br />

                            <Input name="category" onChange={(e) => { this.onChange(e) }} placeholder="Enter new category name ..." />

                            <br />
                            <br />

                        </>
                    }

                    <br />
                    <br />

                    <b>Constant Type <i>(internal)</i></b>
                    <br />
                    <Dropdown.Button onClick={handleButtonClick} overlay={menu}>
                        {! this.state.constantToCreate.type && 'Select a type from the dropdown list' }
                        {!! this.state.constantToCreate.type && this.state.constantToCreate.type }
                    </Dropdown.Button>

                    <br />
                    <br />
                    
                    <b>Constant value <i>(shown)</i></b>
                    <TextArea placeholder="2019" name="defaultValue" onChange={(e) => { this.onChange(e) }} />

                    <br /><br />
                    <b>Create a variant case? (<i>Advanced</i>)</b>
                    <br />
                    <p>A varient case allows a specific item in the Copy to change automatically when the <b>variant condition</b> is met.</p>
                   
                    <Switch
                        name='hasVariant'
                        onChange={() => {
                            this.setState({
                                constantToCreate: {
                                    ...this.state.constantToCreate,
                                    hasVariant: !this.state.constantToCreate.hasVariant
                                }
                            })
                        }}
                        checkedChildren={
                            <>
                                <a>&emsp; This copy has a variant case  &nbsp;</a>
                            </>
                        }
                        unCheckedChildren={
                            <>

                                <a>&emsp; This copy does not have a variant case &emsp;</a>
                            </>
                        }
                        defaultUnchecked
                    />

                    <br /><br />
                    <b>Variant Value (<i>Advanced</i>)</b>
                    <br />
                    <p>This value will be displayed when the associated condition is met.</p>                    
                    <TextArea disabled={! this.state.constantToCreate.hasVariant} name="varirantValue" onChange={(e) => { this.onChange(e) }} placeholder="Variant Value ..." />

                        <br />
                        <br />

                    <TextArea disabled={! this.state.constantToCreate.hasVariant} name="variantCondition" onChange={(e) => { this.onChange(e) }} placeholder="Variant Condition code..." />

                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                        }}
                    >
                        {!this.state.committed && <Button style={{
                            marginRight: 8,
                        }} disabled={this.state.committing} onClick={this.actions.onCloseCreateConstantsDrawer} >Cancel</Button>}
                        <>

                            {!this.state.committed &&
                                <Button
                                    type="primary"
                                    onClick={this.commitConstants}
                                    disabled={this.state.props}
                                    loading={this.state.committing}
                                    style={{ marginTop: 16 }}
                                >

                                    {!this.state.committed && !this.state.committing && 'Publish'}

                                </Button>
                            }


                            {/* <Button onClick={this.onCommitNewNotice} disabled={this.state.committing} type="primary">

                                {!this.state.committed && !this.state.committing && 'Publish'}

                                {this.state.committing && <>Publishing  <Icon type="loading" color="#52c41a" /> </>}

                            </Button> */}


                            {this.state.committed && <Button onClick={this.actions.onCloseCreateConstantsDrawer} type="success"> Finish </Button>}

                        </>

                    </div>
                </Drawer>


            </div>
        )
    }
}

export default CreateWebsiteCopyComponent
