/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { connect } from 'react-redux';
const mapStateToProps = (state) => {
  return {
    currentUser: state.user,
    tokens: state.tokens
  }
}

const userProfileBackgroundSVG = require('assets/img/undraw/undraw_people_tax5.svg');



class UserHeader extends React.Component {
  render() {
    return (
      <>
        <div
          className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
          style={{
            minHeight: "600px",
            backgroundImage:
              "url(" + userProfileBackgroundSVG + ")",
            backgroundSize: "cover",
            backgroundPosition: "center top"
          }}
        >
          {/* Mask */}
          <span className="mask bg-gradient-default opacity-8" />
          {/* Header container */}
          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col lg="7" md="10">
                <h1 className="display-2 text-white">Hello { this.props.currentUser.firstName && this.props.currentUser.firstName }</h1>
                <p className="text-white mt-0 mb-5">
                  This is your profile page. You can find a record of the information we hold for you here.
                  This page will only show you information stored within the portal. To see what information is held by the nursery please contact the nursery team directly.

                  {/* <a href="">Where is my data stored?</a> */}
                </p>


                {/* <Button
                  color="info"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  Edit profile
                </Button> */}


              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps)(UserHeader);
