
import React, { Component } from 'react';
import { Drawer, Form, Input, message, Upload, Select, DatePicker, Icon, Switch, Menu, Dropdown, notification, Button } from 'antd';
import { ScaleLoader } from 'react-spinners';
import '../../../node_modules/antd/lib/drawer/style/css';
import '../../../node_modules/antd/lib/date-picker/style/css';
import '../../../node_modules/antd/lib/switch/style/css';
import '../../../node_modules/antd/lib/dropdown/style/css';
import '../../../node_modules/antd/lib/badge/style/css';
import '../../../node_modules/antd/lib/message/style/css';
import '../../../node_modules/antd/lib/notification/style/css';

import reqwest from 'reqwest';
import BSCCE from '../../apis/BSCCEApi';
import { textChangeRangeIsUnchanged } from 'typescript';


const { TextArea } = Input;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

const props = {};

export class CreateTermsComponent extends Component {

    state = {
        termToCreate: {
            isCurrentTerm: false,
            name: '',
            season: '',
            date_start: '',
            date_end: '',
            date_halfterm_start: '',
            date_helfterm_end: '',
            date_neg_headcount_neg2: '',
            date_neg_headcount_neg3: ''
        },
    };


    actions = {};

    constructor(props) {
        super(props);

        this.actions = this.props.actions;
    }

    onChange = (e) => this.setState({
        termToCreate: {
            ...this.state.termToCreate,
            [e.target.name]: e.target.value
        }
    });


    onTermDatesChange = (dates, dateStrings) => {
        console.log('From: ', dates[0], ', to: ', dates[1]);
        console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);

        this.setState({
            termToCreate: {
                ...this.state.termToCreate,
                date_start: dates[0],
                date_end: dates[1]
            }
        });
    }

    onNEG2DatesChange = (date, dateStrings) => {
        this.setState({
            termToCreate: {
                ...this.state.termToCreate,
                date_neg_headcount_neg2: date
            }
        });
    }

    onNEG3DatesChange = (date, dateString) => {
        console.log('From: ', date);

        this.setState({
            termToCreate: {
                ...this.state.termToCreate,
                date_neg_headcount_neg3: date
            }
        })


    }

    onHalfTermDatesChange = (dates, dateStrings) => {
        console.log('From: ', dates[0], ', to: ', dates[1]);
        console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);

        this.setState({
            termToCreate: {
                ...this.state.termToCreate,
                date_halfterm_start: dates[0],
                date_halfterm_end: dates[1]
            }
        })
    }


    commitTerm = () => {

        console.log('submit ', this.state.termToCreate);


        BSCCE.terms.putTerm(this.state.termToCreate)
            .then(resp => {
                if (resp.data.status == 200) {

                    message.success('upload successfully.');

                    message.error('upload failed.');

                    this.setState({
                        committed: true,
                        committing: false
                    });

                    this.props.actions.create.onCompleteCreateTerm(resp.data);
                }
            })
            .catch(err => { })
    }

    render() {

        const { uploading, fileList } = this.state;
        const uploadProps = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };


        return (
            <div>

                <Drawer
                    title="Upload a new term"
                    width={520}
                    closable={false}
                    anchor="right"
                    onClose={this.actions.onCloseCreateTermDrawer}
                    // open={this.state.canCreateTerm && this.state.showCreateTermDrawer}
                    // visible={this.state.canCreateTerm && this.state.showCreateTermDrawer}
                    open={this.props.canCreateTerm && this.props.showCreateTermDrawer}
                    visible={this.props.canCreateTerm && this.props.showCreateTermDrawer}
                >

                    <p>
                        <b>New Term</b>
                    </p>

                    <b>Term Name <i>(shown)</i></b>
                    <Input name="name" onChange={(e) => { this.onChange(e) }} placeholder="Autumn Term ..." />

                    <br />
                    <br />
                    <b>Term Year <i>(shown)</i></b>
                    <Input placeholder="2019" name="season" onChange={(e) => { this.onChange(e) }} />

                    <br />
                    <br />

                    <b>Term Dates <i>(shown)</i></b>
                    <br />
                    <RangePicker onChange={this.onTermDatesChange} />

                    <br />
                    <br />
                    <b>Half Term Dates <i>(shown)</i></b>
                    < br />
                    <RangePicker onChange={this.onHalfTermDatesChange} />

                    <br />
                    <br />
                    <b>NEG 2 Headcount Date <i>(shown)</i></b>
                    <br />
                    <DatePicker onChange={this.onNEG2DatesChange} />

                    <br />
                    <br />
                    <b>NEG 3 Headcount Date <i>(shown)</i></b>
                    <br />
                    <DatePicker onChange={this.onNEG3DatesChange} />

                    <br />
                    <br />

                    <b>Mark this as the current term?</b>
                    <br />
                    <br />
                    <Switch
                        name='isCurrentTerm'
                        onChange={() => {
                            this.setState({
                                termToCreate: {
                                    ...this.state.termToCreate,
                                    isCurrentTerm: !this.state.termToCreate.isCurrentTerm
                                }
                            })
                        }}
                        checkedChildren={
                            <>
                                <a>&emsp;Will be shown as the current term dates on the website &nbsp;</a>
                            </>
                        }
                        unCheckedChildren={
                            <>

                                <a>&emsp;Will not be shown as the current term on the website &emsp;</a>
                            </>
                        }
                        defaultChecked
                    />

                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                        }}
                    >
                        {!this.state.committed && <Button style={{
                            marginRight: 8,
                        }} disabled={this.state.committing} onClick={this.actions.onCloseCreateTermDrawer} >Cancel</Button>}
                        <>

                            {!this.state.committed &&
                                <Button
                                    type="primary"
                                    onClick={this.commitTerm}
                                    disabled={this.state.props}
                                    loading={this.state.committing}
                                    style={{ marginTop: 16 }}
                                >

                                    {!this.state.committed && !this.state.committing && 'Publish'}

                                </Button>
                            }


                            {/* <Button onClick={this.onCommitNewNotice} disabled={this.state.committing} type="primary">

                                {!this.state.committed && !this.state.committing && 'Publish'}

                                {this.state.committing && <>Publishing  <Icon type="loading" color="#52c41a" /> </>}

                            </Button> */}


                            {this.state.committed && <Button onClick={this.actions.onCloseCreateTermDrawer} type="success"> Finish </Button>}

                        </>

                    </div>
                </Drawer>


            </div>
        )
    }
}

export default CreateTermsComponent
