/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { setState } from "react";
import PropTypes, { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import BSCCE from '../../apis/BSCCEApi';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";


import { ScaleLoader } from 'react-spinners';
import { IconButton } from '@material-ui/core';


import LockIcon from '@material-ui/icons/Lock';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { message } from "antd";
import { faExclamationTriangle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Validate extends React.Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  state = {
    submitting: false,
    canContinue: false,
    complete: false,
    tokenPreCheck: true,
    password: '',
    passwordConfirm: '',
    resetting: false,
    resetToken: '',
    promptToken: '',
    passwordResetToken: null
  };


  constructor(props) {
    super(props);

    this.setState({
      ...this.state
    })
  }

  cookies = {};

  onChangePassword = e => {

    this.onInlineValidatePassword(e.target.value);

    this.onChange(e);

  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentWillMount() {

    BSCCE.access.validateResetToken(this.props.location.pathname.split('/')[3])
      .then(resp => {

        // console.log ('auth check ', resp)
        if (resp.data.status == 200) {
          this.setState({
            ...this.state,
            submitting: false,
            tokenPreCheck: false,
            canContinue: true,
            tokenValid: true,
            passwordResetToken: resp.data.data.passwordResetToken
          });

          // console.log('will mount secc', this.state);
        } else {

          message.error(resp.data.data.reason.message)

          this.setState({
            submitting: false,
            tokenPreCheck: false,
            canContinue: false,
            tokenValid: false,
            complete: false
          })
        }
      });

  }


  componentDidMount() {


  }


  onSubmitNewPassword = (e) => {
    console.log('on submit net');

    this.setState({
      submitting: true,
      ressetting: true
    })

    BSCCE.access.resetPassword({
      password: this.state.password,
      passwordConfirm: this.state.passwordConfirm,
      passwordResetToken: this.state.passwordResetToken
    })
      .then(resp => {

        if (resp.data.status == 200) {

          this.setState({
            canContinue: true,
            tokenValid: true,
            submitting: false,
            resetting: false,
            complete: true
          });

        } else {
          message.error(resp.data.data.reason)

          this.setState({
            canContinue: false,
            tokenValid: false,
            submitting: false,
            resetting: false,
            complete: false
          });
        }

      })

  }



  onReturnToLogin = (e) => {
    console.log(this.props);

    window.location.href = ('/auth/login');
  }


  onGoToForgotPassword = (e) => {
    console.log(this.props);

    this.props.history.push('/auth/forgot');
  }

  onPromptSubmmit = (e) => {

    this.setState({ submitting: true });

  }


  onInlineValidatePassword = password => {
    let result = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(password);

    this.setState({
      passwordValidResult: result
    })
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>
                  {this.state.tokenPreCheck && !this.state.complete &&
                    <h4>Validating your access</h4>
                  }


                  {this.state.tokenValid && this.state.resetting && !this.state.complete &&
                    <h4>Updating your account</h4>
                  }

                  {!this.state.tokenValid && !this.state.tokenPreCheck && !this.state.submitting && !this.state.resetting && !this.state.complete && <>

                    <h4>We could not validate your access</h4>

                    It looks like your activation link has expired.



                  </>
                  }


                  {this.state.complete &&
                    <>
                      <h4>Account Updated</h4>
                      <p>Your credentials have been updated. </p>
                    </>
                  }

                  {!this.state.tokenPreCheck && this.state.tokenValid && !this.state.submitting && !this.state.complete &&
                    <>
                      <h4>Enter your new password</h4>

                      <br />
                      {
                        !this.state.passwordValidResult && <p style={{ marginTop: '20px' }}>
                          <FontAwesomeIcon icon={faExclamationTriangle} /> Passwords must be at least 8 characters long and include at least one number and one special character.
                       </p>
                      }
                      <br />

                      <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="New password" type="password" onChange={this.onChangePassword} name="password" />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Confirm password" type="password" onChange={this.onChange} name="passwordConfirm" />
                        </InputGroup>

                        {
                          !!this.state.passwordValidResult && <p style={{ marginTop: '20px' }}>
                            <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'lightgreen' }} /> Password ok.
                       </p>
                        }

                      </FormGroup>
                    </>
                  }

                </small>
              </div>
              <Form role="form">
                {(this.state.submitting || this.state.tokenPreCheck) &&
                  <div className='sweet-loading text-center'>
                    <ScaleLoader
                      // css={{}}
                      sizeUnit={"px"}
                      size={60}
                      color={'#176599'}
                    />
                  </div>
                }

                <div className="text-center">


                  {this.state.passwordValidResult && <>


                    <Button className="my-4"
                      color={(this.state.canContinue ? "success" : "primary")}
                      type="button"
                      onClick={(this.state.tokenValid && !this.state.canTontinue) ? this.onSubmitNewPassword : (this.state.complete && this.state.canContinue) ? this.onReturnToLogin : this.onGoToForgotPassword}
                      disabled={this.state.submitting || this.state.tokenPreCheck}>
                      {!this.state.submitting && !this.state.tokenPreCheck && this.state.tokenValid && 'continue'}
                      {!this.state.submitting && !this.state.tokenPreCheck && !this.state.tokenValid && 'Generate a new activation link'}
                      {(this.state.submitting || this.state.resetting || this.state.tokenPreCheck) && 'please wait ...'}
                      {this.state.promptSent && !this.state.submitting && 'return to login'}
                      {this.state.complete && !this.state.submitting && 'return to login'}
                    </Button>

                  </>}

                </div>

              </Form>

            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => { e.preventDefault(); this.props.history.push(''); }}
              >
                <small>Return to login?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              {/* <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small></small>
              </a> */}
            </Col>
          </Row>
        </Col>


        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          // open={this.state.promptAuth}
          autoHideDuration={1000}
          onClose={() => { this.setState.bind({ promptAuth: false }) }}
        >
          <MySnackbarContentWrapper
            onClose={() => { }}
            variant="warning"
            message="Please log in first"
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={(!!this.state.err)}
          autoHideDuration={3000}
          onClose={() => { }}
        >
          <MySnackbarContentWrapper
            onClose={() => { }}
            variant="danger"
            message={this.state.err}
          />
        </Snackbar>
      </>
    );
  }
}


// snackbars

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));


function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = WarningIcon;


  return (
    <SnackbarContent
      className='warning'
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};



export default withCookies(Validate);
