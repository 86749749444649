import React from 'react'
import { connect } from 'react-redux';
import ChildrenParentsViewComponent from './Parents/ChildrenParentsViewComponent';
import ChildrenStaffViewComponent from './Staff/ChildrenStaffViewComponent';

const mapStateToProps = (state) => {
    return {
        currentUser: state.user
    }
}

class ChildrenComponent extends React.Component {

    constructor(props) {

        super(props);

    }

    render() {

        return <>

            {this.props.currentUser.activeRole == 'parent' && <ChildrenParentsViewComponent />}
            {(this.props.currentUser.activeRole == 'staff' || this.props.currentUser.activeRole == 'admin') && <ChildrenStaffViewComponent />}

        </>

    }

}


export default connect(mapStateToProps)(ChildrenComponent)