/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import { connect } from 'react-redux';

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const mapStateToProps = (state) => {
  return {
    currentUser: state.user
  }
}
class BlankHeader extends React.Component {

  constructor(props) {
    super(props);


  }

  render() {
    return (
      <>

        <div class="header bg-primary pb-6">
          <div class="container-fluid">
            <div class="header-body">
              <div class="row align-items-center py-4">

                <br />
                <br />
                <br />
                <br />

                <div class="col-lg-6 col-7">

                  <nav aria-label="breadcrumb" class=" d-md-inline-block ml-md-4">
                    <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                      <li class="breadcrumb-item">
                        <a href="#">
                          <i class="fas fa-home"></i>
                          {/* &nbsp; {this.props.currentUser.activeRole} */}
                        </a>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        {this.props.pageTitle}
                      </li>
                    </ol>
                  </nav>
                </div>
                {
                  this.props.actions && this.props.actions.length > 0 && <>

                    {/* {(this.props.forUserRole == 'staff' || this.props.forUserRole == 'admin') && */}

                    <div class="col-lg-6 col-5 text-right">
                      {
                        this.props.actions.map(action => (

                          <>
                            {
                              action.forUserRoles.indexOf(this.props.currentUser.activeRole) > -1 &&
                              <>
                                <a onClick={() => { action.cb() }} class="btn btn-sm btn-neutral">
                                  <FontAwesomeIcon icon={action.icon} style={{ color: action.iconColour }} /> {action.title}
                                </a>
                              </>
                            }
                          </>

                        ))
                      }
                    </div>
                  </>
                }


              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

export default connect(mapStateToProps)(BlankHeader);

