/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip
} from "reactstrap";


import { Drawer, Form, Input, message, Upload, Select, DatePicker, Icon, Switch, Menu, Dropdown, notification, Tag } from 'antd';
import moment from 'moment';
import Truncate from 'react-truncate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faAmbulance, faBriefcase, faBullhorn, faCalendarAlt, faCamera, faCheckCircle, faClock, faCloudSun, faCloudSunRain, faCloudShowersHeavy, faHeartbeat, faHourglassEnd, faCogs, faHourglassStart, faHourglass, faKey, faSnowboarding, faSnowflake, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';

import { connect } from 'react-redux';


// core components
import BlankHeader from "components/Headers/BlankHeader.jsx";

import { ScaleLoader } from 'react-spinners';
import BSCCE from '../../../apis/BSCCEApi'

import ListLinkbookEntriesComponent from "./ListLinkbookEntriesComponent";
import LinkbookEntryComponent from "./LinkbookEntryComponent";

// import ViewLinkbookComponent from "../ViewEntryComponent";


import { SET_CONTEXT_CHILD } from '../../../redux/actions/contextActions';
import { Redirect } from "react-router-dom";

const { RangePicker } = DatePicker;
const { TextArea } = Input;


const mapStateToProps = (state) => {
  return {
    currentUser: state.user,
    context: state.context
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSetEntryContext: childContext => {
      dispatch(SET_CONTEXT_CHILD(childContext));
    }
  }
}

class Linkbooks extends React.Component {

  constructor(props) {
    super(props);

    console.log('linkbooks props ', props);
  }

  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description
    });
  };

  state = {
    toggles: {
      showCreateEntryDrawer: false,
      showDeleteEntryDrawer: false,
      showEditEntryDrawer: false
    },
    // semephores
    sem: {
      deleting: false,
      deleted: false,
      committing: false,
      committed: false,
      uploading: false,
      uploaded: false,
      loading: true
    },
    // permissions
    perms: {
      canCreateEntry: true,
      canDeleteEntry: true,
      canEditEntry: true
    },
    // mutable
    mut: {
      entryToCreate: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        isActive: '',
        isVerified: '',
        roles: []
      },
      focusedEntry: null,
      fileList: [],
      entries: [{ title: 'Week 21' }, { title: 'Week 22' }, { title: 'Week 23' }]
    }
  }

  componentWillMount() {

    // BSCCE.linkbooks.getLinkbooks()
    //     this.setState({

    //       mut: {
    //         ...this.state.mut,
    //         linkbooks: res.data.data
    //       },
    //       sem: {
    //         ...this.state.sem,
    //         loading: false
    //       }

    //     });

    //   })
  }

  actions = {
    list: {
      onSelectEntryFromList: (entry) => {
        console.log('on select entry from list');

        this.setState({
          mut: {
            ...this.state.mut,
            focusedEntry: entry
          }
        });
      },
      onDeselectEntryFromList: () => {
        this.setState({
          mut: {
            ...this.state.mut,
            focusedEntry: null
          }
        })
      },
      onReturnToChildView: () => {
        this.setState({
          ...this.state,
          redirect: true,
          redirectTo: '/admin/children'
        })
      }
    },
    create: {
      onShowCreateEntryDrawer: (e) => {

        this.setState({
          toggles: {
            ...this.state.toggles,
            showCreateEntryDrawer: true
          }
        })
      },
      onCloseCreateEntryDrawer: (e) => {
        this.setState({
          toggles: {
            ...this.state.toggles,
            showCreateEntryDrawer: false
          }
        });
      },
      onCompleteCreateEntry: (e) => {
        this.setState({
          mut: {
            ...this.state.mut,
            linkbooks: [...this.state.mut.linkbooks, e]
          }
        })
      }
    },
    edit: {
      onShowEditEntryDrawer: (item) => {
        console.log('edit', item);
        this.setState({
          toggles: {
            ...this.state.toggles,
            showEditEntryDrawer: true,
          },
          mut: {
            ...this.state.mut,
            focusedEntry: item
          }
        })

      },
      onCloseEditEntryDrawer: (e) => {

        this.setState({
          toggles: {
            ...this.state.toggles,
            showEditEntryDrawer: false
          },
          mut: {
            ...this.state.mut,
            focusedEntry: null
          }
        })

      },
    },

    delete: {
      onshowDeleteEntryDrawer: (e) => {
        console.log('delete', e);
        this.setState({
          toggles: {
            ...this.state.toggles,
            showDeleteEntryDrawer: true
          }
        })
      },
      onCloseDeleteEntryDrawer: (e) => {
        this.setState({
          toggles: {
            ...this.state.toggles,
            showDeleteEntryDrawer: false
          }
        })
      },
      onDeleteEntry: (e) => {
        console.log('delete now', this.state.mut.focusedEntry._id)
        this.setState({
          sem: {
            ...this.state.sem,
            deleting: true
          }
        });

        let child = {
          id: this.state.mut.focusedEntry._id,
          key: this.state.mut.focusedEntry.key,
        }

        BSCCE.linkbooks.deleteEntry(this.state.mut.focusedEntry._id)
          .then((res) => {

            if (res.data.status == 200) {
              this.setState({
                sem: {
                  deleting: false,
                  deleted: true
                },
                mut: {
                  ...this.state.mut,
                  linkbooks: this.state.mut.linkbooks.filter(child => child._id != this.state.mut.focusedEntry._id)
                }
              });

              this.actions.delete.onDeleteComplete();
            } else {
              // this.actions.delete.onDeleteFailed ()
              // error handler
              alert('an error occured');
              console.log(res);
            }

          })

          .catch((err) => {
            console.log('error', err);
          })

      },

      onDeleteComplete: (e) => {
        this.actions.edit.onCloseEditEntryDrawer();
        this.actions.delete.onCloseDeleteconfirmationDrawer();
        this.openNotificationWithIcon(
          'success',
          'Delete success',
          // `has now been deleted.`
          `${this.state.mut.focusedEntry.firstName} has now been deleted.`
        );

        this.setState({
          sem: {
            ...this.state.sem,
            deleting: false,
            deleted: false,
          },
          mut: {
            ...this.state.mut,
            focusedEntry: null
          }
        });

      }
    }
  };



  render() {
    return (

      <>

        {
          this.state.redirect &&  <Redirect to='/admin/children' />
        }

        <BlankHeader pageTitle={
          this.props.context.focusedChild.firstName
          + ((this.props.context && this.props.context.focusedEntryId) ? '/ ' + this.props.context.focusedEntryId : '')
          + ' / Linkbooks'

        } actions={[

          {
            icon: faPlusCircle,
            iconColour: 'green',
            title: 'Refresh',
            cb: this.actions.create.onShowCreateEntryDrawer,
            forUserRoles: ['parent']
          }

        ]} />

        <ListLinkbookEntriesComponent
          entries={this.state.mut.entries}
          actions={this.actions}
          focusedEntry={this.state.mut.focusedEntry}
          focusedChild={this.props.context.focusedChild}
        />

        {
          this.state.mut.focusedEntry &&

          <LinkbookEntryComponent
            focusedEntry={this.state.mut.focusedEntry}
            actions={this.actions}
            focusedChild={this.props.context.focusedChild}
          />

        }

      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Linkbooks);
