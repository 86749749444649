import React, { Component } from 'react';

import { Table, Drawer, Form, Input, message, Upload, Select, DatePicker, Icon, Switch, Menu, Dropdown, notification, Tag, Empty } from 'antd';

import { ScaleLoader } from 'react-spinners';
import BSCCE from '../../apis/BSCCEApi';

import {
    Badge,
    Button,
    Card,
    CardBody,
    CardTitle,
    CardHeader,
    CardFooter,
    Col,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Container,
    Row,
    UncontrolledTooltip
} from "reactstrap";

import PDFViewer from 'pdf-viewer-reactjs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight, faTimes, faFolder, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'



const newsletterHeadSVG = require('assets/img/undraw/undraw_online_articles_79ff.svg');

export class ListDocumentsCategoriesComponent extends Component {

    componentWillUpdate() {

    }

    state = {
        newsletters: [],
        pdf: '',
        forUserRole: '',
        focusedCategory: "Newsletter"
    };

    actions = {};

    constructor(props) {
        super(props);

        this.actions = this.props.actions;

    }

    onDownloadFile(id) {
        console.log('download ', id);
        message.loading('your download should begin shortly', 5)
        BSCCE.files.getFile({ id: id })
            .then((resp) => {
                console.log('open ', resp.data.data.url);
                window.open(resp.data.data.url, '_blank');
            })
    }

    render() {
        return (
            <div>

                <Container className="mt--7" fluid>

                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">


                                <CardHeader className="border-0" style={{borderBottom: 'thin solid grey'}}>

                                    <h2 style={{ paddingTop: '5px' }}>
                                        {this.props.focusedCategory && this.props.focusedCategory}

                                        {!this.props.focusedCategory && <> <span>Select a category below</span> </>}
                                    </h2>


                                </CardHeader>
                          

                                {
                                    this.props.loadingCategories && <>
                                        <div style={{ margin: 'auto', width: '250px', padding: '40px' }}>
                                        <ScaleLoader />

                                        </div>
                                 
                                    </>
                                }

                                {this.props.focusedCategory && <>

                                <Row style={{ padding: '5px', display: 'block'}}>
                                    <Col className="col-auto">

                                        <h3 style={{ display: 'block', float: 'left', width: '100%', border: 'thin solid #eaeaea' }} onClick={() => { this.props.actions.list.onSelectCategoryFromList(null) }} class="btn btn-sm btn-neutral">
                                            <FontAwesomeIcon icon={faArrowCircleLeft} style={{ color: 'dodgeblue' }} />
                                            &emsp; return to category list
                                        </h3>

                                    </Col>
                                </Row>

                                </>}


                                {this.props.focusedCategory == null && <>


                                    {  ! this.props.loadingCategories && (! this.props.categories || this.props.categories.length == 0) && <>
                                        <Empty />
                                    </>}




                                    {this.props.categories && this.props.categories.length > 0 && this.props.categories.map(category => (

                                        <div style={{ background: '#eee', borderBottom: 'thin solid lightgrey', padding: '10px 20px' }} onClick={() => { this.props.actions.list.onSelectCategoryFromList(category) }}>
                                            <span style={{ fontSize: '16pt', }} >
                                                <FontAwesomeIcon icon={faFolder} style={{ color: 'orange' }} />
                                                <span style={{ paddingLeft: '10px', color: 'dodgeblue' }} onClick={() => { this.props.actions.list.onSelectCategoryFromList(category) }}>
                                                    {category}
                                                </span>
                                            </span>

                                            <p className="mt-0 mb-0 text-muted text-sm" onClick={() => { this.props.actions.list.onSelectCategoryFromList(category) }}>
                                                <span className="text-nowrap">Browse {category}</span>
                                                <span className="text-success mr-2"> {' '} {' '}
                                                    <FontAwesomeIcon icon={faArrowCircleRight} />
                                                </span>
                                            </p>
                                        </div>

                                    ))}


                                </>}
                            </Card>
                        </div>
                    </Row>
                </Container>

                <br /><br />
            </div >
        )
    }
}

export default ListDocumentsCategoriesComponent
