/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip
} from "reactstrap";


import { Drawer, Form, Input, message, Upload, Select, DatePicker, Icon, Switch, Menu, Dropdown, notification, Tag } from 'antd';
import moment from 'moment';
import Truncate from 'react-truncate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faAmbulance, faBriefcase, faBullhorn, faCalendarAlt, faCamera, faCheckCircle, faClock, faCloudSun, faCloudSunRain, faCloudShowersHeavy, faHeartbeat, faHourglassEnd, faCogs, faHourglassStart, faHourglass, faKey, faSnowboarding, faSnowflake, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';

import { connect } from 'react-redux';



// import Drawer from '@material-ui/core/Drawer';
import '../../../node_modules/antd/lib/drawer/style/css';
import '../../../node_modules/antd/lib/date-picker/style/css';
import '../../../node_modules/antd/lib/switch/style/css';
import '../../../node_modules/antd/lib/dropdown/style/css';
import '../../../node_modules/antd/lib/tag/style/css';
import '../../../node_modules/antd/lib/message/style/css';
import '../../../node_modules/antd/lib/notification/style/css';


import { CreateUsersComponent } from "../Users/CreateUsersComponent";
import { EditUsersComponent } from "../Users/EditUsersComponent";
import { DeleteUsersComponent } from "../Users/DeleteUsersComponent";
import ListUsersComponent from "../Users/ListUsersComponent";


// core components
import BlankHeader from "components/Headers/BlankHeader.jsx";

import { ScaleLoader } from 'react-spinners';
import BSCCE from '../../apis/BSCCEApi';


const { RangePicker } = DatePicker;
const { TextArea } = Input;


const mapStateToProps = (state) => {
  return {
    currentUser: state.user
  }
}


class Users extends React.Component {

  constructor(props) {
    super(props);

    console.log('user props ', props);
  }

  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description
    });
  };



  state = {
    toggles: {
      showCreateUserDrawer: false,
      showDeleteUserDrawer: false,
      showEditUserDrawer: false
    },
    // semephores
    sem: {
      deleting: false,
      deleted: false,
      committing: false,
      committed: false,
      uploading: false,
      uploaded: false,
      loading: true
    },
    // permissions
    perms: {
      canCreateUser: true,
      canDeleteUser: true,
      canEditUser: true
    },
    // mutable
    mut: {
      userToCreate: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        isActive: '',
        isVerified: '',
        roles: []
      },
      focusedUser: {},
      fileList: [],
      users: []
    }
  }


  componentWillMount() {
    BSCCE.users.getUsers()
      .then(res => {
        this.setState({

          mut: {
            ...this.state.mut,
            users: res.data.data
          },
          sem: {
            ...this.state.sem,
            loading: false
          }


        });

      })
  }



  actions = {
    list: {
      onSelectUserFromList: (item) => {
        this.setState({
          mut: {
            ...this.state.mut,
            focusedUser: item
          }
        });
      }
    },
    create: {
      onShowCreateUserDrawer: (e) => {

        this.setState({
          toggles: {
            ...this.state.toggles,
            showCreateUserDrawer: true
          }
        })
      },
      onCloseCreateUserDrawer: (e) => {
        this.setState({
          toggles: {
            ...this.state.toggles,
            showCreateUserDrawer: false
          }
        });
      },
      onCompleteCreateUser: (e) => {
        this.setState({
          mut: {
            ...this.state.mut,
            users: [...this.state.mut.users, e]
          }
        })
      }
    },
    edit: {
      onShowEditUserDrawer: (item) => {
        console.log('edit', item);
        this.setState({
          toggles: {
            ...this.state.toggles,
            showEditUserDrawer: true,
          },
          mut: {
            ...this.state.mut,
            focusedUser: item
          }
        })

      },
      onCloseEditUserDrawer: (e) => {

        this.setState({
          toggles: {
            ...this.state.toggles,
            showEditUserDrawer: false
          },
          mut: {
            ...this.state.mut,
            focusedUser: null
          }
        })

      },
    },

    delete: {
      onshowDeleteUserDrawer: (e) => {
        console.log('delete', e);
        this.setState({
          toggles: {
            ...this.state.toggles,
            showDeleteUserDrawer: true
          }
        })
      },
      onCloseDeleteconfirmationDrawer: (e) => {
        this.setState({
          toggles: {
            ...this.state.toggles,
            showDeleteUserDrawer: false
          }
        })
      },
      onDeleteUser: (e) => {
        console.log('delete now', this.state.mut.focusedUser._id)
        this.setState({
          sem: {
            ...this.state.sem,
            deleting: true
          }
        });

        let user = {
          id: this.state.mut.focusedUser._id,
          key: this.state.mut.focusedUser.key,
        }

        BSCCE.users.deleteUser(this.state.mut.focusedUser._id)
          .then((res) => {

            if (res.data.status == 200) {
              this.setState({
                sem: {
                  deleting: false,
                  deleted: true
                },
                mut: {
                  ...this.state.mut,
                  users: this.state.mut.users.filter(user => user._id != this.state.mut.focusedUser._id)
                }
              });

              this.actions.delete.onDeleteComplete();
            } else {
              // this.actions.delete.onDeleteFailed ()
              // error handler
              alert('an error occured');
              console.log(res);
            }

          })

          .catch((err) => {
            console.log('error', err);
          })




      },

      onDeleteComplete: (e) => {
        this.actions.edit.onCloseEditUserDrawer();
        this.actions.delete.onCloseDeleteconfirmationDrawer();
        this.openNotificationWithIcon(
          'success',
          'Delete success',
          // `has now been deleted.`
          `${this.state.mut.focusedUser.firstName} has now been deleted.`
        );

        this.setState({
          sem: {
            ...this.state.sem,
            deleting: false,
            deleted: false,
          },
          mut: {
            ...this.state.mut,
            focusedUser: null
          }
        });

      }
    }
  };



  render() {
    return (

      <>
        <CreateUsersComponent
          canCreateUser={this.state.perms.canCreateUser}
          showCreateUserDrawer={this.state.toggles.showCreateUserDrawer}
          userToCreate={this.state.mut.userToCreate}
          focusedUser={this.state.mut.focusedUser}
          actions={this.actions.create}
        />

        <DeleteUsersComponent
          canDeleteUser={this.state.perms.canDeleteUser}
          showDeleteUserDrawer={this.state.toggles.showDeleteUserDrawer}
          onCloseDeleteUserDrawer={this.actions.delete.onCloseDeleteUserDrawer}
          userToEdit={this.state.mut.focusedUser}
          focusedUser={this.state.mut.focusedUser}
          actions={this.actions}
        />


        <EditUsersComponent
          canEditUser={this.state.perms.canEditUser}
          showEditUserDrawer={this.state.toggles.showEditUserDrawer}
          onCloseEdiUserDrawer={this.actions.edit.onCloseEdiUserDrawer}
          userToEdit={this.state.mut.focusedUser}
          onFieldChange={this.onFieldChange}
          actions={this.actions}
        />

        <BlankHeader pageTitle={"Users"} actions={[
          {
            icon: faPlusCircle,
            iconColour: 'green',
            title: 'Create',
            cb: this.actions.create.onShowCreateUserDrawer,
            forUserRoles: ['admin']
          }
        ]} />

        <ListUsersComponent
          loading={this.state.sem.loading}
          users={this.state.mut.users}
          actions={this.actions}
          forUserRole={this.props.currentUser.activeRole}
        />

      </>
    );
  }
}

export default connect(mapStateToProps)(Users);
