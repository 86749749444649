/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";

import Header from "components/Headers/Header.jsx";
import { connect } from 'react-redux';


import { withCookies, Cookies } from 'react-cookie';
import PropTypes, { instanceOf } from 'prop-types';

import ParentsDashboardComponent from './Dashboard/Parents';
import StaffDashboardComponent from './Dashboard/Staff';

const mapStateToProps = (state) => {
  return {
    currentUser: state.user,
    tokens: state.tokens
  }
}


class DashboardWrapperComponent extends React.Component {


  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    console.log('index.props', props);
  }

  state = {
    activeNav: 1,
    chartExample1Data: "data1"
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }
  render() {
    return <>
      { this.props.currentUser.activeRole == 'parent' &&   <ParentsDashboardComponent {...this.props}/> }
      { this.props.currentUser.activeRole == 'staff' &&   <StaffDashboardComponent {...this.props}/> }
     {/* <StaffDashboardComponent /> */}
     </>
  }
}

export default connect(mapStateToProps)(withCookies(DashboardWrapperComponent));
