  
export const SET_USER_INFO = 'SET_USER_INFO';
export const UNSET_USER_INFO = 'UNSET_USER_INFO';
export const SER_USER_ROLE = 'SER_USER_ROLE';

export const SET_VIEW_AS_USER_INFO = 'SET_VIEW_AS_USER_INFO'
export const UNSET_VIEW_AS_USER_INFO = 'UNSET_VIEW_AS_USER_INFO'

export function SET_USER (user) {
  let action = { type: SET_USER_INFO, user };
  return action;
}

export function UNSET_USER() {
  return { type: UNSET_USER_INFO, user: null };
}
export function SET_USER_ROLE(role) {
  return { type: SET_USER_ROLE, role: role };
}

export function SET_VIEW_AS_USER (user) {
  return { type: SET_VIEW_AS_USER_INFO, viewAsUserTokens: user };
}

export function UNSET_VIEW_AS_USER () {
  return { type: UNSET_VIEW_AS_USER_INFO, user: null };
}



