/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { CookiesProvider } from 'react-cookie';


import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";

import AdminLayout from "./layouts/Admin.jsx";
import AuthLayout from "./layouts/Auth.jsx";
import BSCCE from "./apis/BSCCEApi";
import requiresAuth from './components/Auth/isAuthenticated';
import ErrorBoundary from './components/Error/ErrorBoundary';

// Redux
import { Provider } from 'react-redux';
import store from './redux/store/store';

ReactDOM.render(

  <Provider store={store}>
    <CookiesProvider>
      <BrowserRouter>

        <ErrorBoundary>
          <Switch>

            <Route path="/auth" render={props => <AuthLayout {...props} />} />

            <Route component={requiresAuth}>
              <Route path="/admin" render={props => <AdminLayout {...props} />} />
              <Route path="/parents" render={props => <AdminLayout {...props} />} />
            </Route>

            <Redirect from="/" to="/admin/index" />
          </Switch>
        </ErrorBoundary>

      </BrowserRouter>
    </CookiesProvider>
  </Provider>,
  
  document.getElementById("root")
);
