
export const SET_VIEW_AS_USER_INFO = 'SET_VIEW_AS_USER_INFO'
export const UNSET_VIEW_AS_USER_INFO = 'UNSET_VIEW_AS_USER_INFO'

export function SET_VIEW_AS_USER (user) {
  return { type: SET_VIEW_AS_USER_INFO, viewAsUserTokens: user };
}

export function UNSET_VIEW_AS_USER () {
  return { type: UNSET_VIEW_AS_USER_INFO, user: null };
}



