import React, { PropTypes } from 'react';
import { Result, Button } from 'antd'
import { Redirect } from 'react-router-dom';


const errorPNG = require('assets/img/undraw/undraw_programming_2svr.png')

class ErrorBoundary extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = { hasError: false, redirect: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {

    if (this.state.redirect) return <Redirect to='/admin/index' />

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <>

        {this.state.redirect && <Redirect to="/admin/index" />}

        <div id="root">

          <div style={{ margin: 'auto', width: '250px' }}>

            <Result
              status="500"
              title="500"
              subTitle="Sorry, something went wrong."
              extra={<>
              <h1 style={{color: 'white'}}> Oops... </h1>
              <p style={{color: 'white'}}> Something appears to have gone wrong. Please refresh and try again.</p>
              <Button type="primary" onClick={()=> { this.setState ({redirect: true })}}>Back Home</Button>
              </>}
            />

          </div>

        </div>
      </>

    }

    return this.props.children;
  }
}

export default ErrorBoundary