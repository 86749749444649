/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip
} from "reactstrap";


import { Drawer, Form, Input, message, Upload, Select, DatePicker, Icon, Switch, Menu, Dropdown, notification } from 'antd';
import moment from 'moment';
import Truncate from 'react-truncate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faAmbulance, faBriefcase, faBullhorn, faCalendarAlt, faCamera, faCheckCircle, faClock, faCloudSun, faCloudSunRain, faCloudShowersHeavy, faHeartbeat, faHourglassEnd, faCogs, faHourglassStart, faHourglass, faKey, faSnowboarding, faSnowflake, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';



// import Drawer from '@material-ui/core/Drawer';
import '../../../node_modules/antd/lib/drawer/style/css';
import '../../../node_modules/antd/lib/date-picker/style/css';
import '../../../node_modules/antd/lib/switch/style/css';
import '../../../node_modules/antd/lib/dropdown/style/css';
import '../../../node_modules/antd/lib/badge/style/css';
import '../../../node_modules/antd/lib/message/style/css';
import '../../../node_modules/antd/lib/notification/style/css';


// core components
import BlankHeader from "components/Headers/BlankHeader.jsx";

import { ScaleLoader } from 'react-spinners';
import BSCCE from '../../apis/BSCCEApi';


const { RangePicker } = DatePicker;
const { TextArea } = Input;

class Notices extends React.Component {

  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description
    });
  };

  componentWillMount() {
    BSCCE.notices.getNotices()
      .then(res => {
        this.setState({ notices: res.data.data, loading: false });
        console.log('get notices ', res.data.data);
      });
  }

  state = {
    notices: [],
    loading: true,
    canCreateNotice: true,
    showCreateNoticeDrawer: false,
    canEditNotice: true,
    showEditNoticeDrawer: false,
    committed: false,
    committing: false,
    noticeToCreate: { notice_icon: null, notice_active: false, notice_scheduled: false },
    noticeToEdit: {},
    selectedNotice: {},
    deleting: false,
    deleted: false,
  };

  onDatesChange(dates, dateStrings) {
    console.log('From: ', dates[0], ', to: ', dates[1]);
    console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
  }

  onCommitNewNotice = (e) => {
    console.log('commit new');
    this.setState({
      committing: true
    });

    BSCCE.notices.createNotice(this.state.noticeToCreate)
      .then(res => {

        if (res.status == 200) {
          this.openNotificationWithIcon('success', 'Create success', `~${this.state.noticeToCreate.notice_title} has been published successfully`);
          this.setState({
            committing: false,
            committed: true,
            notices: [...this.state.notices, this.state.noticeToCreate],
            showCreateNoticeDrawer: false
          });

        } else {
          message.error('an error occurred');
          console.log(res);
        }

      }, err => {

      })



    // BSCCE.notices.putNotices (this.state.noticeToCreate)
    //     .then(res => {
    //         console.log ('onAfterPutNotice', res.data);
    //         if (res.data.status == 200) {
    //             this.setState ({
    //                 committing: false,
    //                 committed: true
    //             });
    //         }
    //     });    

  }

  onSelectNoticeFromList = (e) => {
    this.setState({ selectedNotice: e });
  }

  onShowCreateNotice = (e) => {
    console.log('show notice create');
    this.setState({ showCreateNoticeDrawer: true, noticeToCreate: {} });
    console.log(this.state);
  };

  onCloseCreateNoticeDrawer = (e) => {
    this.setState({
      showCreateNoticeDrawer: false
    });
  }

  onSelectCreateNoticeIcon = (e) => {
    console.log(e);
  }

  onShowEditNoticeDrawer = (e) => {

    console.log('show notice edit', e);
    this.setState({
      showEditNoticeDrawer: true,
      selectedNotice: e,
      noticeToEdit: e
    });

    console.log(this.state);

    console.log(this.state);
  }

  onCloseEditNoticeDrawer = (e) => {
    this.setState({
      showEditNoticeDrawer: false,
      selectedNotice: null,
      noticeToEdut: null
    });
  }

  onShowDeleteConfirmationDrawer = (e) => {
    console.log('show delete drawer');
    this.setState({
      onS: true
    });
  }

  onCloseDeleteconfirmationDrawer = (e) => {
    console.log('close delete drawer');
    this.setState({
      onS: false
    });
  }

  onDeleteNotice = (e) => {
    console.log('delete notice ', e);
    this.setState({
      deleting: true,
    })

    setTimeout(() => {
      this.setState({
        deleting: false,
        deleted: true
      })
      this.setState({
        notices: [...this.state.notices.filter(notice => notice.notice_id != this.state.noticeToEdit.notice_id)]
      });

      this.onDeleteComplete();


    }, 2000);

  }

  onDeleteComplete = (e) => {
    this.onCloseEditNoticeDrawer();
    this.openNotificationWithIcon('success', 'Delete success', `${this.state.noticeToEdit.notice_name} has now been deleted.`);

    this.setState({
      deleting: false,
      deleted: false,
      selectedNotice: null,
      noticeToEdit: null,
    });

    this.onCloseDeleteconfirmationDrawer();
  }


  onChange = (e) => this.setState({
    noticeToCreate: {
      ...this.state.noticeToCreate,
      [e.target.name]: e.target.value
    }
  });




  createNoticeIconMenu = (
    <Menu onClick={this.onSelectCreateNoticeIcon}>
      <Menu.Item key="fa-bell"><FontAwesomeIcon icon={faBell} /></Menu.Item>
      <Menu.Item key="fa-address-card"><FontAwesomeIcon icon={faAddressCard} /></Menu.Item>
      <Menu.Item key="fa-ambulance"><FontAwesomeIcon icon={faAmbulance} /></Menu.Item>
      <Menu.Item key="fa-briefcase"><FontAwesomeIcon icon={faBriefcase} /></Menu.Item>
      <Menu.Item key="fa-bullhorn"><FontAwesomeIcon icon={faBullhorn} /></Menu.Item>
      <Menu.Item key="fa-calendar-alt"><FontAwesomeIcon icon={faCalendarAlt} /></Menu.Item>
      <Menu.Item key="fa-camera"><FontAwesomeIcon icon={faCamera} /></Menu.Item>
      <Menu.Item key="fa-check-circle"><FontAwesomeIcon icon={faCheckCircle} /></Menu.Item>
      <Menu.Item key="fa-clock"><FontAwesomeIcon icon={faClock} /></Menu.Item>
      <Menu.Item key="fa-cloud-sun"><FontAwesomeIcon icon={faCloudSun} /></Menu.Item>
      <Menu.Item key="fa-cloud-sun-rain"><FontAwesomeIcon icon={faCloudSunRain} /></Menu.Item>
      <Menu.Item key="fa-cloud-showers-heavy"><FontAwesomeIcon icon={faCloudShowersHeavy} /></Menu.Item>
      <Menu.Item key="fa-cogs"><FontAwesomeIcon icon={faCogs} /></Menu.Item>
      <Menu.Item key="fa-heartbeat"><FontAwesomeIcon icon={faHeartbeat} /></Menu.Item>
      <Menu.Item key="fa-hourglass-end"><FontAwesomeIcon icon={faHourglassEnd} /></Menu.Item>
      <Menu.Item key="fa-hourglass"><FontAwesomeIcon icon={faHourglass} /></Menu.Item>
      <Menu.Item key="fa-hourglass-start"><FontAwesomeIcon icon={faHourglassStart} /></Menu.Item>
      <Menu.Item key="fa-key"><FontAwesomeIcon icon={faKey} /></Menu.Item>
      <Menu.Item key="fa-snowflake"><FontAwesomeIcon icon={faSnowflake} /></Menu.Item>
    </Menu>
  );


  render() {
    return (
      <>
        <Drawer
          title="Publish a new notice"
          width={520}
          closable={false}
          anchor="right"
          onClose={this.onCloseCreateNoticeDrawer}
          open={this.state.canCreateNotice && this.state.showCreateNoticeDrawer}
          visible={this.state.canCreateNotice && this.state.showCreateNoticeDrawer}
        >

          <p>
            <b>New Notice</b>
          </p>

          <b>Notice Title <i>(shown)</i></b>
          <Input placeholder="Notice Title" onChange={(e) => { this.onChange(e) }} name="notice_title" />

          <br />
          <br />
          <b>Notice Name <i>(internal)</i></b>
          <Input placeholder="Notice Name (internal)" onChange={(e) => { this.onChange(e) }} name="notice_name" />

          <br />
          <br />

          <b>Notice Icon <i>(shown)</i>  </b>
          <Dropdown overlay={this.createNoticeIconMenu}>
            <a className="ant-dropdown-link" href="#">
              {(null == this.state.noticeToCreate.notice_icon) && <>Select an icon to be shown with this notice <Icon type="down" /></>}
              {(null != this.state.noticeToCreate.notice_icon) && <>icon <Icon type="down" /></>}
            </a>
          </Dropdown>

          <br />
          <br />

          <b>Dismiss this notice once it has been seen?</b>
          <a>&emsp;</a>

          <Switch
            checkedChildren={<Icon type="check" />}
            unCheckedChildren={<Icon type="close" />}
            defaultUnChecked
            name="notice_persist"
            onChange={(e) => { this.setState({ noticeToCreate: { ...this.state.noticeToCreate, notice_persist: e } }) }}
          />

          <br />
          <br />

          <b>Apply a schedule to this notice </b>
&emsp;
      <Switch
            name="notice_scheduled"
            checkedChildren={<Icon type="check" />}
            unCheckedChildren={<Icon type="close" />}
            defaultCUnhecked
            onChange={(e) => { this.setState({ noticeToCreate: { ...this.state.noticeToCreate, notice_scheduled: e } }) }}
          />
          <br />
          <br />

          <div>
            {this.state.noticeToEdit && <>Select a start and end date for this notice
        <br />
              <br />
              <RangePicker
                ranges={{
                  Today: [moment(), moment()],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                }}
                onChange={this.onDatesChange}
              />
            </>
            }
          </div>


          <br />

  Should this notice appear on the website. (If this is not a scheduled notice then it will appear straight away. Otherwise it will appear on the selected start date).

&emsp;
  <Switch
            checkedChildren={<Icon type="check" />}
            unCheckedChildren={<Icon type="close" />}
            defaultUnChecked

            onChange={(e) => { this.setState({ noticeToCreate: { ...this.state.noticeToCreate, notice_active: e } }) }}
          />


          <br />
          <br />
          <TextArea rows={4} onChange={(e) => { this.onChange(e) }} name="notice_body" />



          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }}
          >
            {!this.state.committed && <Button style={{
              marginRight: 8,
            }} disabled={this.state.committing} onClick={this.onCloseCreateNoticeDrawer} >Cancel</Button>}
            <>
              {!this.state.committed && <Button onClick={this.onCommitNewNotice} disabled={this.state.committing} type="primary">
                {!this.state.committed && !this.state.committing && 'Publish'}
                {this.state.committing && <>Publishing  <Icon type="loading" color="#52c41a" /> </>}
              </Button>}
              {this.state.committed && <Button onClick={this.onCloseCreateNoticeDrawer} type="success"> Finish </Button>}

            </>

          </div>
        </Drawer>
        {/* 
    
    EDIT NOTICE DRAWER
    
    */}
        <Drawer
          title={<><a>Edit notice</a>   <UncontrolledDropdown>
            <DropdownToggle
              className="btn-icon-only text-light"
              href="#pablo"
              role="button"
              size="sm"
              color=""
              onClick={() => { }}
            >
              {this.state.selectedNotice && <h4>{this.state.selectedNotice.title} <Icon type="tool" theme="twoTone" style={{ fontSize: '14pt' }} color="#1890ff" /> </h4>}

            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <Button style={{ color: 'darkred' }} type="primary" onClick={() => { this.onShowDeleteConfirmationDrawer(this.state.selectedNotice) }}>
                  Delete {this.state.noticeToEdit && this.state.noticeToEdit.notice_name}
                </Button>
              </DropdownItem>

            </DropdownMenu>
          </UncontrolledDropdown> </>}
          width={520}
          closable={false}
          anchor="right"
          onClose={this.onCloseEditNoticeDrawer}
          open={this.state.canEditNotice && this.state.showEditNoticeDrawer}
          visible={this.state.canEditNotice && this.state.showEditNoticeDrawer}
        >

          {this.state.noticeToEdit && <h4>{this.state.noticeToEdit.notice_name}</h4>}
          <br /><br />
          {this.state.noticeToEdit && <Input placeholder="Notice Title" value={this.state.noticeToEdit.notice_title} />}

          <br />
          <br />

          {this.state.noticeToEdit && <Input placeholder="Notice Name (internal)" value={this.state.noticeToEdit.notice_name} />}

          <br />
          <br />

          {this.state.noticeToEdit && <Dropdown overlay={this.createNoticeIconMenu} value={this.state.noticeToEdit.notice_icon}>
            <a className="ant-dropdown-link" href="#">
              Select an icon to be shown with this notice <Icon type="down" />
            </a>
          </Dropdown>}

          <br />
          <br />

      Dismiss this notice once it has been seen?
        &emsp;

      {this.state.noticeToEdit && <Switch
            checkedChildren={<Icon type="check" />}
            unCheckedChildren={<Icon type="close" />}
            defaultChecked
            value={this.state.noticeToEdit.notice_persist}
          />}

          <br />
          <br />

      Apply a schedule to this notice
&emsp;
    {this.state.noticeToEdit && <Switch
            checkedChildren={<Icon type="check" />}
            unCheckedChildren={<Icon type="close" />}
            defaultChecked
            value={this.state.noticeToEdit.notice_scheduled}
          />}
          <br />
          <br />

          <div>
            Select a start and end date for this notice
        <br />
            <br />
            <RangePicker
              ranges={{
                Today: [moment(), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
              }}
              onChange={this.onDatesChange}
            />

          </div>


          <br />

  Should this notice appear on the website. (If this is not a scheduled notice then it will appear straight away. Otherwise it will appear on the selected start date).

&emsp;
    {this.state.noticeToEdit && <Switch
            checkedChildren={<Icon type="check" />}
            unCheckedChildren={<Icon type="close" />}
            defaultChecked
            value={this.state.noticeToEdit.active}
          />}


          <br />
          <br />
          {this.state.noticeToEdit && <TextArea rows={4} value={this.state.noticeToEdit.notice_body} />}

          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }}
          >
            <Button
              style={{
                marginRight: 8,
              }}
              onClick={this.onCloseEditNoticeDrawer}>
              Cancel
        </Button>

            {!this.state.committed && <Button onClick={this.onCommitNewNotice} type="primary">  Save  </Button>}
            {this.state.commiting && <Button disabled={true} type="primary" >
              Saving ...
       <ScaleLoader
                // css={{}}
                sizeUnit={"px"}
                size={40}
                color={'#176599'}
              />
            </Button>}
            {this.state.committed && <Button onClick={this.onCloseEditNoticeDrawer} type="primary"> Close </Button>}


          </div>

          <Drawer
            title="Confirm Deletion"
            width={400}
            closable={false}
            onClose={this.onCloseDeleteconfirmationDrawer}
            visible={this.state.onS}
          >
            Are you sure your want to delete {this.state.noticeToEdit && <b>{this.state.noticeToEdit.notice_name}</b>}?
            <br /><br />

            {!this.state.deleted && <Button type="primary" onClick={this.onDeleteNotice} style={{ border: 'darkred', color: 'darkred' }} disabled={this.state.deleting}>
              {!this.state.deleted && !this.state.deleting && <>Yes, delete {this.state.noticeToEdit && this.state.noticeToEdit.notice_name} </>}
              {this.state.deleting && <>Deleting  <Icon type="loading" color="#52c41a" /> </>}
            </Button>
            }

            <br /><br />

            {!this.state.deleted && <Button type="primary" disabled={this.state.deleting} onClick={this.onCloseDeleteconfirmationDrawer}>No, cancel</Button>}


            {this.state.deleted && <Button onClick={this.onDeleteComplete} type="success"> Finish </Button>}

          </Drawer>

        </Drawer>



        <BlankHeader pageTitle={"Notices"} actions={[
          {
            icon: faPlusCircle,
            iconColour: 'green',
            title: 'Create',
            cb: this.onShowCreateNotice,
            forUserRoles: ['admin']
          }
        ]} />




        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
               
                {this.state.loading && <div className='sweet-loading text-center'>
                  <ScaleLoader
                    // css={{}}
                    sizeUnit={"px"}
                    size={60}
                    color={'#176599'}
                  />
                </div>}
                {!this.state.loading && <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Title <i>(shown)</i></th>
                      <th scope="col">Body <i>(shown)</i></th>
                      <th scope="col">Status <i></i></th>
                      <th scope="col"> </th>

                    </tr>
                  </thead>
                  <tbody>

                    {this.state.notices.map((item, i) =>
                      <tr>
                        <th scope="row">


                          <Badge>
                            {item.notice_name}
                          </Badge>

                        </th>


                        <td>
                          <div className="d-flex align-items-center">

                            <div>
                              {item.notice_title}
                            </div>
                          </div>
                        </td>
                        <td style={{ maxWidth: '275px' }}>
                          <div className="d-flex align-items-center">
                            <Truncate lines={1} ellipsis={<span>...</span>}>
                              {item.notice_body}
                            </Truncate>

                          </div>
                        </td>
                        <td className="text-right">
                          {/* // is enabled */}
                          {item.notice_active && <Icon type="smile" style={{ fontSize: '14pt' }} theme="twoTone" twoToneColor="#52c41a" />}
                          &emsp;
                      {/* // is not enabled                       */}
                          {!item.notice_active && <Icon type="meh" style={{ fontSize: '14pt' }} theme="twoTone" twoToneColor="#52c41a" />}
                          &emsp;
                          {/* // is scheduled */}
                          {item.notice_scheduled && <Icon type="carry-out" style={{ fontSize: '14pt' }} theme="twoTone" twoToneColor="#52c41a" />}
                          &emsp;

                          {/* // is not scheduled */}
                          {!item.notice_scheduled && <Icon type="alert" style={{ fontSize: '14pt' }} />}

                          {/* // is persist */}
                          &emsp;
                           {item.notice_persist && <Icon type="pushpin" style={{ fontSize: '14pt' }} theme="twoTone" twoToneColor="#52c41a" />}

                          {/* // is not persist */}
                          &emsp;
                          {!item.notice_persist && <Icon type="pushpin" style={{ fontSize: '14pt' }} />}

                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={() => { this.onShowEditNoticeDrawer(item) }}
                            >
                              <Icon type="tool" theme="twoTone" style={{ fontSize: '14pt' }} color="#1890ff" />

                            </DropdownToggle>

                          </UncontrolledDropdown>
                        </td>

                      </tr>
                    )
                    }


                  </tbody>
                </Table>}
                <CardFooter className="py-4">
                  {/* <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className="disabled">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className="active">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          2 <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          3
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav> */}
                </CardFooter>
              </Card>
            </div>
          </Row>

        </Container>
      </>
    );
  }
}

export default Notices;
