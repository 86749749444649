/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip
} from "reactstrap";

import reqwest from 'reqwest';

// core components
import BlankHeader from "components/Headers/BlankHeader.jsx";


import { Drawer, Form, Input, message, Upload, Select, DatePicker, Icon, Switch, Menu, Dropdown, notification } from 'antd';

import { ScaleLoader } from 'react-spinners';
import BSCCE from '../../apis/BSCCEApi';

import '../../../node_modules/antd/lib/drawer/style/css';
import '../../../node_modules/antd/lib/date-picker/style/css';
import '../../../node_modules/antd/lib/switch/style/css';
import '../../../node_modules/antd/lib/dropdown/style/css';
import '../../../node_modules/antd/lib/badge/style/css';
import '../../../node_modules/antd/lib/message/style/css';
import '../../../node_modules/antd/lib/notification/style/css';

import moment from 'moment';
import CreateWebsiteCopyComponent from "../WebsiteCopy/CreateWebsiteCopyComponent";
import EditWebsiteCopyComponent from "../WebsiteCopy/EditWebsiteCopyComponent";
import DeleteWebsiteCopyComponent from "../WebsiteCopy/DeleteWebsiteCopyComponent";
import ListWebsiteCopyComponent from "../WebsiteCopy/ListWebsiteCopyComponent";

import { connect } from 'react-redux';
import { faPlusCircle, faSync } from "@fortawesome/free-solid-svg-icons";

const mapStateToProps = (state) => {
  return {
    currentUser: state.user,
    tokens: state.tokens
  }
}

const { RangePicker } = DatePicker;
const { TextArea } = Input;

class WebsiteCopy extends React.Component {

  constructor(props) {
    super(props);

  }

  componentWillMount() {
    BSCCE.constants.getConstants()
      .then(res => {
        // this.setState({ Constantss: res.data.data, loading: false });

        let categories = [];

        res.data.data.forEach ((constant, i) => {
          if (categories.indexOf (constant.category) == -1) {
            categories.push (constant.category);
          }
        })

        this.setState({
          mut: {
            ...this.state.mut,
            constants: res.data.data,
            categoryList: categories
          },
          sem: {
            ...this.state.sem,
            loading: false
          }
        })
        console.log(res.data.data);
      });
  }

  state = {
    categoryList: [],
    toggles: {
      showCreateConstantsDrawer: false,
      showDeleteConstantsDrawer: false,
      showEditConstantsDrawer: false
    },
    // semephores
    sem: {
      deleting: false,
      deleted: false,
      committing: false,
      committed: false,
      uploading: false,
      uploaded: false,
      loading: true
    },
    // permissions
    perms: {
      canCreateConstants: true,
      canDeleteConstants: true,
      canEditConstants: true
    },
    // mutable
    mut: {
      constantToCreate: {},
      focusedConstant: {},
      fileList: [],
      constants: []
    }
  }
  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description
    });
  };
  actions = {
    list: {
      onSelectConstantsFromList: (item) => {
        this.setState({
          mut: {
            ...this.state.mut,
            focusedConstant: item
          }
        });
      }, refresh: () => {

        this.setState({
          sem: {
            ...this.state.sem,
            loading: true
          }
        })

   
        BSCCE.constants.getConstants()
          .then(res => {

            let categories = [];
            res.data.data.forEach ((constant, i) => {
              if (categories.indexOf (constant.category) == -1) {
              console.log (constant);

                categories.push (constant.category);
              }
            })

            console.log ('categories ', categories);

            this.setState({

              mut: {
                ...this.state.mut,
                constants: res.data.data,
                categoryList: categories

              },
              sem: {
                ...this.state.sem,
                loading: false
              }

            });

          })
      }
    },
    create: {
      onShowUploadConstantsDrawer: (e) => {

        this.setState({
          toggles: {
            ...this.state.toggles,
            showCreateConstantsDrawer: true
          }
        })
      },
      onCloseCreateConstantsDrawer: (e) => {
        this.setState({
          toggles: {
            ...this.state.toggles,
            showCreateConstantsDrawer: false
          }
        });
      },
      onCompleteCreateConstants: (e) => {
        this.setState({
          mut: {
            ...this.state.mut,
            constants: [...this.state.mut.constants, e]
          },
          toggles: {
            ...this.state.toggles,
            showCreateConstantsDrawer: false
          }
        })
        this.openNotificationWithIcon(
          'success',
          'Created successfully',
          `${e.name} was created successfully.`
          // `${this.state.mut.focusedConstant.constant_name} has now been deleted.`
        );

      }
    },
    edit: {
      onShowEditConstantsDrawer: (item) => {
        console.log('edit', item);
        this.setState({
          toggles: {
            ...this.state.toggles,
            showEditConstantsDrawer: true,
          },
          mut: {
            ...this.state.mut,
            focusedConstant: item
          }
        })

      },
      onCompleteUpdateConstants: (e) => {
        this.setState({
          mut: {
            ...this.state.mut,
            constants: this.state.constants.map(constant => {
              if (constant._id == e._id) return e;
            })
          }
        })
      },
      onCloseEditConstantsDrawer: (e) => {

        this.setState({
          toggles: {
            ...this.state.toggles,
            showEditConstantsDrawer: false
          },
          mut: {
            ...this.state.mut,
            focusedConstant: null
          }
        })

      },
    },

    delete: {
      onshowDeleteConstantsDrawer: (e) => {
        console.log('delete', e);
        this.setState({
          toggles: {
            ...this.state.toggles,
            showDeleteConstantsDrawer: true
          }
        })
      },
      onCloseDeleteconfirmationDrawer: (e) => {
        this.setState({
          toggles: {
            ...this.state.toggles,
            showDeleteConstantsDrawer: false
          }
        })
      },
      onDeleteConstant: (e) => {
        console.log('delete now', this.state.mut.focusedConstant)
        this.setState({
          sem: {
            ...this.state.sem,
            deleting: true
          }
        });

        let constant_id = this.state.mut.focusedConstant._id;
        console.log('delete ', constant_id);

        BSCCE.constants.deleteConstants(constant_id)
          .then((res) => {

            if (res.data.status == 200) {
              this.setState({
                sem: {
                  deleting: false,
                  deleted: true
                },
                mut: {
                  ...this.state.mut,
                  constants: this.state.mut.constants.filter(constant => constant._id != this.state.mut.focusedConstant._id)
                }
              });

              this.actions.delete.onDeleteComplete(res.data.data);
            } else {
              // this.actions.delete.onDeleteFailed ()
              // error handler
              alert('an error occured');
              console.log(res);
            }

          })

          .catch((err) => {
            console.log('error', err);
          })




      },

      onDeleteComplete: (e) => {
        this.actions.edit.onCloseEditConstantsDrawer();
        this.actions.delete.onCloseDeleteconfirmationDrawer();

        console.log(e);

        this.openNotificationWithIcon(
          'success',
          'Delete success',
          `has now been deleted.`
          // `${this.state.mut.focusedConstant.constant_name} has now been deleted.`
        );

        this.setState({
          sem: {
            ...this.state.sem,
            deleting: false,
            deleted: false,
          },
          mut: {
            ...this.state.mut,
            focusedConstant: null
          }
        });

      }
    }
  };


  render() {

    const { uploading, fileList } = this.state;
    const props = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };

    return (

      <>
        <CreateWebsiteCopyComponent
          canCreateConstants={this.state.perms.canCreateConstants}
          showCreateConstantsDrawer={this.state.toggles.showCreateConstantsDrawer}
          constantToCreate={this.state.mut.constantToCreate}
          focusedConstant={this.state.mut.focusedConstant}
          constantCategories={this.state.mut.categoryList}
          actions={this.actions}
        />

        <DeleteWebsiteCopyComponent
          canDeleteConstants={this.state.perms.canDeleteConstants}
          showDeleteConstantsDrawer={this.state.toggles.showDeleteConstantsDrawer}
          onCloseDeleteConstantsDrawer={this.actions.delete.onCloseDeleteConstantsDrawer}
          constantToEdit={this.state.mut.focusedConstant}
          focusedConstant={this.state.mut.focusedConstant}
          actions={this.actions}
        />


        <EditWebsiteCopyComponent
          canEditConstants={this.state.perms.canEditConstants}
          showEditConstantsDrawer={this.state.toggles.showEditConstantsDrawer}
          onCloseEditConstantsDrawer={this.actions.edit.onCloseEditConstantsDrawer}
          constantToEdit={this.state.mut.focusedConstant}
          actions={this.actions}
        />

        <BlankHeader pageTitle={"Website Copy"} actions={[
          {
            icon: faPlusCircle,
            iconColour: 'green',
            title: 'Create',
            cb: this.actions.create.onShowUploadConstantsDrawer,
            forUserRoles: ['admin']
          },
          {
            icon: faSync,
            iconColour: 'blue',
            title: 'Refresh',
            cb: this.actions.list.refresh,
            forUserRoles: ['admin']
          }
        ]} />

        <ListWebsiteCopyComponent
          loading={this.state.sem.loading}
          constants={this.state.mut.constants}
          actions={this.actions}
          forUserRole={this.props.currentUser.activeRole}
        />


      </>

    );
  }
}

export default connect(mapStateToProps)(WebsiteCopy);
