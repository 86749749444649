import React from 'react'
import { Drawer, Button, Radio } from 'antd';

import '../../../../node_modules/antd/lib/drawer/style/css'
import { Row, Col } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'


const introImagePNG = require('assets/img/undraw/undraw_product_photography_91i2.png')

const RadioGroup = Radio.Group;


class ParentDashboardWelcomeIntroDrawer extends React.Component {

  constructor(props) {
    super(props);

  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onChange = e => {
    this.setState({
      placement: e.target.value,
    });
  };

  render() {
    return (
      <div>

        <Drawer
          title="The new way to engage with BrightStart ChildCare &amp; Education"
          placement={this.props.placement}
          closable={false}
          onClose={ this.props.onClose }
          visible={this.props.visible}
          height="90%"
        >
          <Row>


            <Col>
              <div class='col' style={{ maxWidth: '600px', textAlign: 'center', margin: 'auto' }}>
                <img src={introImagePNG} style={{ height: '70%', width: '70%', padding: '10px' }} />


                <h6 className="text-uppercase text-muted ls-1 mb-1">
                  Welcome to your new Parents' Portal!
                </h6>
<br />
                <p>We have introduced our Parents' Portal to enhance our engagmenets with our parents and to improve efficiency across the nursery.
                    Another benefit is a reduction on our reliance on paper. </p>
                <p>We are continuously adding new features so be sure to log in often! </p>

      <br />
                <Button onClick = {this.props.onClose} > 
                  Get Started  &emsp; <FontAwesomeIcon icon={faChevronCircleRight} />
                </Button>

              </div>
            </Col>
          </Row>

        </Drawer>
      </div>
    );
  }
}

export default ParentDashboardWelcomeIntroDrawer