import React, { Component } from 'react'

import {
    Badge,

    Card,
    CardHeader,
    CardFooter,
    Col,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip
} from "reactstrap";

import { Drawer, Checkbox, Input, DatePicker, message, Menu, Dropdown, Icon, Cascader, Select, Switch, notification } from 'antd';

import {
    Button,

} from "reactstrap";

import { ScaleLoader } from 'react-spinners';

import BSCCEApi from '../../apis/BSCCEApi';
import DEBUGGER from '../../apis/BSCCEDebug';

import 'antd/lib/cascader/style/css'
import 'antd/lib/select/style/css'

const formSVG = require('../../assets/img/undraw/undraw_add_user_ipe3.svg')

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const { Option } = Select;

const contacts = [];
for (let i = 10; i < 36; i++) {
    contacts.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}



const genderObj = [
    {
        value: 'Male',
        label: 'Male'
    },
    {
        value: 'Female',
        label: 'Female'
    },
]

const ethnicityObj = [
    {
        value: 'African',
        label: 'African',
        contacts: []
    },
    {
        value: 'Black',
        label: 'Black',
        contacts: [
            {
                value: 'Ghanaian',
                label: 'Ghanaian'
            },
            {
                value: 'Nigerian',
                label: 'Nigerian'
            },
            {
                value: 'Somali',
                label: 'Somali'
            },
            {
                value: 'Caribbean',
                label: 'Caribbean'
            },
        ]
    },
    {
        value: 'White',
        label: 'White',
        contacts: [
            {
                value: 'British',
                label: 'British'
            },
            {
                value: 'Irish',
                label: 'Irish'
            },
            {
                value: 'Irish',
                label: 'Irish'
            },
            {
                value: 'Any Other Asian Backgroun',
                label: 'Any Other Asian Backgroun'
            },
            {
                value: 'Black African',
                label: 'Black African'
            },
            {
                value: 'Black Caribbean',
                label: 'Black Caribbean'
            },
            {
                value: 'Indian',
                label: 'Indian'
            },
            {
                value: 'Black African',
                label: 'Black African'
            },
            {
                value: 'Pakistani',
                label: 'Pakistani'
            },
            {
                value: 'Eastern European',
                label: 'Eastern European'
            }, ,
            {
                value: 'Other',
                label: 'Other'
            }, ,
            {
                value: 'Western European',
                label: 'Western European'
            },

        ]
    },
    // {
    //     value: 'White',
    //     label: 'White',
    //     contacts: []
    // },
];

const ethnicityList = [
    'African',
    'Asian',
    'Albanian',
    'Any Other Black Background',
    'Any Other Ethnic Group',
    'Any Other Mixed Background',
    'Bangladeshi',

    'Black Caribbean',
    'Bosnian – Herzegovinian',
    'Chinese',
    'Croatian',
    'Greek',
    'Greek Cypriot',
    'Gypsy/ Roma',
    // 'Indian',
    // 'Information Not Yet Obtained',
    // 'Iranian',
    // 'Iraqi',
    // 'Italian',
    // 'Japanese',
    // 'Kosovan',
    // 'Kurdish',
    // 'Nepali',
    // 'Other Asian',
    // 'Other Black African',
    // 'Other Ethnic Group',
    // 'Pakistani',
    // 'Portuguese',
    // 'Serbian',
    // 'Sri Lankan Sinhalese',
    // 'Sri Lankan Tamil',
    // 'Traveller of Irish Heritage',
    // 'Turkish',
    // 'Turkish Cypriot',

];



export class EditContactsComponent extends Component {

    actions = {};

    state = {
        contactToEdit: {}
    }

    constructor(props) {
        super(props);

        this.actions = props.actions;

        this.setState({
            contactToEdit: props.contactToEdit
        });
    }


notifications = {
        keys: {
            updateContact: 'updateContact',
            createUser: 'createUser',
            isProcessing: 'isProcessing'
        },
        type: {
            overallStatus: {
                working: {
                    key: 'overallStatus',
                    duration: 0,
                    message: 'working ...',
                    description: 'This task is not yet complete'
                },
                success: {
                    key: 'overallStatus',
                    duration: 10,
                    message: 'Task Complete',
                    description: 'This task was completed successfully'
                },
                error: {
                    key: 'overallStatus',
                    duration: 10,
                    message: 'This task failed',
                    description: 'New description.'
                }
            },
            linkAnonUser: {
                pending: {
                    key: 'linkAnonUser',
                    duration: 0,
                    message: 'pending ...',
                    description: 'This task is not yet started'
                },
                working: {
                    key: 'linkAnonUser',
                    duration: 0,
                    message: 'working ...',
                    description: 'This task is not yet complete'
                },
                success: {
                    key: 'linkAnonUser',
                    duration: 10,
                    message: 'Task Complete',
                    description: 'This task was completed successfully'
                },
                error: {
                    key: 'linkAnonUser',
                    duration: 10,
                    message: 'This task failed',
                    description: 'New description.'
                }
            },
            createUser: {
                pending: {
                    key: 'createUser',
                    duration: 0,
                    message: 'Create User',
                    description: 'Waiting to create user from contact'
                },
                working: {
                    key: 'createUser',
                    duration: 0,
                    message: 'Creating User',
                    description: 'Creating user from contact.'
                },
                success: {
                    key: 'createUser',
                    duration: 10,
                    message: 'User Created',
                    description: 'Successfully created user from contact.'
                },
                error: {
                    key: 'createUser',
                    duration: 10,
                    message: 'Create user failed.',
                    description: 'New description.'
                }
            },
            updateContact: {
                pending: {
                    key: 'updateContact',
                    duration: 0,
                    message: 'Update Contact',
                    description: 'Waiting to update contact'
                },
                working: {
                    key: 'updateContact',
                    duration: 0,
                    message: 'Updating Contact',
                    description: 'Updating contact .'
                },
                success: {
                    key: 'updateContact',
                    duration: 10,
                    message: 'Contact Updated',
                    description: 'Successfully updated contact.'
                },
                error: {
                    key: 'updateContact',
                    duration: 10,
                    message: 'Update contact failed',
                    description: 'New description.'
                }
            }
        }
    }


    onFieldChange = (e) => this.setState({
        contactToEdit: {
            ...this.state.contactToEdit,
            [e.target.name]: e.target.value
        }
    });


    onDateChange = (date, dateString) => {
        console.log(date, dateString);

        this.setState({
            contactToEdit: {
                ...this.state.contactToEdit,
                dob: date
            }
        })
    }

    handleTagChange = value => {
            console.log(`selected ${value}`);

            if (!! value) {
                this.setState({
                    ...this.state,
                    contactToEdit: {
                        ...this.state.contactToEdit,
                        tags:   (value.indexOf (',') > -1) ? value.split(',').trim () : value
                    }
                })
            }
 
    }

   onSubmitEditContact = () => {

        notification.info(this.notifications.type.overallStatus.working)

        if (this.state.contactToEdit.linkAnonUser) {

            notification.info(this.notifications.type.linkAnonUser.pending)

            DEBUGGER.log('link anon user');
        }

        DEBUGGER.log('update existing contact ', this.state.contactToEdit)

        // client side validations

        let contactObj = {

            ...this.props.contactToEdit,
            ...this.state.contactToEdit
            // , gender: this.state.contactToEdit.gender ? this.state.contactToEdit.gender[0] : ''
            // , ethnicity: this.state.contactToEdit.ethnicity ? this.state.contactToEdit.ethnicity.join('-') : ''
            // , relationships: this.state.contactToEdit.guardianOf ? this.state.contactToEdit.guardianOf.map(child => {
            //     return { type: this.state.contactToEdit.guardianRelation, subjectId: child.key, isGuardian: this.state.contactToEdit.isGuardian, isEmergencyContact: this.state.contactToEdit.isEmergencyContact, isContractHolder: this.state.contactToEdit.isContractHolder }
            // }) : null
        }

        DEBUGGER.log(contactObj);

        setTimeout(() => {

            notification.warn(this.notifications.type.updateContact.working)

            console.log ('submit ', contactObj)


            BSCCEApi.contacts.putContact(contactObj)
                .then(resp => {
                    DEBUGGER.log('on after submit', resp);

                    if (resp.data.status == 200) {

                        this.setState({
                            ...this.state,
                            updatedContact: resp.data.data
                        })

                        notification.success(this.notifications.type.updateContact.success)

                        // update status steps mark complete

                        if (this.state.contactToEdit.linkAnonUser) {

                            DEBUGGER.log('create link anon user');

                            notification.warn(this.notifications.type.linkAnonUser.working)

                            let userObj = {
                                contactId: resp.data.data._id,
                                id: this.state.contactToEdit.anonUserId[0].key
                            }

                            DEBUGGER.log(userObj);

                            setTimeout(() => { 
                                BSCCEApi.users.updateUser(userObj)
                                    .then(respUser => {
                                        DEBUGGER.log('on after submit', resp);

                                        if (resp.data.status == 200) {

                                            notification.success(this.notifications.type.linkAnonUser.success)

                                            notification.success(this.notifications.type.overallStatus.success)

                                            // finished with no user created
                                            this.props.actions.onCompleteupdateContact(this.state.newContact);


                                        } else {
                                            notification.error(this.notifications.type.linkAnonUser.error)

                                            DEBUGGER.log(resp.data.data.reason);
                                        }

                                    })
                                    .catch(e => {
                                        DEBUGGER.log('on error', e);
                                    });

                            }, 800)

                        } else {

                            notification.success(this.notifications.type.overallStatus.success)

                            // finished with no user created
                            this.props.actions.onCompleteupdateContact(resp);

                        }

                    } else {

                        // could not create contact 
                        alert('error');
                        notification.error('failed to update contact', this.state.indicators.keys.updateContact, 10);
                    }

                })
                .catch(e => {
                    DEBUGGER.log('on error', e);
                })

        
        }, 800)

    }

    render() {

       
        return (
            <div>
                <Drawer
                    title={<><a>Edit </a>
                        <UncontrolledDropdown>
                            <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={() => { }}
                            >
                                {this.props.contactToEdit &&
                                    <h4>{this.props.contactToEdit.title}
                                        <Icon type="tool" theme="twoTone" style={{ fontSize: '14pt' }} color="#1890ff" />
                                    </h4>
                                }

                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem href="#pablo" >
                                    <Button style={{ color: 'darkred' }} type="primary" onClick={() => {

                                        this.actions.delete.onshowDeleteContactDrawer(this.props.contactToEdit)
                                    }}>
                                        Delete {this.props.contactToEdit && this.props.contactToEdit.title}
                                    </Button>
                                </DropdownItem>

                            </DropdownMenu>
                        </UncontrolledDropdown> </>}
                    width={520}
                    closable={false}
                    anchor="right"
                    onClose={this.actions.edit.onCloseEditContactDrawer}

                    open={this.props.canEditContact && this.props.showEditContactDrawer}
                    visible={this.props.canEditContact && this.props.showEditContactDrawer}
                >
                    {/* form */}

                    <b>First Name <i>(shown)</i></b>
                    <Input name="firstName" onChange={(e) => { this.onFieldChange(e) }} placeholder={this.props.contactToEdit.firstName} />

                    <br />
                    <br />

                    <b>Middle Name(s) <i>(shown)</i></b>
                    <Input placeholder={this.props.contactToEdit.middleNames}  name="middleNames" onChange={(e) => { this.onFieldChange(e) }} />

                    <br />
                    <br />
                    <b>Last Name <i>(shown)</i></b>
                    <Input placeholder={this.props.contactToEdit.lastName}  name="lastName" onChange={(e) => { this.onFieldChange(e) }} />

                    <br />
                    <br />

                    <b>Date of Birth <i>(shown)</i></b>
                    <br />
                    <DatePicker
                        value={this.props.contactToEdit.dob} 
                        format="DD/MM/YYYY"
                        onChange={this.onDateChange} />

                    <br />
                    <br />

                    <b>Gender <i>(shown)</i></b>

                    <br />
                    <br />


                    <Cascader options={genderObj} defaultValue={this.props.contactToEdit.gender}  onChange={(gender) => { this.setState({ contactToEdit: { ...this.state.contactToEdit, gender } }) }} />

                    <br /><br />

                    <b>Ethnicity <i>(shown)</i></b>

                    <br />
                    <br />

                    <Cascader options={ethnicityObj} defaultValue={this.props.contactToEdit.ethnicity}  onChange={(ethnicity) => { this.setState({ contactToEdit: { ...this.state.contactToEdit, ethnicity } }) }} />

                    <br /><br />

                    {/* <b>Does this contact qualify for NEG</b>
                    <br />
                    <br />

                    <Switch
                        checkedContacts={<> <a>Yes, this contact qualifies for NEG</a></>}
                        unCheckedContacts={<> <a>No, this contact does not qualify for NEG</a></>}
                        onChange={() => { this.setState({ contactToEdit: { ...this.state.contactToEdit, isNEG: !this.state.contactToEdit.isNEG } }) }}
                    />

                    <br />
                    <br />


                    <b>Is SEN?</b>

                    <br />
                    <br />

                    <Switch
                        checked={this.props.contactToEdit.isSEN} 
                        checkedContacts={<> <a>Yes, this contact has SEN needs</a></>}
                        unCheckedContacts={<> <a>No, this contact does not have SEN needs</a></>}
                        onChange={() => { this.setState({ contactToEdit: { ...this.state.contactToEdit, isSEN: !this.state.contactToEdit.isSEN } }) }}
                    /> */}

                    {/* <br />
                    <br />

                    {this.state.contactToEdit && this.state.contactToEdit.isSEN &&
                        <>

                            <b>SEN Categories</b>

                            <br />
                            <br />

                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                defaultValue={['a10', 'c12']}
                                onChange={(args) => {

                                    console.log('SEN Categories: ', args);
                                    this.setState({
                                        contactToEdit: {
                                            ...this.state.contactToEdit,
                                            SENCategories: []
                                        }
                                    });

                                }}
                            >
                                {contacts}
                            </Select>

                            <br />
                            <br />

                        </>
                    }

                    <b>Assign Guardians</b>

                    <br />
                    <br />

                    <Switch
                        checkedContacts={<> <a>Yes, assign Guardians now</a></>}
                        unCheckedContacts={<> <a>No, assign guardians later</a></>}
                        onChange={() => { this.setState({ contactToEdit: { ...this.state.contactToEdit, hasGuardians: !this.state.contactToEdit.hasGuardians } }) }}
                    />

                    <br />
                    <br /> */}
{/* 
                    {this.state.contactToEdit && this.state.contactToEdit.hasGuardians &&
                        <>
                            <b>Select Guardians</b>

                            <br />
                            <br />

                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                defaultValue={['a10', 'c12']}
                                onChange={(args) => {

                                    console.log('assign guardians: ', args);
                                    this.setState({
                                        contactToEdit: {
                                            ...this.state.contactToEdit,
                                            guardians: args
                                        }
                                    });

                                }}
                            >
                                {contacts}
                            </Select>

                            <br />
                            <br />

                        </>
                    }


                    <b>Assign Key Person</b>

                    <br />
                    <br />

                    <Switch
                        checkedContacts={<> <a>Yes, assign Key Person now</a></>}
                        unCheckedContacts={<> <a>No, assign Key Person later</a></>}
                        onChange={() => { this.setState({ contactToEdit: { ...this.state.contactToEdit, hasKeyPerson: !this.state.contactToEdit.hasKeyPerson } }) }}
                    />

                    <br />
                    <br />

                    {this.state.contactToEdit && this.state.contactToEdit.hasKeyPerson &&
                        <>
                            <b>Select Key Worker</b>

                            <br />
                            <br />

                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                onChange={(args) => {

                                    console.log('assign guardians: ', args);
                                    this.setState({
                                        contactToEdit: {
                                            ...this.state.contactToEdit,
                                            keyPerson: []
                                        }
                                    });

                                }}
                            >
                                {contacts}
                            </Select>

                            <br />
                            <br />

                        </>
                    } */}
{/* 
                    <b>Does this contact have any allergies</b>

                    <br />
                    <br />

                    <Switch
                        checkedContacts={<> <a>Yes, this contact has allergies / diatery requirements</a></>}
                        unCheckedContacts={<> <a>No, this contact does not have allergies / diatery requirements</a></>}
                        onChange={() => { this.setState({ contactToEdit: { ...this.state.contactToEdit, hasDietaryAllergies: !this.state.contactToEdit.hasDietaryAllergies } }) }}
                    />

                    <br />
                    <br /> */}

                    {/* {this.state.contactToEdit && this.state.contactToEdit.hasDietaryAllergies &&
                        <>
                            <b>Select Allergy Categories</b>

                            <br />
                            <br />

                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                labelInValue={true}
                                loading={true}
                                onChange={(args) => {

                                    console.log('allery categories: ', args);
                                    this.setState({
                                        contactToEdit: {
                                            ...this.state.contactToEdit,
                                            dietaryAllergies: []
                                        }
                                    });

                                }}
                            >
                                {contacts}
                            </Select>
                        </>
                    } */}

<br />
<br />
<b>Add Tags</b>
                    <Select mode="tags" style={{ width: '100%' }} placeholder="Tags Mode" onChange={this.handleTagChange}>
                        
                    </Select>

                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                {/* form */}

                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e8e8e8',
                        padding: '10px 16px',
                        textAlign: 'right',
                        left: 0,
                        background: '#fff',
                        borderRadius: '0 0 4px 4px',
                    }}
                >
                    <Button
                        style={{
                            marginRight: 8,
                        }}
                        onClick={this.actions.edit.onCloseEditContactDrawer}>
                        Cancel
                        </Button>

                    {!this.state.committed && <Button onClick={this.onSubmitEditContact} type="primary">  Save  </Button>}
                    {this.state.commiting && <Button disabled={true} type="primary" >
                        Saving ...
                    <ScaleLoader
                            // css={{}}
                            sizeUnit={"px"}
                            size={40}
                            color={'#176599'}
                        />
                    </Button>}
                    {this.state.committed && <Button onClick={this.actions.edit.onCloseEditContactDrawer} type="primary"> Close </Button>}


                </div>
                </Drawer>
            </div >
        )
    }
}

export default EditContactsComponent
