import React, { Component } from 'react'

import { Drawer, Checkbox, Input, DatePicker, message, Menu, Dropdown, Cascader, Select, Switch } from 'antd';

import {
    Button,

} from "reactstrap";


import { ScaleLoader } from 'react-spinners';

import BSCCEApi from '../../apis/BSCCEApi';

import '../../../node_modules/antd/lib/cascader/style/css'
import '../../../node_modules/antd/lib/select/style/css'

const formSVG = require('../../assets/img/undraw/undraw_add_user_ipe3.svg')

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const { Option } = Select;

const children = [];
for (let i = 10; i < 36; i++) {
    children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const SENDCategoriesList = [], SENDCategories = [
    "COP Stage 1", 
    "COP Stage 2",
    "COP Stage 3",
    "COP Stage 4",
    "COP Stage 5",
    "N - No Special Educational Needs",
    "A - School Action or Early Years Action",
    "P - School Action PLus or Early Years Action Plus",
    "S - Statement of SEND",
];
for (let i =  0; i < SENDCategories.length;  i++) {
    SENDCategoriesList.push(<Option key={SENDCategories[i]}>{SENDCategories[i]}</Option>);
}

const DietaryAllergiesList = [], DietaryAllergies = [
    "Apples",
    "Celery",
    "Wheat / Gluten Free",
    "Shellfish / Crustaceans",
    "Eggs",
    "Fish",
    "Halal",
    "Hayfever",
    "Kosher",
    "Lupin",
    "Milk / Dairy Free",
    "Molluscs",
    "Mustard",
    "Nuts / All",
    "Nuts / Almonds",
    "Nuts / Peanuts",
    "Nuts / Tree Nuts",
    "Sesame",
    "Soybeans",
    "Sulphur Dioxide and Sulphates",
    "Vegetarian",
    "Vegan"

]
for (let i =  0; i < DietaryAllergies.length;  i++) {
    DietaryAllergiesList.push(<Option key={DietaryAllergies[i]}>{DietaryAllergies[i]}</Option>);
}

function handleChange(value) {
    console.log(`selected ${value}`);
}

export class CreateChildComponent extends Component {

    state = {
        childToCreate: {
            firstName: null,
            lastName: null,
            isSEN: false,
            isNEG: false,

             /**
              *! fields to add to form

                *? what is the use of this email? Main Contact Parent? 
                ** email address
 
                Child
                ** Address
                ** telephone

                Mother 
                    ** occupation and work address
                    ** phone number
                Father 
                    ** occupation and work address
                    ** phone number

                does your child reside in a smoke free environment: false,
                is Seeing Speech and Language Therapist
                How many words on average does ouy child currently speak
                is your childs immunisation up to date
                 ** Diptheria
                 ** Whooping Cough
                 ** Poliomyelitis
                 ** Tatnus
                 ** Measles
                 ** MMR

                Name of dentist
                ** Address
                ** Phone number

                Name of Doctor
                ** Address
                ** Phone number

            Have any court orders been issues in respect of your child. If yes please provide the original court order 
                (upon your visit to the nursery);\

                Persons to be contacted in emergencies. Name & Address of person collecting child fro mplay group (must be different from above emegrncy contact)
                Children will only be allowed to leave with the person names. 
                ** name
                ** address
                ** phone number
                

                Name of Family Support Worker (if any)
                ** Phone Number

                Any Other Informatino Relative to this child: 
                ** toileting routine
                ** sleep patterns
                ** comforters
                ** religion practices
                

                in case of emergency, we may have to take your child to hospital. For us to act quiclly please sign the statemenet below.
                ** mother
                ** father

                I consent to any emergency medical or dental treament nevessary for my child, if authorised by the person in charge until i can attend
                ** mother
                ** father

             **/
            SENCategories: [],
            guardians: [],
            keyPersons: [],
            dietaryAllergies: [],
            dob: null,
            hasDietaryAllergies: false,
            hasGuardians: false,
            hasKeyPerson: false,
            gender: [],
            religion: [],
            ethnicity: []
        }
    };

    onCommitNewChild = (child) => {

        console.log('create new child ', this.state.childToCreate)

        message.info('creating child record ...');

        // client side validations

        let childObj = {
            ...this.state.childToCreate
            , gender: this.state.childToCreate.gender[0]
            , religion: this.state.childToCreate.religion[0]
            , ethnicity: this.state.childToCreate.ethnicity.join('-')
        }

        console.log(childObj);

        BSCCEApi.children.createChild(childObj)
            .then(resp => {
                console.log('on after submit', resp);

                if (resp.data.status == 200) {
                    message.success("Child created successfully");
                    this.props.actions.create.onCompleteCreateChild(resp);

                } else {
                    alert('error');
                    message.error(resp.data.data.reason);
                }


            })
            .catch(e => {
                console.log('on error', e);
            })

    }

    onFieldChange = (e) => this.setState({
        childToCreate: {
            ...this.state.childToCreate,
            [e.target.name]: e.target.value
        }
    });



    permissionModel = (roleName) => {
        return {
            [roleName]: {

            }
        }
    }

    onDateChange = (date, dateString) => {
        console.log(date, dateString);

        this.setState({
            childToCreate: {
                ...this.state.childToCreate,
                dob: date
            }
        })
    }

    render() {

        const genderObj = [
            {
                value: 'Male',
                label: 'Male'
            },
            {
                value: 'Female',
                label: 'Female'
            },
        ]


        const religionObj = [
            {
                value: 'Christianity',
                label: 'Christianity'
            },
            {
                value: 'Islam',
                label: 'Islam'
            },
            {
                value: 'Hinduism',
                label: 'Hinduism'
            }, ,
            {
                value: 'Judaism',
                label: 'Judaism'
            },
            {
                value: 'Jainism',
                label: 'Jainism'
            },
            {
                value: 'Humanism',
                label: 'Humanism'
            },
            {
                value: 'Atheist',
                label: 'Atheist'
            },
            {
                value: 'Agnostic',
                label: 'Agnostic'
            },
            {
                value: 'Sikhism',
                label: 'Sikhism'
            },
            {
                value: 'Bhuddism',
                label: 'Bhuddism'
            },
            {
                value: 'Other',
                label: 'Other'
            },
        ]

        const ethnicityObj = [
            {
                value: 'Afgan',
                label: 'Afgan',
                children: []
            },
            {
                value: 'African Asian',
                label: 'African Asian',
                children: []
            },
            {
                value: 'Albanian',
                label: 'Albanian',
                children: []
            },
            {
                value: 'Black',
                label: 'Black',
                children: [
                    {
                        value: 'Ghanaian',
                        label: 'Ghanaian'
                    },
                    {
                        value: 'Nigerian',
                        label: 'Nigerian'
                    },
                    {
                        value: 'Somali',
                        label: 'Somali'
                    },
                    {
                        value: 'Caribbean',
                        label: 'Caribbean'
                    },
                    {
                        value: 'African Other',
                        label: 'Other Black African Background'
                    },
                    {
                        value: 'Other',
                        label: 'Any other Black Background'
                    },
                ]
            },
            {
                value: 'Bosnian',
                label: 'Bosnian',
                children: []
            },
            {
                value: 'Chinese',
                label: 'Chinese',
                children: []
            },
            {
                value: 'Croatian',
                label: 'Croatian',
                children: []
            },
            {
                value: 'Greek',
                label: 'Greek',
                children: []
            },
            {
                value: 'Greek Cypriot',
                label: 'Greek Cypriot',
                children: []
            },
            {
                value: 'Gypsy / Roma',
                label: 'Gypsy / Roma',
                children: []
            },
            {
                value: 'Indian',
                label: 'Indian',
                children: []
            },
            {
                value: 'Information Not Yet Obtained',
                label: 'Information Not Yet Obtained',
                children: []
            },
            {
                value: 'Iranian',
                label: 'Iranian',
                children: []
            },
            {
                value: 'Iraqi',
                label: 'Iraqi',
                children: []
            },
            {
                value: 'Italian',
                label: 'Italian',
                children: []
            },
            {
                value: 'Italian',
                label: 'Italian',
                children: []
            },
            {
                value: 'Japanese',
                label: 'Japanese',
                children: []
            },
            {
                value: 'Kosovan',
                label: 'Kosovan',
                children: []
            },
            {
                value: 'Kurdish',
                label: 'Kurdish',
                children: []
            },
            {
                value: 'Nepali',
                label: 'Nepali',
                children: []
            },
            {
                value: 'Asian Other',
                label: 'Asian Other',
                children: []
            },
            {
                value: 'Other',
                label: 'Other',
                children: []
            },
            {
                value: 'Pakistani',
                label: 'Pakistani',
                children: []
            },
            {
                value: 'Portuguese',
                label: 'Portuguese',
                children: []
            },
            {
                value: 'Serbian',
                label: 'Serbian',
                children: []
            },
            {
                value: 'Sri Lankan',
                label: 'Sri Lankan',
                children: [

                    {
                        value: 'Singalese',
                        label: 'Singalese',
                        children: []
                    }, {
                        value: 'Tamil',
                        label: 'Tamil',
                        children: []
                    },
                ]
            }, {
                value: 'Traveller of Iritsh Heritage',
                label: 'Traveller of Iritsh Heritage',
                children: []
            }, {
                value: 'Turkish',
                label: 'Turkish',
                children: []
            }, {
                value: 'Turkish Cypriot',
                label: 'Turkish Cypriot',
                children: []
            },

            {
                value: 'White',
                label: 'White',
                children: [
                    {
                        value: 'British',
                        label: 'British'
                    },
                    {
                        value: 'Irish',
                        label: 'Irish'
                    },
                    {
                        value: 'Any Other Asian Background',
                        label: 'Any Other Asian Background'
                    },
                    {
                        value: 'Black African',
                        label: 'Black African'
                    },
                    {
                        value: 'Black Caribbean',
                        label: 'Black Caribbean'
                    },
                    {
                        value: 'Indian',
                        label: 'Indian'
                    },
                    {
                        value: 'Black African',
                        label: 'Black African'
                    },
                    {
                        value: 'Pakistani',
                        label: 'Pakistani'
                    },
                    {
                        value: 'Eastern European',
                        label: 'Eastern European'
                    }, ,
                    {
                        value: 'Other',
                        label: 'Other'
                    }, ,
                    {
                        value: 'Western European',
                        label: 'Western European'
                    },

                ]
            },
            {
                value: 'Any Other Ethnic Group',
                label: 'Any Other Ethnic Group',
                children: []
            },
            {
                value: 'Any Other Mixed Background',
                label: 'Any Other Mixed Background',
                children: []
            },
        ];

        return (
            <>
                <Drawer
                    title="Enlist a new child"
                    width={520}
                    closable={false}
                    anchor="right"
                    onClose={this.props.actions.onCloseCreateChildDrawer}
                    // open={this.state.canCreateChild && this.state.showCreateChildDrawer}
                    // visible={this.state.canCreateChild && this.state.showCreateChildDrawer}
                    open={this.props.canCreateChild && this.props.showCreateChildDrawer}
                    visible={this.props.canCreateChild && this.props.showCreateChildDrawer}
                >

                    <img src={formSVG} height="100" width="200" />

                    <br />
                    <br />

                    <b>First Name <i>(required)</i></b>
                    <Input name="firstName" onChange={(e) => { this.onFieldChange(e) }} placeholder="e.g., Alicia" />

                    <br />
                    <br />

                    <b>Middle Name(s) <i>(shown)</i></b>
                    <Input placeholder="e.g., Marie" name="middleNames" onChange={(e) => { this.onFieldChange(e) }} />

                    <br />
                    <br />
                    <b>Last Name <i>(required)</i></b>
                    <Input placeholder="e.g., Taylor" name="lastName" onChange={(e) => { this.onFieldChange(e) }} />

                    <br />
                    <br />

                    <b>Date of Birth <i>(required)</i></b>
                    <br />
                    <DatePicker
                        format="DD/MM/YYYY"
                        onChange={this.onDateChange} />

                    <br />
                    <br />

                    <b>Gender <i>(required)</i></b>

                    <br />
                    <br />


                    <Cascader options={genderObj} onChange={(gender) => { this.setState({ childToCreate: { ...this.state.childToCreate, gender } }) }} />

                    <br /><br />

                    <b>Religion </b>

                    <br />
                    <br />


                    <Cascader options={religionObj} onChange={(religion) => { this.setState({ childToCreate: { ...this.state.childToCreate, religion } }) }} />

                    <br /><br />

                    <b>Ethnicity <i>(required)</i></b>

                    <br />
                    <br />

                    <Cascader options={ethnicityObj} onChange={(ethnicity) => { this.setState({ childToCreate: { ...this.state.childToCreate, ethnicity } }) }} />

                    <br /><br />

                    <b>Does this child qualify for NEG</b>
                    <br />
                    <br />

                    <Switch
                        checkedChildren={<> <a>Yes, this child qualifies for NEG</a></>}
                        unCheckedChildren={<> <a>No, this child does not qualify for NEG</a></>}
                        onChange={() => { this.setState({ childToCreate: { ...this.state.childToCreate, isNEG: !this.state.childToCreate.isNEG } }) }}
                    />

                    <br />
                    <br />


                    <b>Is SEND?</b>

                    <br />
                    <br />

                    <Switch
                        checkedChildren={<> <a>Yes, this child has SEND needs</a></>}
                        unCheckedChildren={<> <a>No, this child does not have SEND needs</a></>}
                        onChange={() => { this.setState({ childToCreate: { ...this.state.childToCreate, isSEN: !this.state.childToCreate.isSEN } }) }}
                    />

                    <br />
                    <br />

                    {this.state.childToCreate && this.state.childToCreate.isSEN &&
                        <>

                            <b>SEND Categories</b>

                            <br />
                            <br />

                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                // defaultValue={[""]}
                                onChange={(args) => {

                                    console.log('SEN Categories: ', args);
                                    this.setState({
                                        childToCreate: {
                                            ...this.state.childToCreate,
                                            SENCategories: [...args]
                                        }
                                    });

                                }}
                            >
                                {SENDCategoriesList}
                            </Select>

                            <br />
                            <br />

                        </>
                    }

                    {/* <b>Assign Guardians</b>

                    <br />
                    <br />

                    <Switch
                        checkedChildren={<> <a>Yes, assign Guardians now</a></>}
                        unCheckedChildren={<> <a>No, assign guardians later</a></>}
                        onChange={() => { this.setState({ childToCreate: { ...this.state.childToCreate, hasGuardians: !this.state.childToCreate.hasGuardians } }) }}
                    />

                    <br />
                    <br />

                    {this.state.childToCreate && this.state.childToCreate.hasGuardians &&
                        <>
                            <b>Select Guardians</b>

                            <br />
                            <br />

                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                defaultValue={['a10', 'c12']}
                                onChange={(args) => {

                                    console.log('assign guardians: ', args);
                                    this.setState({
                                        childToCreate: {
                                            ...this.state.childToCreate,
                                            guardians: args
                                        }
                                    });

                                }}
                            >
                                {children}
                            </Select>

                            <br />
                            <br />

                        </>
                    } */}


                    {/* <b>Assign Key Person</b>

                    <br />
                    <br />

                    <Switch
                        checkedChildren={<> <a>Yes, assign Key Person now</a></>}
                        unCheckedChildren={<> <a>No, assign Key Person later</a></>}
                        onChange={() => { this.setState({ childToCreate: { ...this.state.childToCreate, hasKeyPerson: !this.state.childToCreate.hasKeyPerson } }) }}
                    />

                    <br />
                    <br />

                    {this.state.childToCreate && this.state.childToCreate.hasKeyPerson &&
                        <>
                            <b>Select Key Worker</b>

                            <br />
                            <br />

                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                onChange={(args) => {

                                    console.log('assign guardians: ', args);
                                    this.setState({
                                        childToCreate: {
                                            ...this.state.childToCreate,
                                            keyPerson: []
                                        }
                                    });

                                }}
                            >
                                {children}
                            </Select>

                            <br />
                            <br />

                        </>
                    } */}

                    <b>Does this child have any allergies / dietary requirements?</b>

                    <br />
                    <br />

                    <Switch
                        checkedChildren={<> <a>Yes, this child has allergies / dietary requirements</a></>}
                        unCheckedChildren={<> <a>No, this child does not have allergies / dietary requirements</a></>}
                        onChange={() => { this.setState({ childToCreate: { ...this.state.childToCreate, hasDietaryAllergies: !this.state.childToCreate.hasDietaryAllergies } }) }}
                    />

                    <br />
                    <br />

                    {this.state.childToCreate && this.state.childToCreate.hasDietaryAllergies &&
                        <>
                            <b>Select Allergy / Dietary Categories</b>

                            <br />
                            <br />

                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                onChange={(args) => {

                                    console.log('allery categories: ', args);
                                    this.setState({
                                        childToCreate: {
                                            ...this.state.childToCreate,
                                            dietaryAllergies: [...args]
                                        }
                                    });

                                }}
                            >
                                {DietaryAllergiesList}
                            </Select>
                        </>
                    }

                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                    {/* <b>Roles <i>(shown)</i></b>
                    <p>This determines what functionality will be available to the child and will change layout of the portal depending on the content being shown to this child. </p>
                    < br />

                    <Checkbox
                        checked={this.state.childToCreate.isParent}
                        // disabled={(this.state.childToCreate.isStaff || this.state.childToCreate.isAdmin)}
                        onChange={(e) => {
                            this.setState({
                                childToCreate: {
                                    ...this.state.childToCreate,
                                    isParent: !this.state.childToCreate.isParent
                                }
                            });
                        }}
                    >
                        {' '} Parent
                    </Checkbox>

                    <br />

                    <Checkbox
                        checked={this.state.childToCreate.isStaff}
                        // disabled={(this.state.childToCreate.isAdmin || this.state.childToCreate.isParent)}
                        onChange={(e) => {
                            this.setState({
                                childToCreate: {
                                    ...this.state.childToCreate,
                                    isStaff: !this.state.childToCreate.isStaff
                                }
                            });
                        }}
                    >
                        {' '} Staff
                    </Checkbox>

                    <br />

                    <Checkbox
                        checked={this.state.childToCreate.isAdmin}
                        // disabled={(this.state.childToCreate.isParent || this.state.childToCreate.isStaff)}
                        onChange={(e) => {
                            this.setState({
                                childToCreate: {
                                    ...this.state.childToCreate,
                                    isAdmin: !this.state.childToCreate.isAdmin
                                }
                            });
                        }}
                    >
                        {' '} Admin
                    </Checkbox> */}

                    <br />
                    <br />

                    {/* <b>Mark this as the current Child?</b>
                    <br />
                    <br />
                    <Switch
                        name='isCurrentChild'
                        onChange={() => {
                            this.setState({
                                ChildToCreateVars: {
                                    ...this.state.ChildToCreateVars,
                                    isCurrentChild: !this.state.ChildToCreate.isCurrentChild
                                }
                            })
                        }}
                        checkedChildren={
                            <>
                                <a>&emsp;Will be shown as the current Child dates on the website &nbsp;</a>
                            </>
                        }
                        unCheckedChildren={
                            <>

                                <a>&emsp;Will not be shown as the current Child on the website &emsp;</a>
                            </>
                        }
                        defaultChecked
                    /> */}

                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                        }}
                    >
                        {!this.state.committed && <Button style={{
                            marginRight: 8,
                        }} disabled={this.state.committing} onClick={this.props.actions.onCloseCreateChildDrawer} >Cancel</Button>}
                        <>

                            {!this.state.committed &&
                                <Button
                                    type="primary"
                                    onClick={this.onCommitNewChild}
                                    disabled={this.state.props}
                                    loading={this.state.committing}
                                    style={{ marginTop: 16 }}
                                >

                                    {!this.state.committed && !this.state.committing && 'Publish'}

                                </Button>
                            }


                            {/* <Button onClick={this.onCommitNewNotice} disabled={this.state.committing} type="primary">

                                {!this.state.committed && !this.state.committing && 'Publish'}

                                {this.state.committing && <>Publishing  <Icon type="loading" color="#52c41a" /> </>}

                            </Button> */}


                            {this.state.committed && <Button onClick={this.actions.onCloseCreateChildDrawer} type="success"> Finish </Button>}

                        </>

                    </div>
                </Drawer>   </>
        )
    }
}

export default CreateChildComponent
