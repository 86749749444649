import React, { Component } from 'react'

import debounce from 'lodash/debounce';
import { Drawer, Checkbox, Input, DatePicker, message, notification, Menu, Modal, Cascader, Select, Switch, Spin } from 'antd';

import {
    Button,

} from "reactstrap";


import { ScaleLoader } from 'react-spinners';

import BSCCEApi from '../../apis/BSCCEApi';
import DEBUGGER from '../../apis/BSCCEDebug';


import 'antd/lib/cascader/style/css'
import 'antd/lib/select/style/css'

const formSVG = require('../../assets/img/undraw/undraw_add_user_ipe3.svg')

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const { Option } = Select;

const contacts = [];
for (let i = 10; i < 36; i++) {
    contacts.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}
const guardianRelationships = [];
const guardianRelationshipsOptions = ['Select ...', 'Birth Mother', 'Birth Father', 'Mother by law (Adopted)', 'Father by law (Adtoped)', 'Guardian / Foster Parent', 'Case Worker', 'Grandmother', 'Grandfather', 'Aunty', 'Uncle', 'Brother', 'Sister', 'Trusted Family Friend', 'Other'];

for (let j = 0; j < guardianRelationshipsOptions.length; j++) {
    guardianRelationships.push(<Option key={guardianRelationshipsOptions[j]}>{guardianRelationshipsOptions[j]}</Option>);
}
function handleChange(value) {
    DEBUGGER.log(`selected ${value}`);
}

const contactTypeObj = [

    {
        value: 'Internal',
        label: 'Internal',
        children: [
            {
                value: 'Staff',
                label: 'Staff',
            },
            {
                value: 'Student',
                label: 'Student',
            },
            {
                value: 'Volunteer',
                label: 'Volunteer',
            },
            {
                value: 'Other',
                label: 'Other',
            }
        ]
    },
    {
        value: 'External',
        label: 'External',
        children: [
            {
                value: 'Doctor',
                label: 'Doctor',
            },
            {
                value: 'Dentist',
                label: 'Dentist',
            },
            {
                value: 'Support Worker',
                label: 'Support Worker',
            },
            {
                value: 'Local Authority Worker',
                label: 'Local Authority Worker',
            },
            {
                value: "Other",
                label: "Other"
            }
        ]
    }, {
        value: 'Customer',
        label: 'Customer',
        isLeaf: true
    }


];



export class CreateContactComponent extends Component {

    state = {
        contactToCreate: {
            firstName: null,
            middleNames: null,
            lastName: false,
            email: null,
            addressLine1: null,
            addressLine2: null,
            addressTown: null,
            addressPostcode: null,
            phone: null,
            dob: null,
            gender: null,
            ethnicity: null,
            isGuardian: false,
            guardianOf: [],
            relationships: [],
            addresses: [],
            isContractHolder: false,
            createPortalUser: false
        },
        userToCreate: {
            email: '',
            roles: '',
            password: null,
            isStaff: null,
            isParent: null,
            isAdmin: null,
            contactId: null,
        },
        newUser: {},
        newContact: {}


    };

    notifications = {
        keys: {
            createContact: 'createContact',
            createUser: 'createUser',
            isProcessing: 'isProcessing'
        },
        type: {
            overallStatus: {
                working: {
                    key: 'overallStatus',
                    duration: 0,
                    message: 'working ...',
                    description: 'This task is not yet complete'
                },
                success: {
                    key: 'overallStatus',
                    duration: 10,
                    message: 'Task Complete',
                    description: 'This task was completed successfully'
                },
                error: {
                    key: 'overallStatus',
                    duration: 10,
                    message: 'This task failed',
                    description: 'New description.'
                }
            },
            linkAnonUser: {
                pending: {
                    key: 'linkAnonUser',
                    duration: 0,
                    message: 'pending ...',
                    description: 'This task is not yet started'
                },
                working: {
                    key: 'linkAnonUser',
                    duration: 0,
                    message: 'working ...',
                    description: 'This task is not yet complete'
                },
                success: {
                    key: 'linkAnonUser',
                    duration: 10,
                    message: 'Task Complete',
                    description: 'This task was completed successfully'
                },
                error: {
                    key: 'linkAnonUser',
                    duration: 10,
                    message: 'This task failed',
                    description: 'New description.'
                }
            },
            createUser: {
                pending: {
                    key: 'createUser',
                    duration: 0,
                    message: 'Create User',
                    description: 'Waiting to create user from contact'
                },
                working: {
                    key: 'createUser',
                    duration: 0,
                    message: 'Creating User',
                    description: 'Creating user from contact.'
                },
                success: {
                    key: 'createUser',
                    duration: 10,
                    message: 'User Created',
                    description: 'Successfully created user from contact.'
                },
                error: {
                    key: 'createUser',
                    duration: 10,
                    message: 'Create user failed.',
                    description: 'New description.'
                }
            },
            createUser: {
                pending: {
                    key: 'createUser',
                    duration: 0,
                    message: 'Create User',
                    description: 'Waiting to create user from contact'
                },
                working: {
                    key: 'createUser',
                    duration: 0,
                    message: 'Creating User',
                    description: 'Creating user from contact.'
                },
                success: {
                    key: 'createUser',
                    duration: 10,
                    message: 'User Created',
                    description: 'Successfully created user from contact.'
                },
                error: {
                    key: 'createUser',
                    duration: 10,
                    message: 'Create user failed.',
                    description: 'New description.'
                }
            },
            createContact: {
                pending: {
                    key: 'createContact',
                    duration: 0,
                    message: 'Create Contact',
                    description: 'Waiting to create contact'
                },
                working: {
                    key: 'createContact',
                    duration: 0,
                    message: 'Creating Contact',
                    description: 'Creating contact .'
                },
                success: {
                    key: 'createContact',
                    duration: 10,
                    message: 'Contact Created',
                    description: 'Successfully created contact.'
                },
                error: {
                    key: 'createContact',
                    duration: 10,
                    message: 'Create contact failed',
                    description: 'New description.'
                }
            }
        }
    }

    onCommitNewContact = (contact) => {

        notification.info(this.notifications.type.overallStatus.working)

        notification.info(this.notifications.type.createContact.pending)

        if (this.state.contactToCreate.createPortalUser) {

            notification.info(this.notifications.type.createUser.pending)

            DEBUGGER.log('show create user');
        }

        if (this.state.contactToCreate.linkAnonUser) {

            notification.info(this.notifications.type.linkAnonUser.pending)

            DEBUGGER.log('link anon user');
        }


        DEBUGGER.log('create new contact ', this.state.contactToCreate)

        // client side validations

        let contactObj = {
            ...this.state.contactToCreate
            , gender: this.state.contactToCreate.gender ? this.state.contactToCreate.gender[0] : ''
            , ethnicity: this.state.contactToCreate.ethnicity ? this.state.contactToCreate.ethnicity.join('-') : ''
            , relationships: this.state.contactToCreate.guardianOf ? this.state.contactToCreate.guardianOf.map(child => {
                return { type: this.state.contactToCreate.guardianRelation, subjectId: child.key, isGuardian: this.state.contactToCreate.isGuardian, isEmergencyContact: this.state.contactToCreate.isEmergencyContact, isContractHolder: this.state.contactToCreate.isContractHolder }
            }) : null
        }

        DEBUGGER.log(contactObj);

        setTimeout(() => {

            notification.warn(this.notifications.type.createContact.working)

            BSCCEApi.contacts.createContact(contactObj)
                .then(resp => {
                    DEBUGGER.log('on after submit', resp);

                    if (resp.data.status == 200) {

                        this.setState({
                            ...this.state,
                            newContact: resp.data.data
                        })

                        notification.success(this.notifications.type.createContact.success)

                        // update status steps mark complete

                        if (this.state.contactToCreate.createPortalUser) {

                            DEBUGGER.log('create user login');

                            notification.warn(this.notifications.type.createUser.working)


                            let userObj = {
                                email: resp.data.data.email,
                                recoveryEmail: resp.data.data.email,
                                firstName: resp.data.data.firstName,
                                lastName: resp.data.data.lastName,
                                password: this.generateRandomPassword(),
                                contactId: resp.data.data._id,
                            }

                            userObj.roles = [];

                            if (this.state.contactToCreate.isParent) userObj.roles.push({ name: 'parent' });
                            if (this.state.contactToCreate.isAdmin) userObj.roles.push({ name: 'admin' });
                            if (this.state.contactToCreate.isStaff) userObj.roles.push({ name: 'staff' });

                            DEBUGGER.log(userObj);

                            setTimeout(() => {

                                BSCCEApi.users.createUser(userObj)
                                    .then(respUser => {
                                        DEBUGGER.log('on after submit', resp);

                                        if (resp.data.status == 200) {

                                            notification.success(this.notifications.type.createUser.success)

                                            notification.success(this.notifications.type.overallStatus.success)

                                            // finished with no user created
                                            this.props.actions.onCompleteCreateContact(this.state.newContact);


                                        } else {
                                            notification.error(this.notifications.type.createUser.error)

                                            DEBUGGER.log(resp.data.data.reason);
                                        }

                                    })
                                    .catch(e => {
                                        DEBUGGER.log('on error', e);
                                    });

                            }, 800)


                        } else if (this.state.contactToCreate.linkAnonUser) {

                            DEBUGGER.log('create link anon user');

                            notification.warn(this.notifications.type.linkAnonUser.working)

                            let userObj = {
                                contactId: resp.data.data._id,
                                id: this.state.contactToCreate.anonUserId[0].key
                            }

                            DEBUGGER.log(userObj);

                            setTimeout(() => {

                                BSCCEApi.users.updateUser(userObj)
                                    .then(respUser => {
                                        DEBUGGER.log('on after submit', resp);

                                        if (resp.data.status == 200) {

                                            notification.success(this.notifications.type.linkAnonUser.success)

                                            notification.success(this.notifications.type.overallStatus.success)

                                            // finished with no user created
                                            this.props.actions.onCompleteCreateContact(this.state.newContact);


                                        } else {
                                            notification.error(this.notifications.type.linkAnonUser.error)

                                            DEBUGGER.log(resp.data.data.reason);
                                        }

                                    })
                                    .catch(e => {
                                        DEBUGGER.log('on error', e);
                                    });

                            }, 800)

                        } else {

                            notification.success(this.notifications.type.overallStatus.success)

                            // finished with no user created
                            this.props.actions.onCompleteCreateContact(resp);

                        }




                    } else {

                        // could not create contact 
                        alert('error');
                        notification.error('failed to create contact', this.state.indicators.keys.createContact, 10);
                    }


                })
                .catch(e => {
                    DEBUGGER.log('on error', e);
                })
        }, 800)



    }

    onFieldChange = (e) => this.setState({
        contactToCreate: {
            ...this.state.contactToCreate,
            [e.target.name]: e.target.value
        }
    });


    generateRandomPassword() {
        var chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
        var pass = "";
        var length = 20;
        for (var x = 0; x < length; x++) {
            var i = Math.floor(Math.random() * chars.length);
            pass += chars.charAt(i);
        }
        return pass;

    }



    permissionModel = (roleName) => {
        return {
            [roleName]: {

            }
        }
    }

    onDateChange = (date, dateString) => {
        DEBUGGER.log(date, dateString);

        this.setState({
            contactToCreate: {
                ...this.state.contactToCreate,
                dob: date
            }
        })
    }

    render() {

        const genderObj = [
            {
                value: 'Male',
                label: 'Male'
            },
            {
                value: 'Female',
                label: 'Female'
            },
        ]


        const religionObj = [
            {
                value: 'Christianity',
                label: 'Christianity'
            },
            {
                value: 'Islam',
                label: 'Islam'
            },
            {
                value: 'Hinduism',
                label: 'Hinduism'
            }, ,
            {
                value: 'Judaism',
                label: 'Judaism'
            },
            {
                value: 'Jainism',
                label: 'Jainism'
            },
            {
                value: 'Humanism',
                label: 'Humanism'
            },
            {
                value: 'Atheist',
                label: 'Atheist'
            },
            {
                value: 'Agnostic',
                label: 'Agnostic'
            },
            {
                value: 'Sikhism',
                label: 'Sikhism'
            },
            {
                value: 'Bhuddism',
                label: 'Bhuddism'
            },
            {
                value: 'Other',
                label: 'Other'
            },
        ]

        const ethnicityObj = [
            {
                value: 'Afgan',
                label: 'Afgan',
                children: []
            },
            {
                value: 'African Asian',
                label: 'African Asian',
                children: []
            },
            {
                value: 'Albanian',
                label: 'Albanian',
                children: []
            },
            {
                value: 'Black',
                label: 'Black',
                children: [
                    {
                        value: 'Ghanaian',
                        label: 'Ghanaian'
                    },
                    {
                        value: 'Nigerian',
                        label: 'Nigerian'
                    },
                    {
                        value: 'Somali',
                        label: 'Somali'
                    },
                    {
                        value: 'Caribbean',
                        label: 'Caribbean'
                    },
                    {
                        value: 'African Other',
                        label: 'Other Black African Background'
                    },
                    {
                        value: 'Other',
                        label: 'Any other Black Background'
                    },
                ]
            },
            {
                value: 'Bosnian',
                label: 'Bosnian',
                children: []
            },
            {
                value: 'Chinese',
                label: 'Chinese',
                children: []
            },
            {
                value: 'Croatian',
                label: 'Croatian',
                children: []
            },
            {
                value: 'Greek',
                label: 'Greek',
                children: []
            },
            {
                value: 'Greek Cypriot',
                label: 'Greek Cypriot',
                children: []
            },
            {
                value: 'Gypsy / Roma',
                label: 'Gypsy / Roma',
                children: []
            },
            {
                value: 'Indian',
                label: 'Indian',
                children: []
            },
            {
                value: 'Information Not Yet Obtained',
                label: 'Information Not Yet Obtained',
                children: []
            },
            {
                value: 'Iranian',
                label: 'Iranian',
                children: []
            },
            {
                value: 'Iraqi',
                label: 'Iraqi',
                children: []
            },
            {
                value: 'Italian',
                label: 'Italian',
                children: []
            },
            {
                value: 'Italian',
                label: 'Italian',
                children: []
            },
            {
                value: 'Japanese',
                label: 'Japanese',
                children: []
            },
            {
                value: 'Kosovan',
                label: 'Kosovan',
                children: []
            },
            {
                value: 'Kurdish',
                label: 'Kurdish',
                children: []
            },
            {
                value: 'Nepali',
                label: 'Nepali',
                children: []
            },
            {
                value: 'Asian Other',
                label: 'Asian Other',
                children: []
            },
            {
                value: 'Other',
                label: 'Other',
                children: []
            },
            {
                value: 'Pakistani',
                label: 'Pakistani',
                children: []
            },
            {
                value: 'Portuguese',
                label: 'Portuguese',
                children: []
            },
            {
                value: 'Serbian',
                label: 'Serbian',
                children: []
            },
            {
                value: 'Sri Lankan',
                label: 'Sri Lankan',
                children: [

                    {
                        value: 'Singalese',
                        label: 'Singalese',
                        children: []
                    }, {
                        value: 'Tamil',
                        label: 'Tamil',
                        children: []
                    },
                ]
            }, {
                value: 'Traveller of Iritsh Heritage',
                label: 'Traveller of Iritsh Heritage',
                children: []
            }, {
                value: 'Turkish',
                label: 'Turkish',
                children: []
            }, {
                value: 'Turkish Cypriot',
                label: 'Turkish Cypriot',
                children: []
            },

            {
                value: 'White',
                label: 'White',
                children: [
                    {
                        value: 'British',
                        label: 'British'
                    },
                    {
                        value: 'Irish',
                        label: 'Irish'
                    },
                    {
                        value: 'Any Other Asian Background',
                        label: 'Any Other Asian Background'
                    },
                    {
                        value: 'Black African',
                        label: 'Black African'
                    },
                    {
                        value: 'Black Caribbean',
                        label: 'Black Caribbean'
                    },
                    {
                        value: 'Indian',
                        label: 'Indian'
                    },
                    {
                        value: 'Black African',
                        label: 'Black African'
                    },
                    {
                        value: 'Pakistani',
                        label: 'Pakistani'
                    },
                    {
                        value: 'Eastern European',
                        label: 'Eastern European'
                    }, ,
                    {
                        value: 'Other',
                        label: 'Other'
                    }, ,
                    {
                        value: 'Western European',
                        label: 'Western European'
                    },

                ]
            },
            {
                value: 'Any Other Ethnic Group',
                label: 'Any Other Ethnic Group',
                children: []
            },
            {
                value: 'Any Other Mixed Background',
                label: 'Any Other Mixed Background',
                children: []
            },
        ];



        return (
            <>

                <Drawer
                    title="Enlist a new contact"
                    width={520}
                    closable={false}
                    anchor="right"
                    onClose={this.props.actions.onCloseCreateContactDrawer}
                    // open={this.state.canCreateContact && this.state.showCreateContactDrawer}
                    // visible={this.state.canCreateContact && this.state.showCreateContactDrawer}
                    open={this.props.canCreateContact && this.props.showCreateContactDrawer}
                    visible={this.props.canCreateContact && this.props.showCreateContactDrawer}
                >

                    <img src={formSVG} height="100" width="200" />

                    <br />
                    <br />

                    <b>First Name <i>(shown)</i></b>
                    <Input name="firstName" onChange={(e) => { this.onFieldChange(e) }} placeholder="e.g., Alicia" />

                    <br />
                    <br />

                    <b>Middle Name(s) <i>(shown)</i></b>
                    <Input placeholder="e.g., Marie" name="middleNames" onChange={(e) => { this.onFieldChange(e) }} />

                    <br />
                    <br />
                    <b>Last Name <i>(shown)</i></b>
                    <Input placeholder="e.g., Taylor" name="lastName" onChange={(e) => { this.onFieldChange(e) }} />

                    <br />
                    <br />

                    <b>Email <i>(shown)</i></b>
                    <Input placeholder="e.g., Taylor" name="email" onChange={(e) => { this.onFieldChange(e) }} />

                    <br />
                    <br />

                    <b>Address Line 1 <i>(shown)</i></b>
                    <Input class="govuk-input" id="address-line-1" name="address-line-1" type="text" autocomplete="address-line1" />

                    <br />
                    <br />


                    <b>Address Line 2 <i>(shown)</i></b>
                    <Input class="govuk-input" id="address-line-2" name="address-line-2" type="text" autocomplete="address-line2" />

                    <br />
                    <br />

                    <b>Town <i>(shown)</i></b>
                    <Input class="govuk-input govuk-!-width-two-thirds" id="address-town" name="address-town" type="text" autocomplete="address-level2" />

                    <br />
                    <br />


                    <b>County <i>(shown)</i></b>
                    <Input class="govuk-input govuk-!-width-two-thirds" id="address-county" name="address-county" type="text" />

                    <br />
                    <br />

                    <b>Post Code:  <i>(shown)</i></b>
                    <Input placeholder="e.g., Taylor" name="address-postcode" onChange={(e) => { this.onFieldChange(e) }} />

                    <br />
                    <br />

                    <b>Phone: (personal) <i>(shown)</i></b>
                    <Input placeholder="e.g., +44" name="phone" onChange={(e) => { this.onFieldChange(e) }} />

                    <br />
                    <br />

                    <b>Date of Birth <i>(shown)</i></b>
                    <br />
                    <DatePicker
                        format="DD/MM/YYYY"
                        onChange={this.onDateChange} />

                    <br />
                    <br />

                    <b>Gender <i>(shown)</i></b>

                    <br />
                    <br />


                    <Cascader options={genderObj} onChange={(gender) => { this.setState({ contactToCreate: { ...this.state.contactToCreate, gender } }) }} />

                    <br /><br />

                    <b>Ethnicity <i>(shown)</i></b>

                    <br />
                    <br />

                    <Cascader options={ethnicityObj} onChange={(ethnicity) => { this.setState({ contactToCreate: { ...this.state.contactToCreate, ethnicity } }) }} />

                    <br /><br />


                    {/* <b>Does this contact qualify for NEG</b>
                    <br />
                    <br />

                    <Switch
                        checkedChildren={<> <a>Yes, this contact qualifies for NEG</a></>}
                        unCheckedChildren={<> <a>No, this contact does not qualify for NEG</a></>}
                        onChange={() => { this.setState({ contactToCreate: { ...this.state.contactToCreate, isNEG: !this.state.contactToCreate.isNEG } }) }}
                    />

                    <br />
                    <br />


                    <b>Is SEN?</b>

                    <br />
                    <br />

                    <Switch
                        checkedChildren={<> <a>Yes, this contact has SEN needs</a></>}
                        unCheckedChildren={<> <a>No, this contact does not have SEN needs</a></>}
                        onChange={() => { this.setState({ contactToCreate: { ...this.state.contactToCreate, isExternal: !this.state.contactToCreate.isExternal } }) }}
                    /> 

                    <br />
                    <br /> */}

                    {/* {this.state.contactToCreate && this.state.contactToCreate.isExternal &&
                        <>

                            <b>SEN Categories</b>

                            <br />
                            <br />

                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                defaultValue={['a10', 'c12']}
                                onChange={(args) => {

                                    DEBUGGER.log('SEN Categories: ', args);
                                    this.setState({
                                        contactToCreate: {
                                            ...this.state.contactToCreate,
                                            SENCategories: []
                                        }
                                    });

                                }}
                            >
                                {contacts}
                            </Select>

                            <br />
                            <br />

                        </>
                    } */}


                    <b>Is this person the parent or guardian of an known child?</b>

                    <br />
                    <br />

                    <Switch
                        checkedChildren={<> <a>Yes, assign to a known child</a></>}
                        unCheckedChildren={<> <a>No, do not assign to a known child</a></>}
                        onChange={() => { this.setState({ contactToCreate: { ...this.state.contactToCreate, isGuardian: !this.state.contactToCreate.isGuardian } }) }}
                    />

                    <br />
                    <br />

                    {this.state.contactToCreate && this.state.contactToCreate.isGuardian &&
                        <>
                            <b>Select Child(ren)</b>

                            <br />
                            <br />

                            {/* <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                defaultValue={['a10', 'c12']}
                                onChange={(args) => {

                                    DEBUGGER.log('assign guardians: ', args);
                                    this.setState({
                                        contactToCreate: {
                                            ...this.state.contactToCreate,
                                            guardianOf: args
                                        }
                                    });

                                }}
                            >
                                {contacts}
                            </Select> */}


                            <ChildRemoteSelect setValue={(value) => { this.setState({ contactToCreate: { ...this.state.contactToCreate, guardianOf: value } }) }} />

                            <br />
                            <br />

                            <b>What is their relationship?</b>

                            <br />
                            <br />

                            <Select
                                mode="single"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                defaultValue={['Select ...']}
                                onChange={(args) => {

                                    DEBUGGER.log('assign relationships: ', args);
                                    this.setState({
                                        contactToCreate: {
                                            ...this.state.contactToCreate,
                                            guardianRelation: args
                                        }
                                    });

                                }}
                            >
                                {guardianRelationships}
                            </Select>
                            <br />
                            <br />

                            <b>Is this contact the contract holder?</b>
                            <br />
                            <br />

                            <Switch
                                checkedChildren={<> <a>Yes, this is the contract holder</a></>}
                                unCheckedChildren={<> <a>No, this is not the contract holder</a></>}
                                onChange={(val) => { this.setState({ contactToCreate: { ...this.state.contactToCreate, isContractHolder: val } }) }}
                            />

                            <br />
                            <br />

                            <b>Is this person an Emegency Contact?</b>

                            <br />
                            <br />

                            <Switch
                                checkedChildren={<> <a>Yes, this is an Emergency Contact</a></>}
                                unCheckedChildren={<> <a>No, this is not an Emergency Contact</a></>}
                                onChange={() => { this.setState({ contactToCreate: { ...this.state.contactToCreate, isEmergencyContact: !this.state.contactToCreate.isEmergencyContact } }) }}
                            />

                            <br />
                            <br />
                        </>
                    }

                    {this.state.contactToCreate && !this.state.contactToCreate.isEmergencyContact && !this.state.contactToCreate.isGuardian && <>
                        <b>What kind of contact is this person?</b>
                        <br />
                        <br />

                        <Cascader options={contactTypeObj} onChange={(contactType) => { this.setState({ contactToCreate: { ...this.state.contactToCreate, contactType } }) }} />

                    </>
                    }

                    <br />
                    <br />



                    <b>Invite this contact to the Parent's Portal?</b>
                    <br />
                    <br />

                    <Switch
                        checkedChildren={<> <a>Yes, a Portal Account for this contact</a></>}
                        unCheckedChildren={<> <a>No, do not create a Portal Account</a></>}
                        onChange={(val) => { this.setState({ contactToCreate: { ...this.state.contactToCreate, createPortalUser: val } }) }}
                    />

                    <br />
                    <br />

                    {this.state.contactToCreate.createPortalUser && <>

                        <b>Roles <i>(shown)</i></b>
                        <p>This determines what functionality will be available to the user and will change layout of the portal depending on the content being shown to this user. </p>
                        < br />



                        <Checkbox
                            checked={this.state.contactToCreate.isParent}
                            // disabled={(this.state.contactToCreate.isStaff || this.state.contactToCreate.isAdmin)}
                            onChange={(e) => {
                                this.setState({
                                    contactToCreate: {
                                        ...this.state.contactToCreate,
                                        isParent: !this.state.contactToCreate.isParent
                                    }
                                });
                            }}
                        >
                            {' '} Parent
                    </Checkbox>

                        <br />

                        <Checkbox
                            checked={this.state.contactToCreate.isStaff}
                            // disabled={(this.state.contactToCreate.isAdmin || this.state.contactToCreate.isParent)}
                            onChange={(e) => {
                                this.setState({
                                    contactToCreate: {
                                        ...this.state.contactToCreate,
                                        isStaff: !this.state.contactToCreate.isStaff
                                    }
                                });
                            }}
                        >
                            {' '} Staff
                    </Checkbox>

                        <br />

                        <Checkbox
                            checked={this.state.contactToCreate.isAdmin}
                            // disabled={(this.state.contactToCreate.isParent || this.state.contactToCreate.isStaff)}
                            onChange={(e) => {
                                this.setState({
                                    contactToCreate: {
                                        ...this.state.contactToCreate,
                                        isAdmin: !this.state.contactToCreate.isAdmin
                                    }
                                });
                            }}
                            disabled>
                            {' '} Admin
                    </Checkbox>


                    </>}

                    <br /><br />
                    {/* 
                    
                    --- ADMIN ONLY ----
                    
                    */}
                    <b>Does this contact have an orphan user account?</b>
                    <br />
                    <br />

                    <Switch
                        checkedChildren={<> <a>Yes, link contact and orphan user account</a></>}
                        unCheckedChildren={<> <a>No, do not link anything</a></>}
                        onChange={(val) => { this.setState({ contactToCreate: { ...this.state.contactToCreate, linkAnonUser: val } }) }}
                    />

                    {this.state.contactToCreate && this.state.contactToCreate.linkAnonUser &&
                        <>
                            <b>Select user</b>

                            <br />
                            <br />

                            {/* <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                defaultValue={['a10', 'c12']}
                                onChange={(args) => {

                                    DEBUGGER.log('assign guardians: ', args);
                                    this.setState({
                                        contactToCreate: {
                                            ...this.state.contactToCreate,
                                            guardianOf: args
                                        }
                                    });

                                }}
                            >
                                {contacts}
                            </Select> */}


                            <UserRemoteSelect setValue={(value) => { this.setState({ contactToCreate: { ...this.state.contactToCreate, linkAnonUser: true, anonUserId: value } }) }} />
                        </>
                    }

                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                    {/* <b>Roles <i>(shown)</i></b>
                    <p>This determines what functionality will be available to the contact and will change layout of the portal depending on the content being shown to this contact. </p>
                    < br />

                    <Checkbox
                        checked={this.state.contactToCreate.isParent}
                        // disabled={(this.state.contactToCreate.isStaff || this.state.contactToCreate.isAdmin)}
                        onChange={(e) => {
                            this.setState({
                                contactToCreate: {
                                    ...this.state.contactToCreate,
                                    isParent: !this.state.contactToCreate.isParent
                                }
                            });
                        }}
                    >
                        {' '} Parent
                    </Checkbox>

                    <br />

                    <Checkbox
                        checked={this.state.contactToCreate.isStaff}
                        // disabled={(this.state.contactToCreate.isAdmin || this.state.contactToCreate.isParent)}
                        onChange={(e) => {
                            this.setState({
                                contactToCreate: {
                                    ...this.state.contactToCreate,
                                    isStaff: !this.state.contactToCreate.isStaff
                                }
                            });
                        }}
                    >
                        {' '} Staff
                    </Checkbox>

                    <br />

                    <Checkbox
                        checked={this.state.contactToCreate.isAdmin}
                        // disabled={(this.state.contactToCreate.isParent || this.state.contactToCreate.isStaff)}
                        onChange={(e) => {
                            this.setState({
                                contactToCreate: {
                                    ...this.state.contactToCreate,
                                    isAdmin: !this.state.contactToCreate.isAdmin
                                }
                            });
                        }}
                    >
                        {' '} Admin
                    </Checkbox> */}

                    <br />
                    <br />

                    {/* <b>Mark this as the current Contact?</b>
                    <br />
                    <br />
                    <Switch
                        name='isCurrentContact'
                        onChange={() => {
                            this.setState({
                                ContactToCreateVars: {
                                    ...this.state.ContactToCreateVars,
                                    isCurrentContact: !this.state.ContactToCreate.isCurrentContact
                                }
                            })
                        }}
                        checkedChildren={
                            <>
                                <a>&emsp;Will be shown as the current Contact dates on the website &nbsp;</a>
                            </>
                        }
                        unCheckedChildren={
                            <>

                                <a>&emsp;Will not be shown as the current Contact on the website &emsp;</a>
                            </>
                        }
                        defaultChecked
                    /> */}

                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                        }}
                    >
                        {!this.state.committed && <Button style={{
                            marginRight: 8,
                        }} disabled={this.state.committing} onClick={this.props.actions.onCloseCreateContactDrawer} >Cancel</Button>}
                        <>

                            {!this.state.committed &&
                                <Button
                                    type="primary"
                                    onClick={this.onCommitNewContact}
                                    disabled={this.state.props}
                                    loading={this.state.committing}
                                    style={{ marginTop: 16 }}
                                >

                                    {!this.state.committed && !this.state.committing && 'Publish'}

                                </Button>
                            }


                            {/* <Button onClick={this.onCommitNewNotice} disabled={this.state.committing} type="primary">

                                {!this.state.committed && !this.state.committing && 'Publish'}

                                {this.state.committing && <>Publishing  <Icon type="loading" color="#52c41a" /> </>}

                            </Button> */}


                            {this.state.committed && <Button onClick={this.actions.onCloseCreateContactDrawer} type="success"> Finish </Button>}

                        </>

                    </div>
                </Drawer>   </>
        )
    }
}

export default CreateContactComponent


class ChildRemoteSelect extends React.Component {
    constructor(props) {
        super(props);
        this.lastFetchId = 0;
        this.fetchUser = debounce(this.fetchUser, 400);
    }

    state = {
        data: [],
        value: [],
        fetching: false,
    };

    fetchUser = value => {
        DEBUGGER.log('fetching user', value);
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.setState({ data: [], fetching: true });

        BSCCEApi.children.query(value)
            .then(resp => {
                var data = [];
                if (fetchId !== this.lastFetchId) {
                    // for fetch callback order
                    return;
                }
                if (resp.data.data.length > 0) {
                    data = resp.data.data.map(child => ({
                        text: `${child.firstName} ${child.lastName}`,
                        value: child._id,
                    }));
                } else {
                    notification.info(`no matches found for ${value}`, 5)
                }

                this.setState({ data, fetching: false });
            })

    };

    handleChange = value => {
        this.setState({
            value,
            data: [],
            fetching: false,
        });

        this.props.setValue(value);
    };

    render() {
        const { fetching, data, value } = this.state;
        return (
            <Select
                mode="multiple"
                labelInValue
                value={value}
                placeholder="Select child(ren)"
                notFoundContent={fetching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={this.fetchUser}
                onChange={this.handleChange}
                style={{ width: '100%' }}
            >
                {data.map(d => (
                    <Option key={d.value}>{d.text}</Option>
                ))}
            </Select>
        );
    }
}

class UserRemoteSelect extends React.Component {
    constructor(props) {
        super(props);
        this.lastFetchId = 0;
        this.fetchUser = debounce(this.fetchUser, 400);
    }

    state = {
        data: [],
        value: [],
        fetching: false,
    };

    fetchUser = value => {
        DEBUGGER.log('fetching user', value);
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.setState({ data: [], fetching: true });

        BSCCEApi.users.query(value)
            .then(resp => {
                console.log (resp);
                var data = [];
                if (fetchId !== this.lastFetchId) {
                    // for fetch callback order
                    return;
                }
                if (resp.data.data.length > 0) {

                    let orphanUsers = resp.data.data.filter(user => !user.contactId);

                    DEBUGGER.log(orphanUsers);

                    data = orphanUsers.map(user => ({
                        text: `${user.firstName} ${user.lastName} ${user.email}`,
                        value: user._id,
                    }));

                } else {
                    notification.info(`no matches found for ${value}`, 5)
                }

                this.setState({ data, fetching: false });
            })

    };

    handleChange = value => {
        this.setState({
            value,
            data: [],
            fetching: false,
        });

        this.props.setValue(value);
    };

    render() {
        const { fetching, data, value } = this.state;
        return (
            <Select
                mode="multiple"
                labelInValue
                value={value}
                placeholder="Select user"
                notFoundContent={fetching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={this.fetchUser}
                onChange={this.handleChange}
                style={{ width: '100%' }}
            >
                {data.map(d => (
                    <Option key={d.value}>{d.text}</Option>
                ))}
            </Select>
        );
    }
}