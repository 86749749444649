import React, { Component } from 'react'

import {
    Badge,

    Card,
    CardHeader,
    CardFooter,
    Col,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip
} from "reactstrap";

import { Drawer, Checkbox, Input, DatePicker, message, notification, Menu, Dropdown, Icon, Cascader, Select, Switch } from 'antd';

import {
    Button,

} from "reactstrap";


import { ScaleLoader } from 'react-spinners';

import BSCCEApi from '../../apis/BSCCEApi';

import '../../../node_modules/antd/lib/cascader/style/css'
import '../../../node_modules/antd/lib/select/style/css'
import { isArray } from 'lodash';

const formSVG = require('../../assets/img/undraw/undraw_add_user_ipe3.svg')

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const { Option } = Select;

function handleChange(value) {
    console.log(`selected ${value}`);
}


const genderObj = [
    {
        value: 'Male',
        label: 'Male'
    },
    {
        value: 'Female',
        label: 'Female'
    },
]

const ethnicityObj = [
    {
        value: 'Afgan',
        label: 'Afgan',
        children: []
    },
    {
        value: 'African Asian',
        label: 'African Asian',
        children: []
    },
    {
        value: 'Albanian',
        label: 'Albanian',
        children: []
    },
    {
        value: 'Black',
        label: 'Black',
        children: [
            {
                value: 'Ghanaian',
                label: 'Ghanaian'
            },
            {
                value: 'Nigerian',
                label: 'Nigerian'
            },
            {
                value: 'Somali',
                label: 'Somali'
            },
            {
                value: 'Caribbean',
                label: 'Caribbean'
            },
            {
                value: 'African Other',
                label: 'Other Black African Background'
            },
            {
                value: 'Other',
                label: 'Any other Black Background'
            },
        ]
    },
    {
        value: 'Bosnian',
        label: 'Bosnian',
        children: []
    },
    {
        value: 'Chinese',
        label: 'Chinese',
        children: []
    },
    {
        value: 'Croatian',
        label: 'Croatian',
        children: []
    },
    {
        value: 'Greek',
        label: 'Greek',
        children: []
    },
    {
        value: 'Greek Cypriot',
        label: 'Greek Cypriot',
        children: []
    },
    {
        value: 'Gypsy / Roma',
        label: 'Gypsy / Roma',
        children: []
    },
    {
        value: 'Indian',
        label: 'Indian',
        children: []
    },
    {
        value: 'Information Not Yet Obtained',
        label: 'Information Not Yet Obtained',
        children: []
    },
    {
        value: 'Iranian',
        label: 'Iranian',
        children: []
    },
    {
        value: 'Iraqi',
        label: 'Iraqi',
        children: []
    },
    {
        value: 'Italian',
        label: 'Italian',
        children: []
    },
    {
        value: 'Italian',
        label: 'Italian',
        children: []
    },
    {
        value: 'Japanese',
        label: 'Japanese',
        children: []
    },
    {
        value: 'Kosovan',
        label: 'Kosovan',
        children: []
    },
    {
        value: 'Kurdish',
        label: 'Kurdish',
        children: []
    },
    {
        value: 'Nepali',
        label: 'Nepali',
        children: []
    },
    {
        value: 'Asian Other',
        label: 'Asian Other',
        children: []
    },
    {
        value: 'Other',
        label: 'Other',
        children: []
    },
    {
        value: 'Pakistani',
        label: 'Pakistani',
        children: []
    },
    {
        value: 'Portuguese',
        label: 'Portuguese',
        children: []
    },
    {
        value: 'Serbian',
        label: 'Serbian',
        children: []
    },
    {
        value: 'Sri Lankan',
        label: 'Sri Lankan',
        children: [

            {
                value: 'Singalese',
                label: 'Singalese',
                children: []
            }, {
                value: 'Tamil',
                label: 'Tamil',
                children: []
            },
        ]
    }, {
        value: 'Traveller of Iritsh Heritage',
        label: 'Traveller of Iritsh Heritage',
        children: []
    }, {
        value: 'Turkish',
        label: 'Turkish',
        children: []
    }, {
        value: 'Turkish Cypriot',
        label: 'Turkish Cypriot',
        children: []
    },

    {
        value: 'White',
        label: 'White',
        children: [
            {
                value: 'British',
                label: 'British'
            },
            {
                value: 'Irish',
                label: 'Irish'
            },
            {
                value: 'Any Other Asian Background',
                label: 'Any Other Asian Background'
            },
            {
                value: 'Black African',
                label: 'Black African'
            },
            {
                value: 'Black Caribbean',
                label: 'Black Caribbean'
            },
            {
                value: 'Indian',
                label: 'Indian'
            },
            {
                value: 'Black African',
                label: 'Black African'
            },
            {
                value: 'Pakistani',
                label: 'Pakistani'
            },
            {
                value: 'Eastern European',
                label: 'Eastern European'
            }, ,
            {
                value: 'Other',
                label: 'Other'
            }, ,
            {
                value: 'Western European',
                label: 'Western European'
            },

        ]
    },
    {
        value: 'Any Other Ethnic Group',
        label: 'Any Other Ethnic Group',
        children: []
    },
    {
        value: 'Any Other Mixed Background',
        label: 'Any Other Mixed Background',
        children: []
    },
];




export class EditChildrenComponent extends Component {

    actions = {};

    state = {
        childToEdit: {}
    }
    notifications = {
        keys: {
            createUser: 'createChild',
            updateChild: 'updateChild'
        },
        type: {
            overallStatus: {
                working: {
                    key: 'overallStatus',
                    duration: 0,
                    message: 'working ...',
                    description: 'This task is not yet complete'
                },
                success: {
                    key: 'overallStatus',
                    duration: 10,
                    message: 'Task Complete',
                    description: 'This task was completed successfully'
                },
                error: {
                    key: 'overallStatus',
                    duration: 10,
                    message: 'This task failed',
                    description: ''
                }
            },
            updateChild: {
                pending: {
                    key: 'updateChild',
                    duration: 0,
                    message: 'Update Child',
                    description: 'Waiting to update the child record'
                },
                working: {
                    key: 'updateChild',
                    duration: 0,
                    message: 'Updating Child',
                    description: 'Updating child record.'
                },
                success: {
                    key: 'updateChild',
                    duration: 10,
                    message: 'User Updated',
                    description: 'Successfully Updated child record.'
                },
                error: msg => ({
                    key: 'updateChild',
                    duration: 10,
                    message: 'Update failed',
                    description: msg
                })
            }
        }
    }


    constructor(props) {
        super(props);

        this.actions = props.actions;

    }

    onFieldChange = (e) => this.setState({
        childToEdit: {
            ...this.state.childToEdit,
            [e.target.name]: e.target.value
        }
    });


    onDateChange = (date, dateString) => {
        console.log(date, dateString);

        this.setState({
            childToEdit: {
                ...this.state.childToEdit,
                dob: date
            }
        })
    }

    onCommitUpdatedChild = (child = this.state.childToEdit) => {

        // notification. updating child
        this.setState({ submitting: true })

        // copy from props
        let childToUpdate = { 
            ...this.props.childToEdit, 
            ...this.state.childToEdit,
        }

        console.log (childToUpdate);

        childToUpdate.gender =  childToUpdate.gender ? childToUpdate.gender[0] : null;
        childToUpdate.ethnicity =  childToUpdate.ethnicity ? 
            isArray(childToUpdate.ethnicity) 
                ? childToUpdate.ethnicity.join('-') 
                    : childToUpdate.ethnicity 
            : null;

        notification.info(this.notifications.type.updateChild.working)

        BSCCEApi
            .children
            .putChild(childToUpdate)
            .then(resp => {
                if (resp.data.status == 200) {
                    notification.success(this.notifications.type.updateChild.success)
                    this.setState ({
                        childToEdit: {},
                        childToUpdate: {}
                    })
                    this.props.actions.list.refresh()

                    // notification child updated
                    // this.setState ({ submitting: false;})
                } else {
                    notification.error(this.notifications.type.updateChild.error(resp.data.data.err))

                    // notification child updated failed
                    // this.setState ({ submitting: false;})
                }

            })
            .catch(err => {
                notification.error(this.notifications.type.updateChild.error("Looks like a problem with the network."))

                // notification child update failed
                // network error
                // this.setState ({ submitting: false;})

            })

    }

    render() {
        return (
            <div>
                <Drawer
                    title={<><a>Edit </a>
                        <UncontrolledDropdown>
                            <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={() => { }}
                            >
                                {this.props.childToEdit &&
                                    <h4>{this.props.childToEdit.title}
                                        <Icon type="tool" theme="twoTone" style={{ fontSize: '14pt' }} color="#1890ff" />
                                    </h4>
                                }

                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem href="#pablo" >
                                    <Button style={{ color: 'darkred' }} type="primary" onClick={() => {

                                        this.actions.delete.onshowDeleteChildDrawer(this.props.childToEdit)
                                    }}>
                                        Delete {this.props.childToEdit && this.props.childToEdit.title}
                                    </Button>
                                </DropdownItem>

                            </DropdownMenu>
                        </UncontrolledDropdown> </>}
                    width={520}
                    closable={false}
                    anchor="right"
                    onClose={this.actions.edit.onCloseEditChildDrawer}

                    open={this.props.canEditChild && this.props.showEditChildDrawer}
                    visible={this.props.canEditChild && this.props.showEditChildDrawer}
                >
                    {/* form */}

                    <b>First Name <i>(shown)</i></b>
                    <Input name="firstName" onChange={(e) => { this.onFieldChange(e) }} placeholder={this.props.childToEdit.firstName && this.props.childToEdit.firstName} />

                    <br />
                    <br />

                    <b>Middle Name(s) <i>(shown)</i></b>
                    <Input placeholder={this.props.childToEdit.middleNames && this.props.childToEdit.middleNames} name="middleNames" onChange={(e) => { this.onFieldChange(e) }} />

                    <br />
                    <br />
                    <b>Last Name <i>(shown)</i></b>
                    <Input placeholder={this.props.childToEdit.lastName && this.props.childToEdit.lastName} name="lastName" onChange={(e) => { this.onFieldChange(e) }} />

                    <br />
                    <br />

                    <b>Date of Birth <i>(shown)</i></b>
                    <br />
                    <DatePicker
                        format="DD/MM/YYYY"
                        placeholder={this.props.childToEdit.dob && this.props.childToEdit.dob}
                        onChange={this.onDateChange} />

                    <br />
                    <br />

                    <b>Gender <i>(shown)</i></b>

                    <br />
                    <br />


                    <Cascader options={genderObj} onChange={(gender) => { this.setState({ childToEdit: { ...this.state.childToEdit, gender } }) }} placeholder={this.props.childToEdit.gender && this.props.childToEdit.gender} />

                    <br /><br />

                    <b>Ethnicity <i>(shown)</i></b>

                    <br />
                    <br />

                    <Cascader options={ethnicityObj} onChange={(ethnicity) => { this.setState({ childToEdit: { ...this.state.childToEdit, ethnicity } }) }} placeholder={this.props.childToEdit.ethnicity && this.props.childToEdit.ethnicity} />

                    <br /><br />

                    <b>Does this child qualify for NEG</b>
                    <br />
                    <br />

                    <Switch
                        checkedChildren={<> <a>Yes, this child qualifies for NEG</a></>}
                        unCheckedChildren={<> <a>No, this child does not qualify for NEG</a></>}
                        onChange={() => { this.setState({ childToEdit: { ...this.state.childToEdit, isNEG: !this.state.childToEdit.isNEG } }) }}
                        defaultValue={this.props.childToEdit.isNEG && this.props.childToEdit.isNEG}
                    />

                    <br />
                    <br />


                    <b>Is SEN?</b>

                    <br />
                    <br />

                    <Switch
                        checkedChildren={<> <a>Yes, this child has SEN needs</a></>}
                        unCheckedChildren={<> <a>No, this child does not have SEN needs</a></>}
                        onChange={() => { this.setState({ childToEdit: { ...this.state.childToEdit, isSEN: !this.state.childToEdit.isSEN } }) }}
                        defaultValue={this.props.childToEdit.isSEN && this.props.childToEdit.isSEN}
                    />

                    <br />
                    <br />

                    {this.state.childToEdit && this.state.childToEdit.isSEN &&
                        <>

                            <b>SEN Categories</b>

                            <br />
                            <br />

                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                defaultValue={this.props.childToEdit.SENCategories && this.props.childToEdit.SENCategories}
                                onChange={(args) => {

                                    console.log('SEN Categories: ', args);
                                    this.setState({
                                        childToEdit: {
                                            ...this.state.childToEdit,
                                            SENCategories: []
                                        }
                                    });

                                }}
                            >
                                {[]}
                            </Select>

                            <br />
                            <br />

                        </>
                    }

                    <b>Assign Guardians</b>

                    <br />
                    <br />

                    <Switch
                        checkedChildren={<> <a>Yes, assign Guardians now</a></>}
                        unCheckedChildren={<> <a>No, assign guardians later</a></>}
                        onChange={() => { this.setState({ childToEdit: { ...this.state.childToEdit, hasGuardians: !this.state.childToEdit.hasGuardians } }) }}
                        placeholder={this.props.childToEdit.guardians && this.props.childToEdit.guardians}
                    />

                    <br />
                    <br />

                    {this.state.childToEdit && this.state.childToEdit.hasGuardians &&
                        <>
                            <b>Select Guardians</b>

                            <br />
                            <br />

                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                defaultValue={this.props.childToEdit.guardians && this.props.childToEdit.guardians}
                                onChange={(args) => {

                                    console.log('assign guardians: ', args);
                                    this.setState({
                                        childToEdit: {
                                            ...this.state.childToEdit,
                                            guardians: args
                                        }
                                    });

                                }}
                            >
                                {[]}
                            </Select>

                            <br />
                            <br />

                        </>
                    }


                    <b>Assign Key Person</b>

                    <br />
                    <br />

                    <Switch
                        checkedChildren={<> <a>Yes, assign Key Person now</a></>}
                        unCheckedChildren={<> <a>No, assign Key Person later</a></>}
                        onChange={() => { this.setState({ childToEdit: { ...this.state.childToEdit, hasKeyPerson: !this.state.childToEdit.hasKeyPerson } }) }}
                        defaultValue={this.props.childToEdit.hasKeyPerson}
                    />

                    <br />
                    <br />

                    {this.state.childToEdit && this.state.childToEdit.hasKeyPerson &&
                        <>
                            <b>Select Key Worker</b>

                            <br />
                            <br />

                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                onChange={(args) => {

                                    console.log('assign guardians: ', args);
                                    this.setState({
                                        childToEdit: {
                                            ...this.state.childToEdit,
                                            keyPerson: []
                                        }
                                    });

                                }}
                            >
                                {[]}
                            </Select>

                            <br />
                            <br />

                        </>
                    }

                    <b>Does this child have any allergies</b>

                    <br />
                    <br />

                    <Switch
                        checkedChildren={<> <a>Yes, this child has allergies / diatery requirements</a></>}
                        unCheckedChildren={<> <a>No, this child does not have allergies / diatery requirements</a></>}
                        onChange={() => { this.setState({ childToEdit: { ...this.state.childToEdit, hasDietaryAllergies: !this.state.childToEdit.hasDietaryAllergies } }) }}
                        defaultValue={this.props.childToEdit.hasDietaryAllergies}
                    />

                    <br />
                    <br />

                    {this.state.childToEdit && this.state.childToEdit.hasDietaryAllergies &&
                        <>
                            <b>Select Allergy Categories</b>

                            <br />
                            <br />

                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                labelInValue={true}
                                loading={true}
                                onChange={(args) => {

                                    console.log('allery categories: ', args);
                                    this.setState({
                                        childToEdit: {
                                            ...this.state.childToEdit,
                                            dietaryAllergies: []
                                        }
                                    });

                                }}
                            >
                                {[]}
                            </Select>
                        </>
                    }

                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                    {/* form */}

                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                        }}
                    >
                        <Button
                            style={{
                                marginRight: 8,
                            }}
                            onClick={this.actions.edit.onCloseEditChildDrawer}>
                            Cancel
                        </Button>

                        {!this.state.committed && <Button onClick={this.onCommitUpdatedChild} type="primary">  Save  </Button>}
                        {this.state.commiting && <Button disabled={true} type="primary" >
                            Saving ...
                    <ScaleLoader
                                // css={{}}
                                sizeUnit={"px"}
                                size={40}
                                color={'#176599'}
                            />
                        </Button>}
                        {this.state.committed && <Button onClick={this.actions.edit.onCloseEditChildDrawer} type="primary"> Close </Button>}


                    </div>
                </Drawer>
            </div >
        )
    }
}

export default EditChildrenComponent
