/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip
} from "reactstrap";


import { ScaleLoader } from 'react-spinners';

import { Drawer, Form, Input, message, Upload, Select, DatePicker, Icon, Switch, Menu, Dropdown, notification, Tag } from 'antd';
import moment from 'moment';
import Truncate from 'react-truncate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';



// import Drawer from '@material-ui/core/Drawer';
import '../../../node_modules/antd/lib/drawer/style/css';
import '../../../node_modules/antd/lib/date-picker/style/css';
import '../../../node_modules/antd/lib/switch/style/css';
import '../../../node_modules/antd/lib/dropdown/style/css';
import '../../../node_modules/antd/lib/tag/style/css';
import '../../../node_modules/antd/lib/message/style/css';
import '../../../node_modules/antd/lib/notification/style/css';


// core components
import BlankHeader from "components/Headers/BlankHeader.jsx";

import BSCCE from '../../apis/BSCCEApi';


const { RangePicker } = DatePicker;
const { TextArea } = Input;


const mapStateToProps = (state) => {
  return {
    notesFromStore: state.notes
  }
}


class ListUsersComponent extends React.Component {

  state = {
    userToCreate: {}
  }

  constructor(props) {
    super(props);

    console.log('user component props ', props);
  }

  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description
    });
  };




  onSelectUserFromList = (e) => {
    this.setState({ selectedUser: e });
  }

  onDeleteUser = (e) => {
    console.log('delete notice ', e);
    console.log('delete notice ', this.state.userToEdit);
    this.setState({
      deleting: true,
    });


    BSCCE.users.deleteUser(e._id)
      .then(res => {
        if (res.data.status == 200) {

          this.setState({
            deleting: false,
            deleted: true
          });

          this.setState({
            users: [...this.props.users.filter(user => user._id != this.state.userToEdit._id)]
          });

          this.onDeleteComplete();

        } else {
          this.openNotificationWithIcon('error', 'Something went wrong', res.data.data.reason);
          this.setState({
            deleting: false
          });

        }
      }, err => {
        console.log('err ', err);
        this.openNotificationWithIcon('error', 'Something went wrong', err);
        this.setState({
          deleting: false
        });
      });

    setTimeout(() => {



    }, 2000);

  }

  onDeleteComplete = (e) => {
    this.openNotificationWithIcon('success', 'Delete success', `${this.state.userToEdit.firstName} has now been deleted.`);
    this.onCloseDeleteconfirmationDrawer();
    this.onCloseEditUserDrawer();

    this.setState({
      deleting: false,
      deleted: false,
      selectedUser: null,
      userToEdit: null,
    });

  }

  onFieldChange = (e) => this.setState({ [e.target.name]: e.target.value });


  createNewUserDrawerForm = [
    {
      name: 'firstName',
      onChange: this.onFieldChange,
      value: this.state.userToCreate.firstName,
      placeholder: "enter new user's first name",
      type: 'Input',
      label: "User's firstname",
      props: {}
    },
    {
      name: 'lastName',
      onChange: this.onFieldChange,
      value: this.state.userToCreate.lastName,
      placeholder: "enter new user's last name",
      type: 'Input',
      label: "User's last name",
      props: {},
    },
    {
      name: 'email',
      onChange: this.onFieldChange,
      value: this.state.userToCreate.email,
      placeholder: "enter new user's email",
      type: 'Input',
      label: "user's email",
      props: {}
    }, {
      name: 'password',
      onChange: this.onFieldChange,
      value: this.state.userToCreate.email,
      placeholder: "enter new user's email",
      type: 'Input',
      label: "user's email",
      props: {}
    }, {
      name: 'roles',
      onChange: this.onFieldChange,
      value: this.state.userToCreate.email,
      placeholder: "enter new user's email",
      type: 'Input',
      label: "user's email",
      props: {}
    },

  ]


  render() {
    return (
      <>

        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">

                {this.props.loading && <div className='sweet-loading text-center'>
                  <ScaleLoader
                    // css={override}
                    sizeUnit={"px"}
                    size={60}
                    color={'#176599'}
                  />
                </div>}
                {!this.props.loading && <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">First Name</th>
                      <th scope="col">Last Name <i>(shown)</i></th>
                      <th scope="col">Email <i>(shown)</i></th>
                      <th scope="col">Status</th>
                      <th scope="col">isContact</th>
                      <th scope="col"></th>

                    </tr>
                  </thead>
                  <tbody>


                    {this.props.users && this.props.users.map((item, i) =>
                      <tr>
                        <th scope="row">


                          {item.contactId &&
                            <FontAwesomeIcon icon={faUserCheck} style={{ color: 'green', fontSize: '12pt' }} />
                          }

                          {item.firstName}
                        </th>

                        <td>
                          <div className="d-flex align-items-center">
                            <div>
                              {item.lastName}
                            </div>
                          </div>
                        </td>
                        <td >
                          <div className="d-flex align-items-center">
                            {item.email}

                          </div>
                        </td>
                        <td >
                          <div className="d-flex align-items-center">


                            {item.roles && item.roles.map((role, i) =>

                              <>

                                <Tag color="">{role.name}</Tag>

                              </>

                            )}


                          </div>
                        </td>
                        <td className="text-left">
                          {/* // is enabled */}
                          {item.isVerified && <Icon type="smile" style={{ fontSize: '14pt' }} theme="twoTone" twoToneColor="#52c41a" />}
                          &emsp;
                      {/* // is not enabled                       */}
                          {!item.isVerified && <Icon type="tick" style={{ fontSize: '14pt' }} theme="twoTone" twoToneColor="#52c41a" />}
                          &emsp;



                      </td>
                        <td>
                          {(item.contactId) ? 'Y' : 'N'}
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              // href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={() => { this.props.actions.edit.onShowEditUserDrawer(item) }}
                            >
                              <Icon type="tool" theme="twoTone" style={{ fontSize: '14pt' }} color="#1890ff" />

                            </DropdownToggle>

                          </UncontrolledDropdown>
                        </td>

                      </tr>
                    )
                    }

                  </tbody>
                </Table>}
                <CardFooter className="py-4">
                  {/* <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem className="disabled">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className="active">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          2 <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          3
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
              </nav> */}
                </CardFooter>
              </Card>
            </div>
          </Row>

        </Container>

      </>
    );
  }
}

export default connect(mapStateToProps)(ListUsersComponent);
