import React, { Component } from 'react'

import { Drawer, Checkbox, Input, DatePicker, message } from 'antd';

import {
    Button,

} from "reactstrap";


import { ScaleLoader } from 'react-spinners';

import BSCCEApi from '../../apis/BSCCEApi';

const { RangePicker } = DatePicker;
const { TextArea } = Input;


export class CreateUsersComponent extends Component {

    state = {
        userToCreate: {
            firstName: null,
            lastName: '',
            email: '',
            roles: '',
            password: null,
            isStaff: null,
            isParent: null,
            isAdmin: null,
            contactId: null,
        }
    };

    onCommitNewUser = (user) => {

        console.log(this.state.userToCreate)

        let userObj = { ...this.state.userToCreate }

        userObj.roles = [];

        if (userObj.isParent) userObj.roles.push({ name: 'parent' });
        if (userObj.isAdmin) userObj.roles.push({ name: 'admin' });
        if (userObj.isStaff) userObj.roles.push({ name: 'staff' });

        console.log(userObj);

        BSCCEApi.users.createUser(userObj)
            .then(resp => {
                console.log('on after submit', resp);

                if (resp.data.status == 200) {
                    message.success ("User created successfully");
                    this.props.actions.onCompleteCreateUser(resp);

                } else {

                    message.error(resp.data.data.reason);
                }


            })
            .catch(e => {
                console.log('on error', e);
            })

    }

    onFieldChange = (e) => this.setState({
        userToCreate: {
            ...this.state.userToCreate,
            [e.target.name]: e.target.value
        }
    });

    createUserFormModel = [
        {
            name: 'firstName',
            onChange: this.onFieldChange,
            value: this.state.userToCreate.firstName,
            placeholder: "enter new user's first name",
            type: 'Input',
            label: "User's firstname",
            props: {}
        },
        {
            name: 'lastName',
            onChange: this.onFieldChange,
            value: this.state.userToCreate.lastName,
            placeholder: "enter new user's last name",
            type: 'Input',
            label: "User's last name",
            props: {},
        },
        {
            name: 'email',
            onChange: this.onFieldChange,
            value: this.state.userToCreate.email,
            placeholder: "enter new user's email",
            type: 'Input',
            label: "user's email",
            props: {}
        }, {
            name: 'password',
            onChange: this.onFieldChange,
            value: this.state.userToCreate.email,
            placeholder: "enter new user's email",
            type: 'Input',
            label: "user's email",
            props: {}
        }, {
            name: 'roles',
            onChange: this.onFieldChange,
            value: this.state.userToCreate.email,
            placeholder: "enter new user's email",
            type: 'Input',
            label: "user's email",
            props: {}
        },

    ]


    permissionModel = (roleName) => {
        return {
            [roleName]: {

            }
        }
    }


    render() {
        return (
            <>
                <Drawer
                    title="Create a new user"
                    width={520}
                    closable={false}
                    anchor="right"
                    onClose={this.props.actions.onCloseCreateUserDrawer}
                    // open={this.state.canCreateUser && this.state.showCreateUserDrawer}
                    // visible={this.state.canCreateUser && this.state.showCreateUserDrawer}
                    open={this.props.canCreateUser && this.props.showCreateUserDrawer}
                    visible={this.props.canCreateUser && this.props.showCreateUserDrawer}
                >

                    <b>First Name <i>(shown)</i></b>
                    <Input name="firstName" onChange={(e) => { this.onFieldChange(e) }} placeholder="e.g., Alicia" />

                    <br />
                    <br />
                    <b>Last Name <i>(shown)</i></b>
                    <Input placeholder="e.g., Thomas" name="lastName" onChange={(e) => { this.onFieldChange(e) }} />

                    <br />
                    <br />

                    <b>Email <i>(shown)</i></b>
                    <br />
                    <Input placeholder="alicia.thomas@bscce.com" name="email" onChange={(e) => { this.onFieldChange(e) }} />

                    <br />
                    <br />

                    <b>Recovery Email <i>(internal)</i></b>
                    <br />
                    <Input placeholder="alicia.thomas@gmail.com" name="recoveryEmail" onChange={(e) => { this.onFieldChange(e) }} />

                    <br /><br />

                    <b>Password <i></i></b>
                    <br />
                    <Input placeholder="alicia.thomas@gmail.com" name="password" type="password" onChange={(e) => { this.onFieldChange(e) }} />



                    <br />
                    <br />
                    <b>Roles <i>(shown)</i></b>
                    <p>This determines what functionality will be available to the user and will change layout of the portal depending on the content being shown to this user. </p>
                    < br />



                    <Checkbox
                        checked={this.state.userToCreate.isParent}
                        // disabled={(this.state.userToCreate.isStaff || this.state.userToCreate.isAdmin)}
                        onChange={(e) => {
                            this.setState({
                                userToCreate: {
                                    ...this.state.userToCreate,
                                    isParent: !this.state.userToCreate.isParent
                                }
                            });
                        }}
                    >
                        {' '} Parent
                    </Checkbox>

                    <br />

                    <Checkbox
                        checked={this.state.userToCreate.isStaff}
                        // disabled={(this.state.userToCreate.isAdmin || this.state.userToCreate.isParent)}
                        onChange={(e) => {
                            this.setState({
                                userToCreate: {
                                    ...this.state.userToCreate,
                                    isStaff: !this.state.userToCreate.isStaff
                                }
                            });
                        }}
                    >
                        {' '} Staff
                    </Checkbox>

                    <br />

                    <Checkbox
                        checked={this.state.userToCreate.isAdmin}
                        // disabled={(this.state.userToCreate.isParent || this.state.userToCreate.isStaff)}
                        onChange={(e) => {
                            this.setState({
                                userToCreate: {
                                    ...this.state.userToCreate,
                                    isAdmin: !this.state.userToCreate.isAdmin
                                }
                            });
                        }}
                    >
                        {' '} Admin
                    </Checkbox>

                    <br />
                    <br />

                    {/* <b>Mark this as the current User?</b>
                    <br />
                    <br />
                    <Switch
                        name='isCurrentUser'
                        onChange={() => {
                            this.setState({
                                UserToCreateVars: {
                                    ...this.state.UserToCreateVars,
                                    isCurrentUser: !this.state.UserToCreate.isCurrentUser
                                }
                            })
                        }}
                        checkedChildren={
                            <>
                                <a>&emsp;Will be shown as the current User dates on the website &nbsp;</a>
                            </>
                        }
                        unCheckedChildren={
                            <>

                                <a>&emsp;Will not be shown as the current User on the website &emsp;</a>
                            </>
                        }
                        defaultChecked
                    /> */}

                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                        }}
                    >
                        {!this.state.committed && <Button style={{
                            marginRight: 8,
                        }} disabled={this.state.committing} onClick={this.props.actions.onCloseCreateUserDrawer} >Cancel</Button>}
                        <>

                            {!this.state.committed &&
                                <Button
                                    type="primary"
                                    onClick={this.onCommitNewUser}
                                    disabled={this.state.props}
                                    loading={this.state.committing}
                                    style={{ marginTop: 16 }}
                                >

                                    {!this.state.committed && !this.state.committing && 'Publish'}

                                </Button>
                            }


                            {/* <Button onClick={this.onCommitNewNotice} disabled={this.state.committing} type="primary">

                                {!this.state.committed && !this.state.committing && 'Publish'}

                                {this.state.committing && <>Publishing  <Icon type="loading" color="#52c41a" /> </>}

                            </Button> */}


                            {this.state.committed && <Button onClick={this.actions.onCloseCreateUserDrawer} type="success"> Finish </Button>}

                        </>

                    </div>
                </Drawer>   </>
        )
    }
}

export default CreateUsersComponent
