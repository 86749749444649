import { createStore, combineReducers } from 'redux';
import contextReducer from '../reducers/contextReducer';
import userReducer from '../reducers/userReducer';
import viewAsReducer from '../reducers/viewAsReducer';

const reducers = combineReducers ({
    user: userReducer,  
    context: contextReducer,
    viewAsUser: viewAsReducer
});

export default createStore(
  reducers,
  undefined,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);