
import React from "react";

import {
    // Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col
} from "reactstrap"

import { Button } from 'antd'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faQuoteRight, faPlusCircle } from '@fortawesome/free-solid-svg-icons';


class LinkbookEntryComponent extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        return <>

            {
                this.props.focusedEntry && <>

                    <Container className="mt-5" fluid>

                        <Row>

                            <div class="col-lg-8">



                                {/* <Tag color="blue" style={{ margin: '0', padding: '5px', fontSize: '12pt', textAlign: 'center' }}> */}
                                <Card style={{zIndex: '10'}}>
                                    <div class="card-header bg-transparent">
                                        <div class="col-1">
                                            <FontAwesomeIcon icon={faQuoteLeft} style={{ float: 'left' }} />
                                        </div>

                                        <div class="col-10">
                                            <h2 class="mb-0" style={{textAlign: 'center'}}>

                                                <span>
                                                    This Week I did something or other and something else
                                                </span>


                                            </h2>
                                        </div>

                                        <div class="col-1">
                                            <FontAwesomeIcon icon={faQuoteRight} style={{ float: 'right' }} />
                                        </div>
                                    </div>
                                </Card>

                                <div style={{ marginTop: '-15px', padding: '10px' }}>

                   
                                    <Card>

                                        <CardHeader>
                                            <h3>
                                                Well Being
                                            </h3>
                                        </CardHeader>
                                        <CardBody>
                                            <p>Something about well being </p>
                                        </CardBody>

                                        <CardHeader>
                                            <h3>
                                                Toiletting Progress
                                            </h3>
                                        </CardHeader>
                                        <CardBody>
                                            <p>Something about toiletting</p>
                                        </CardBody>

                                        <CardHeader>

                                            <h3> Sleep Chart</h3> 
                                                </CardHeader>
                                        <CardBody>
                                            <table>
                                                <tr>
                                                    <td>day</td>
                                                    <td>from</td>
                                                    <td>-</td>
                                                    <td>until</td>
                                                </tr>
                                                <tr>
                                                    <td>Monday</td>
                                                    <td>8:00</td>
                                                    <td>-</td>
                                                    <td>6:00</td>
                                                </tr>
                                                <tr>
                                                    <td>Tuesday</td>
                                                    <td>8:00</td>
                                                    <td>-</td>
                                                    <td>6:00</td>
                                                </tr>
                                                <tr>
                                                    <td>Wednesday</td>
                                                    <td>8:00</td>
                                                    <td>-</td>
                                                    <td>6:00</td>
                                                </tr>
                                                <tr>
                                                    <td>Thursday</td>
                                                    <td>8:00</td>
                                                    <td>-</td>
                                                    <td>6:00</td>
                                                </tr>
                                                <tr>
                                                    <td>Friday</td>
                                                    <td>8:00</td>
                                                    <td>-</td>
                                                    <td>6:00</td>
                                                </tr>
                                            </table>
                                        </CardBody>


                                    </Card>

                                    <br />
                                    <br />

                                </div>
                                {/* </Tag> */}


                            </div>

                            <div class="col-lg-4">

                                {this.props.focusedEntry && <>

                                    {/* <Tag color="blue" style={{ margin: '0', padding: '5px', fontSize: '12pt', textAlign: 'center', width: "100%" }}>
                                        <Card>
                                            <div class="card-header bg-transparent" style={{ width: '100%' }}>

                                                Foundations Stages 22 - 34 months

                                            </div>
                                        </Card>
                                    </Tag>


                                    <Card style={{ background: 'lightyellow' }}>
                                        <CardHeader>
                                            16 to 26 only
                                    </CardHeader>
                                        <CardBody>
                                            I Slept from -- to -- monday
                                            I Slept from -- to -- tuesday
                                            I Slept from -- to -- wednesday
                                            I Slept from -- to -- thursday
                                            I Slept from -- to -- friday

                                        <br />
                                            <br />
                                        </CardBody>

                                    </Card> */}

                                </>}


                                <Card style={{ background: 'lightyellow' }}>

                                    <CardHeader>
                                        Foundations Stages 22 - 34 months
                                    </CardHeader>

                                    <CardBody>

                                        <h3>General Information</h3>
                                       
                                       <p>
                                           something information 
                                       </p>
                                    </CardBody>

                                </Card>

                                <br />
                                <br />

                                <Card>
                                    
                                    <CardHeader>
                                        <h3>I ate</h3>
                                    </CardHeader>
                                   
                                    <CardBody>
                                        <p>something</p>
                                    </CardBody>
                                    
                                    <CardHeader>
                                       <h3> I drank</h3>
                                    </CardHeader>
                                    
                                    <CardBody>
                                        <p>something</p>
                                    </CardBody>

                                </Card>




                            </div>

                        </Row>

                        <div style={{ marginTop: '10px' }}> </div>

                        <Row>
                            <div class="col-lg-12">
                                <Card>
                                    <div class="card-header bg-transparent">
                                        <h3 class="mb-0">
                                            Comments
                                        <Button style={{float: 'right', border: 'thin solid green', color: 'darkgreen'}}>
                                            
                                            <span style={{paddingTop: '3px'}}> Add Comment </span>
                                            
                                            <FontAwesomeIcon icon={faPlusCircle} style={{ float: 'left', margin: '5px' }} />
                                        </Button>
                                        </h3>

                                        
                                    </div>
                                </Card>
                            </div>
                        </Row>

                    </Container >

                <style jsx>
                    {
                        `
                            .card-header { 
                                background: #eaeaea
                            }

                            h3 {
                                font-weight: bold
                            }
                        `
                    }
                </style>
                </>
            }

        </>
    }

}

export default LinkbookEntryComponent