import React, { Component } from 'react'

import { Drawer, Form, Input, message, Upload, Select, DatePicker, Icon, Switch, Menu, Dropdown, notification } from 'antd';

import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardFooter,
    Col,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip
} from "reactstrap";

import { ScaleLoader } from 'react-spinners';

import moment from 'moment';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

export class EditTermsComponent extends Component {

    actions = {};

    state = {
        termToEdit: {}
    }

    constructor(props) {
        super(props);

        this.actions = props.actions;

        this.setState({
            termToEdit: props.termToEdit
        });


    }

    render() {
        return (
            <div>
                <Drawer
                    title={<><a>Edit </a>
                        <UncontrolledDropdown>
                            <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={() => { }}
                            >
                                {this.props.termToEdit && 
                                    <h4>{this.props.termToEdit.title} 
                                        <Icon type="tool" theme="twoTone" style={{ fontSize: '14pt' }} color="#1890ff" /> 
                                    </h4>
                                }

                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem href="#pablo" >
                                    <Button style={{ color: 'darkred' }} type="primary" onClick={() => { 

                                        this.actions.delete.onshowDeleteTermDrawer(this.props.termToEdit) 
                                    }}>
                                    Delete {this.props.termToEdit && this.props.termToEdit.title}
                                    </Button>
                                </DropdownItem>

                            </DropdownMenu>
                        </UncontrolledDropdown> </>}
                    width={520}
                    closable={false}
                    anchor="right"
                    onClose={this.actions.edit.onCloseEditTermDrawer}

                    open={this.props.canEditTerm && this.props.showEditTermDrawer}
                    visible={this.props.canEditTerm && this.props.showEditTermDrawer}
                >



                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                        }}
                    >
                        <Button
                            style={{
                                marginRight: 8,
                            }}
                            onClick={this.actions.edit.onCloseEditTermDrawer}>
                            Cancel
                        </Button>

                        {!this.state.committed && <Button onClick={this.onCommitNewNotice} type="primary">  Save  </Button>}
                        {this.state.commiting && <Button disabled={true} type="primary" >
                            Saving ...
                    <ScaleLoader
                                // css={{}}
                                sizeUnit={"px"}
                                size={40}
                                color={'#176599'}
                            />
                        </Button>}
                        {this.state.committed && <Button onClick={this.actions.edit.onCloseEditTermDrawer} type="primary"> Close </Button>}


                    </div>
                </Drawer>
            </div>
        )
    }
}

export default EditTermsComponent
