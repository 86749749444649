import React, { Component } from 'react';

import { Table, Drawer, Form, Input, message, Upload, Select, DatePicker, Icon, Switch, Menu, Dropdown, notification, Tag, Tooltip, Collapse } from 'antd';

import { ScaleLoader } from 'react-spinners';
import BSCCE from '../../apis/BSCCEApi';

import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardFooter,
    Col,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Container,
    Row,
} from "reactstrap";

import PDFViewer from 'pdf-viewer-reactjs';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight, faAddressBook, faFileDownload, faEdit, faFileAlt } from '@fortawesome/free-solid-svg-icons'

import '../../../node_modules/antd/lib/collapse/style/css'
const { Panel } = Collapse;

const ButtonGroup = Button.Group;
const newsletterHeadSVG = require('assets/img/undraw/undraw_online_articles_79ff.svg');

export class ListNewslettersComponent extends Component {

    componentWillUpdate() {
    }

    state = {
        documents: [],
        pdf: '',
        forUserRole: '',
        focusedDocument: {}
    };

    actions = {};

    constructor(props) {
        super(props);

        this.actions = this.props.actions;

    }

    onDownloadFile(id) {
        console.log('download ', id);
        message.loading('your download should begin shortly', 5)
        BSCCE.files.getFile({ id: id })
            .then((resp) => {
                console.log('open ', resp.data.data.url);
                window.open(resp.data.data.url, '_blank');
            })
    }


    render() {
        return (
            <div>

                <Container className="mt--7" fluid>

                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">

                                {/* <CardHeader className="border-0">
                                    <Row>
                                        <div className="col">

                                            <Col>
                                                <img src={newsletterHeadSVG} style={{ width: '20%', height: 'auto', float: 'left', padding: '20px' }} />
                                            </Col>

                                            <Col>
                                                <div >
                                                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                                                        Our Newsletters
                                                </h6>
                                                    <p>
                                                        We post a monthly newsletter to keep our parents informed of what is happening in our local area and how this may affect them or their childcare services.
                                                        Our documents also include handly tips for parents and lots of other useful inf\ion.
                                            </p>
                                                </div>
                                            </Col>

                                        </div>

                                    </Row>

                                </CardHeader> */}

                                {
                                    this.props.loadingDocuments && <>
                                        <div style={{ margin: 'auto', width: '250px', padding: '40px' }}>
                                            <ScaleLoader color={'lightblue'} />

                                        </div>

                                    </>
                                }

                                {this.props.documents && <>

                                    {this.props.documents.map(
                                        (document, i) => {

                                            return <>

                                                <div style={{ borderBottom: 'thin solid lightgrey', padding: '10px' }}  >


                                                    <span style={{ display: 'inline-block', paddingLeft: '10px', color: 'grey' }}>
                                                        <FontAwesomeIcon icon={faFileAlt} style={{ fontSize: '14pt', color: 'green' }} />
                                                        <a style={{ paddingLeft: '10px', color: '#555', fontSize: '16pt' }}>
                                                            {document && document.title && document.title}
                                                        </a>
                                                    </span>



                                                    {/* {document.forAudience && document.forAudience.length > 1 && <Button disabled
                                                        style={{ fontSize: '9pt', background: 'white', color: 'gold', float: 'right', border: 'thin solid gold' }}
                                                        onClick={() => { this.setState({ ...this.state, focusedDocumentId: (!!this.state.focusedDocumentId) ? null : document._id }) }}>
                                                        {document.forAudience && document.forAudience.map((audience, i) => {
                                                            return <>{audience.label} {' '}</>
                                                        })}
                                                    </Button>} */}

                                                    <Button
                                                        style={{ fontSize: '9pt', background: 'white', color: 'grey', float: 'right', border: 'thin solid lightgrey' }}
                                                        onClick={() => { this.setState({ ...this.state, focusedDocumentId: (!!this.state.focusedDocumentId) ? null : document._id }) }}>
                                                        {(this.state.focusedDocumentId == document._id) ? 'Hide Details' : 'View Details'}
                                                    </Button>

                                                    {this.state.focusedDocumentId && this.state.focusedDocumentId == document._id &&
                                                        <div>



                                                            <div
                                                                style={{ width: '95%', margin: '15px auto' }}
                                                                dangerouslySetInnerHTML={{ __html: document.description }}>

                                                            </div>


                                                            <div style={{ float: "right" }}>
                                                                {this.props.forUserRole == 'staff' && <>

                                                                    <Button
                                                                        onClick={() => { this.props.actions.edit.onShowEditDocumentDrawer(document._id) }}
                                                                        style={{ fontSize: '9pt', background: 'white', color: 'darkblue', border: 'thin solid lightblue' }}
                                                                    >

                                                                        <FontAwesomeIcon icon={faEdit} style={{ color: 'darkblue' }} /> Edit File Details

                                                                    </Button>

                                                                </>}


                                                                <Button
                                                                    style={{ fontSize: '9pt', background: 'white', border: 'thin solid lightgreen', color: 'green' }}
                                                                    onClick={() => { this.onDownloadFile(document._id) }}
                                                                    loading={this.state.downloadingNewsletter}>
                                                                    <FontAwesomeIcon icon={faFileDownload} style={{ color: 'darkgreen' }} />
                                                                    <a>Download {document.title}</a>
                                                                </Button>

                                                            </div>
                                                        </div>
                                                    }

                                                </div>

                                            </>
                                        })
                                    }


                                </>}

                                <CardFooter className="py-4">

                                </CardFooter>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default ListNewslettersComponent
