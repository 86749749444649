/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardFooter,
  UncontrolledDropdown,
  DropdownToggle,
  Container,
  Row
} from "reactstrap";

import { Input, DatePicker, notification, Table, Modal, Button, Drawer, Checkbox, Tabs, Divider, Skeleton, List, Empty, Tag } from 'antd';
import Moment from 'react-moment';
import {
  SyncOutlined,
} from '@ant-design/icons';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faSpinner, faHourglassStart } from '@fortawesome/free-solid-svg-icons';

import { connect } from 'react-redux';




// core components
import BlankHeader from "components/Headers/BlankHeader.jsx";

import BSCCE from '../../apis/BSCCEApi';

import "../../../node_modules/antd/lib/modal/style/css"
import "../../../node_modules/antd/lib/tabs/style/css"
import "../../../node_modules/antd/lib/tag/style/css"
import "../../../node_modules/@ant-design/icons/SyncOutlined";

import { ListItem } from "@material-ui/core";

const { TabPane } = Tabs;

const { RangePicker } = DatePicker;
const { TextArea } = Input;


const mapStateToProps = (state) => {
  return {
    notesFromStore: state.notes
  }
}


class ListChildrenComponent extends React.Component {

  state = {
    focusedChild: {},
    childToCreate: {},
    searchTerm: '',
    childDrawer: {},
    childrenToDisplay: [],
    searchTerm: ''
  }


  notifications = {
    keys: {
      createUser: 'createChild',
      updateChild: 'updateChild'
    },
    type: {
      overallStatus: {
        working: {
          key: 'overallStatus',
          duration: 0,
          message: 'working ...',
          description: 'This task is not yet complete'
        },
        success: {
          key: 'overallStatus',
          duration: 10,
          message: 'Task Complete',
          description: 'This task was completed successfully'
        },
        error: {
          key: 'overallStatus',
          duration: 10,
          message: 'This task failed',
          description: ''
        }
      },
      updateChild: {
        pending: {
          key: 'updateChild',
          duration: 0,
          message: 'Update Child',
          description: 'Waiting to update the child record'
        },
        working: {
          key: 'updateChild',
          duration: 0,
          message: 'Updating Child',
          description: 'Updating child record.'
        },
        success: {
          key: 'updateChild',
          duration: 10,
          message: 'User Updated',
          description: 'Successfully Updated child from user.'
        },
        error: {
          key: 'updateChild',
          duration: 10,
          message: 'Create user failed.',
          description: 'New description.'
        }      
      },
      createChild: {
        pending: {
          key: 'createChild',
          duration: 0,
          message: 'Create Child',
          description: 'Waiting to update the child record'
        },
        working: {
          key: 'createChild',
          duration: 0,
          message: 'Creating Child',
          description: 'Creating child record.'
        },
        success: {
          key: 'createChild',
          duration: 10,
          message: 'User Created',
          description: 'Successfully Created child from user.'
        },
        error: {
          key: 'createChild',
          duration: 10,
          message: 'Create user failed.',
          description: 'New description.'
        }      
      }
    }
  }




  constructor(props) {
    super(props);

    console.log('child component props ', props);
  }

  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description
    });
  };


  onChangeSearchTerm = (e) => {

    let term = e.target.value;

    this.setState({
      searchTerm: term,
      childrenToDisplay: this.props.children.filter((child, index) => {

        if (

          child.firstName.toLowerCase().indexOf(term.toLowerCase()) > -1
          ||
          child.lastName.toLowerCase().indexOf(term.toLowerCase()) > -1

        ) {

          return child;

        }

      })
    });

  }

  componentDidMount(e) {
    this.setState({
      childrenToDisplay: this.props.children
    })
  }

  onSelectChildFromList = (e) => {
    this.setState({ selectedChild: e });
  }

  onDeleteChild = (e) => {
    console.log('delete notice ', e);
    console.log('delete notice ', this.state.childToEdit);
    this.setState({
      deleting: true,
    });


    BSCCE.children.deleteChild(e._id)
      .then(res => {
        if (res.data.status == 200) {

          this.setState({
            deleting: false,
            deleted: true
          });

          this.setState({
            children: [...this.props.children.filter(child => child._id != this.state.childToEdit._id)]
          });

          this.onDeleteComplete();

        } else {
          this.openNotificationWithIcon('error', 'Something went wrong', res.data.data.reason);
          this.setState({
            deleting: false
          });

        }
      }, err => {
        console.log('err ', err);
        this.openNotificationWithIcon('error', 'Something went wrong', err);
        this.setState({
          deleting: false
        });
      });

    setTimeout(() => {



    }, 2000);

  }

  onDeleteComplete = (e) => {
    this.openNotificationWithIcon('success', 'Delete success', `${this.state.childToEdit.firstName} has now been deleted.`);
    this.onCloseDeleteconfirmationDrawer();
    this.onCloseEditChildDrawer();

    this.setState({
      deleting: false,
      deleted: false,
      selectedChild: null,
      childToEdit: null,
    });

  }

  onFieldChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {

    const columns = [
      {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName'
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName',
      },
      {
        title: 'D.O.B',
        dataIndex: 'dob',
        key: 'dob',
        render: record => {
          return <Moment format="D MMM YYYY" date={record} />
        }
      },
      {
        title: '',
        key: 'action',
        render: (text, record) => {


          const onOpenChildCardDrawer = child => {

            this.setState({
              childCardDrawerVisible: true,
              focusedChild: child,
              childDrawer: {
                ...this.state.childDrawer,
                loadingChildFiles: true,
                loadingChildRelationships: true,
                loadingChildActivity: false,
              }
            })
            // pass through child details 
            // BSCCE
            //   .children
            //   .getChildRelationships(
            //     child._id
            //   )
            //   .then(childRelationships => this.setState({
            //     childDrawer: {
            //       ...this.state.childDrawer,
            //       loadingChildRelationships: false,
            //       childRelationships: childRelationships.data.data
            //     }
            //   }))
            // load child relationships

            // let forChildIds = child._id;

            // BSCCE.files.queryFiles(
            //   forChildIds
            // )
            //   .then(childFiles => this.setState({
            //     childDrawer: {
            //       ...this.state.childDrawer,
            //       loadingChildFiles: false,
            //       childFiles: childFiles.data.data
            //     }
            //   }))
            // load child files



          }



          return (
            // <UncontrolledDropdown>
            //   <DropdownToggle
            //     className="btn-icon-only text-light"
            //     // href="#pablo"
            //     role="button"
            //     size="sm"
            //     color=""
            //     onClick={() => { this.props.actions.edit.onShowEditChildDrawer(record) }}
            //   >
            //     <Icon type="tool" theme="twoTone" style={{ fontSize: '14pt' }} color="#1890ff" />

            //   </DropdownToggle>

            // </UncontrolledDropdown>

            <>
              <Button onClick={() => { onOpenChildCardDrawer(record) }}>
                <a> <FontAwesomeIcon icon={faEye} />
                  <span>
                    {(this.state.focusedChild && this.state.focusedChild._id) == record._id ? ' viewing child' : ' view child card'}
                  </span>
                </a>
              </Button>
            </>
          )

        }
      }
    ];

    // rowSelection objects indicates the need for row selection
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      },
      onSelect: (record, selected, selectedRows) => {
        console.log(record, selected, selectedRows);
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
        console.log(selected, selectedRows, changeRows);
      },
      onSelectInvert: (selectedRowKeys) => {
        console.log('selected invert ', selectedRowKeys);
      }
    };

    const onCloseChildCardDrawer = child => {

      this.setState({
        childCardDrawerVisible: false,
        focusedChild: {},
        childDrawer: {}
      })

    }

 

    return (
      <>

        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">

                <Drawer
                  title={<>
                    {this.state.focusedChild && this.state.focusedChild.firstName && this.state.focusedChild.firstName}

                    <UncontrolledDropdown>
                      <DropdownToggle
                        className="btn-icon-only text-light"
                        // href="#pablo"
                        role="button"
                        size="sm"
                        color=""
                        onClick={() => { this.props.actions.edit.onShowEditChildDrawer(this.state.focusedChild) }}
                      >
                        {/* <Icon type="tool" theme="twoTone" style={{ fontSize: '14pt' }} color="#1890ff" />
                               */}

                        <FontAwesomeIcon icon={faEdit} />

                      </DropdownToggle>

                    </UncontrolledDropdown>
                  </>}
                  open={this.state.childCardDrawerVisible}
                  visible={this.state.childCardDrawerVisible}
                  onClose={() => onCloseChildCardDrawer()}

                  width={550}
                  closable={true}
                  anchor="right"
                // open={this.state.canCreateChild && this.state.showCreateChildDrawer}
                // visible={this.state.canCreateChild && this.state.showCreateChildDrawer}
                >


                 
                  <Tabs defaultActiveKey="1" onChange={(key) => {
                    console.log(key);
                  }}>

                    <TabPane tab="Details" key="1">

                    <br />
                      <List>

                        
                      <ListItem>
                          <b>First Name: </b> <a>
                            {this.state.focusedChild && this.state.focusedChild.firstName && this.state.focusedChild.firstName}
                          </a>
                        </ListItem>
                      <ListItem>
                          <b>Middle Names: </b> <a>
                            {this.state.focusedChild && this.state.focusedChild.middleName && this.state.focusedChild.middleName}
                          </a>
                        </ListItem>
                      <ListItem>
                          <b>Last Name: </b> <a>
                            {this.state.focusedChild && this.state.focusedChild.lastName && this.state.focusedChild.lastName}
                          </a>
                        </ListItem>
                      <ListItem>
                          <b>Email: </b> <a>
                            {this.state.focusedChild && this.state.focusedChild.email && this.state.focusedChild.email}
                          </a>
                        </ListItem>
                      <ListItem>
                          <b>Gender: </b> <a>
                            {this.state.focusedChild && this.state.focusedChild.gender && this.state.focusedChild.gender}
                          </a>
                        </ListItem>
                      <ListItem>
                          <b>Ethnicity: </b> <a>
                            {this.state.focusedChild && this.state.focusedChild.ethnicity && this.state.focusedChild.ethnicity}
                          </a>
                        </ListItem>
                      <ListItem>
                          <b>DOB: </b> <a>
                            {this.state.focusedChild && this.state.focusedChild.dob && <>
                              <Moment format="D MMM YYYY" date={this.state.focusedChild.dob} />
                              
                              </>}
                          </a>
                        </ListItem>
                      <ListItem>
                          <b>phone: </b> <a>
                            {this.state.focusedChild && this.state.focusedChild.phone && this.state.focusedChild.phone}
                          </a>
                        </ListItem>

                        <ListItem>
                          <b>last updated:  </b> <a>
                            {this.state.focusedChild && this.state.focusedChild.updatedAt && this.state.focusedChild.updatedAt}
                          </a>
                        </ListItem>

                        <ListItem>
                          <b>created:</b> <a>
                            {this.state.focusedChild && this.state.focusedChild.createdAt && this.state.focusedChild.createdAt}
                          </a>
                        </ListItem>

                      </List>

                      <br /><br />

                    </TabPane>
                    {/* <TabPane tab={
                      (
                        <>
                          Relationships
                        {this.state.childDrawer.loadingChildRelationships && <SyncOutlined spin />}
                          {this.state.childDrawer.childRelationships && this.state.childDrawer.childRelationships.length > 0 && <Tag> {this.state.childDrawer.childRelationships.length}</Tag>}
                        </>
                      )
                    } key="2">

                  

                      {this.state.childDrawer.childRelationships && this.state.childDrawer.childRelationships.length > 0 && this.state.childDrawer.childRelationships.map(relationship => (
                        <>
                          <br />
                          {relationship.firstName} {''} {relationship.lastName} {''} {relationship.type}
                        </>
                      ))}

                      {
                        !this.state.childDrawer.loadingChildRelationships && (!this.state.childDrawer.childRelationships || this.state.childDrawer.childRelationships.length < 1) && <Empty />
                      }

                      {this.state.childDrawer.loadingChildRelationships && <Skeleton active />}


                    </TabPane>
                    <TabPane tab={
                      (
                        <>
                          Files
                        {this.state.childDrawer.loadingChildFiles && <SyncOutlined spin />}
                          {this.state.childDrawer.childFiles && this.state.childDrawer.childFiles.length > 0 && <Tag> {this.state.childDrawer.childFiles.length}</Tag>}
                        </>
                      )
                    } key="3">

             


                      {this.state.childDrawer.ChildFiles && this.state.childDrawer.childFiles.length > 0 && this.state.childDrawer.childFiles.map(file => (
                        <>
                          <br />
                          {file.title} {''} {file.description} {''} {file.cateory} {''} {file.created}
                        </>
                      ))}

                      {
                        !this.state.childDrawer.loadingChildFiles && (!this.state.childDrawer.childFiles || this.state.childDrawer.childFiles.length < 1) && <Empty />
                      }

                      {this.state.childDrawer.loadingChildFiles && <Skeleton active />}



                    </TabPane>
                    <TabPane tab="Activity" key="4">
                      Activity

                      {this.state.childDrawer.childActivity && this.state.childDrawer.childActivity.length > 0 && this.state.childDrawer.childActivity.map(relationship => (
                      <>
                        <br />
                          file
                      </>
                    ))}

                      {this.state.childDrawer.loadingChildActivity && <Skeleton active />}

                      {
                        !this.state.childDrawer.loadingChildActivity && (!this.state.childDrawer.childActivity || this.state.childDrawer.childActivity.length < 1) && <Empty />
                      }

                      <Skeleton active />
                    </TabPane>
                    */}
                  </Tabs> 


                </Drawer>



                <Input
                  style={{ background: 'transparent', borderBottom: 'thin solid darkgrey', borderRadius: '0', boxShadow: 'inset lightgrey 0 -4px 6px', height: '45px', fontSize: '15pt', color: '#333', padding: '10px', }}
                  placeholder="Start typing to filter by first or last name ..."
                  onChange={(e) => { this.onChangeSearchTerm(e) }}
                />

                <Table
                  columns={columns}
                  loading={this.props.loading}
                  rowSelection={rowSelection}
                  dataSource={this.state.searchTerm.length > 0 ? this.state.childrenToDisplay : this.props.children}
                />

                <CardFooter className="py-4">
                </CardFooter>
              </Card>
            </div>
          </Row>

        </Container>

      </>
    );
  }
}

export default connect(mapStateToProps)(ListChildrenComponent);
