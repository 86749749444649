/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "./views/Index.jsx";
import Profile from "./views/examples/Profile.jsx";
import Maps from "./views/examples/Maps.jsx";
import Register from "./views/examples/Register.jsx";
import Login from "./views/examples/Login.jsx";
import Forgot from "./views/examples/Forgot.jsx";
import Validate from "./views/examples/Validate.jsx";
import Tables from "./views/examples/Tables.jsx";
import Icons from "./views/examples/Icons.jsx";
import Unauthorised from "./views/examples/Unauthorised.jsx";
import Newsletters from "./views/examples/Newsletters.jsx";
import Notices from "./views/examples/Notices.jsx";
import Users from "./views/examples/Users.jsx";

import Children from "./views/Children/Children";
import Linkbooks from "./views/Linkbooks/Linkbooks";

import Terms from "./views/examples/Terms";
import Vacancies from "./views/examples/Vacancies";
import WebsiteCopy from "./views/examples/WebsiteCopy";
import Contacts from "./views/examples/Contacts";
import Documents from "./views/examples/Documents";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
    visibleForRoles: ['staff', 'admin', 'super']
  },
  {
    path: "/index",
    name: "Home",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
    visibleForRoles: ['parent']
  }
  , {
    path: "/",
    name: "Home",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    visibleForRoles: ['']
  }
  , {
    path: "/documents",
    name: "Documents",
    icon: "ni ni-briefcase-24 text-pink",
    component: Documents,
    layout: "/admin",
    visibleForRoles: ['admin', 'staff']
  }, {
    path: "/documents",
    name: "Your Documents",
    icon: "ni ni-briefcase-24 text-pink",
    component: Documents,
    layout: "/admin",
    visibleForRoles: ['parent',]
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: Icons,
    layout: "/admin",
    visibleForRoles: ['super']
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: Maps,
    layout: "/admin",
    visibleForRoles: ['super']
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
    visibleForRoles: []
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/admin",
    visibleForRoles: []
  },
  {
    path: "/children",
    name: "Children",
    icon: "ni ni-circle-08 text-pink",
    component: Children,
    layout: "/admin",
    visibleForRoles: ['admin', 'staff']
  },
  {
    path: "/children",
    name: "Your Children",
    icon: "ni ni-circle-08 text-pink",
    component: Children,
    layout: "/admin",
    visibleForRoles: ['parent']
  },
  {
    path: "/contacts",
    name: "People",
    icon: "ni ni-bullet-list-67 text-red",
    component: Contacts,
    layout: "/admin",
    visibleForRoles: ['admin']
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  },
  {
    path: "/validate",
    name: "Validate",
    icon: "ni ni-circle-08 text-pink",
    component: Validate,
    layout: "/auth"
  },
  {
    path: "/forgot",
    name: "Forgot",
    icon: "ni ni-circle-08 text-pink",
    component: Forgot,
    layout: "/auth"
  },
  {
    path: "/unauth",
    name: "Unauthorised",
    icon: "ni ni-circle-08 text-pink",
    component: Unauthorised,
    layout: "/auth"
  },
  // {
  //   path: "/newsletters",
  //   name: "Newsletters",
  //   icon: "ni ni-archive-2 text-pink",
  //   component: Newsletters,
  //   layout: "/admin",
  //   visibleForRoles: ['admin', 'staff', 'parent']
  // },
  {
    path: "/notices",
    name: "Notices",
    icon: "ni ni-bell-55 text-pink",
    component: Notices,
    layout: "/admin",
    visibleForRoles: ['admin']
  }, {
    path: "/terms",
    name: "Term Dates",
    icon: "ni ni-watch-time text-pink",
    component: Terms,
    layout: "/admin",
    visibleForRoles: ['admin']
  }, {
    path: "/copy",
    name: "Website Copy",
    icon: "ni ni-ruler-pencil text-pink",
    component: WebsiteCopy,
    layout: "/admin",
    visibleForRoles: ['admin']
  },
  {
    path: "/users",
    name: "Users",
    icon: "ni ni-user-run text-pink",
    component: Users,
    layout: "/admin",
    visibleForRoles: ['admin']
  },
  {
    path: "/vacancies",
    name: "Vacancies",
    icon: "ni ni-paper-diploma text-pink",
    component: Vacancies,
    layout: "/admin",
    visibleForRoles: ['admin']
  }
  , {
    path: "/linkbooks",
    name: "Link Books",
    icon: "ni ni-books text-pink",
    component: Linkbooks,
    layout: "/admin",
    visibleForRoles: []
  }
];
export default routes;
